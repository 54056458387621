import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StackedPhones from "./finalStacked.png";
import Reporting from "./reportingWithFilters.png";
import Timesheets from "./timeEdits.png";
import Users from "./addUser.png";
import Alerts from "./alerts2.png";
import QR from "./qr.png";
import NFC from "./rfid.png";
import DAILY from "./dailycode.png";
import FINGER from "./biometrics.png";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import "./Product.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Intercom from '@intercom/messenger-js-sdk';
import SubProduct from "./SubProduct";
import Pricing from "./Pricing";
import About from "./About";

Intercom({
  app_id: 'ct6qw2sy',
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
};

const Product = ({ onRegisterClick, displayPricing, displayAbout, displayInitialHome }) => {
  const isSmallScreen = useMediaQuery("(max-width: 775px)");
  const isMedScreen = useMediaQuery("(max-width: 1050px)");
  const [renderPrivacy, setRenderPrivacy] = useState(false);
  const [renderTos, setRenderTos] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {}, []);

  const handleRegisterClick = () => {
    onRegisterClick();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const togglePrivacy = () => {
    setRenderPrivacy(!renderPrivacy);
  };

  const toggleTos = () => {
    setRenderTos(!renderTos);
  };

  return (
    <>
      <Box
        className="container"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {displayInitialHome && <SubProduct onRegisterClick={onRegisterClick}/> }
        {displayPricing && <Pricing />}
        {displayAbout && <About />}
        <Box sx={{ width: "75%", ml: "auto", mr: "auto" }}>
          <Box
            className="faq"
            sx={{
              maxWidth: "99%",
              ml: "auto",
              mr: "auto",
              mt: 2,
              display: "flex",
              flexDirection: "column",
              paddingBottom: "50px",
            }}
          >
            <Typography
              variant="h4"
              className="faqMain"
              sx={{ ml: 0, mr: "auto" }}
            >
              FAQ
            </Typography>
            <Typography
              variant="h7"
              className="faqSubMain"
              sx={{ ml: 0, mr: "auto" }}
            >
              For any additional quesitons, feel free to contact us via the chat
              icon in the bottom right.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              How flexible is the time recording with ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Empower Your Workflow:</strong> With ChronoLeaf, you
              tailor how employees clock in and out, choosing from methods like
              QR, NFC, or biometric scans to fit your unique business needs.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Do I need to keep the website open to manage clock-ins?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Streamlined Efficiency:</strong> Utilize our dedicated app
              to manage employee time tracking seamlessly—no need to keep a
              browser open. Our app supports all scan types for effortless
              operations.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              What does a ChronoLeaf subscription cost?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Value-Driven Pricing:</strong> ChronoLeaf is currently
              free during our trial period as we prepare to launch with
              competitive pricing that stands out in the timekeeping market.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              How can employees manage their QR Codes and NFC tags?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Convenience at Your Fingertips:</strong> Employees can
              easily store their QR codes and NFC tags in their smartphones via
              digital wallets like Apple Wallet or Google Pay, ensuring they’re
              always ready to clock in.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Does ChronoLeaf handle overtime and tax calculations in wage
              displays?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Comprehensive Wage Calculation:</strong> Displayed wages
              include overtime, tailored to various state laws. While tax
              calculations are upcoming, our current focus is on accurate wage
              representation.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              How secure is my data with ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Privacy First:</strong> Your data's security is paramount.
              We use trusted third parties like Google and Stripe to manage
              sensitive information, ensuring your data remains secure.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Does ChronoLeaf offer payroll services?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Future-Forward Features:</strong> We are excited about our
              upcoming optional tax calculation and payroll services, designed
              to further streamline your administrative tasks.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              What integrations are available with ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Enhanced Connectivity:</strong> We currently support wage
              and hour data exports, and are actively developing integrations
              with platforms like QuickBooks for a unified business management
              experience.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Is there a limit to how many employees can use ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Scalability for Every Size:</strong> ChronoLeaf supports
              an unlimited number of employees, with plans to introduce a
              minimal fee for each active employee to sustain quality service as
              you grow.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Can I download and print reports from ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Data at Your Disposal:</strong> Generate reports easily
              and download them in Excel format or print them directly,
              facilitating smooth record-keeping and compliance.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              How long does the ChronoLeaf trial period last?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Explore with Ease:</strong> Our one-month trial offers
              full access to explore how ChronoLeaf can revolutionize your
              timekeeping without any commitment.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              What is the process to cancel a ChronoLeaf subscription?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Hassle-Free Cancellation:</strong> Cancel anytime via the
              Stripe customer portal accessible through your billing settings,
              ensuring control is always in your hands.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              What external devices are needed for ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Tailored to Your Needs:</strong> Depending on your chosen
              verification methods—daily codes, QR scans, fingerprints,
              NFC—specific hardware may be required. All necessary devices and
              setup guides are readily available through our platform.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Can you explain NFC technology used in ChronoLeaf?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Touchless Convenience:</strong> NFC (Near-Field
              Communication) technology lets employees clock in with a simple
              wave of their phone, enhancing the speed and hygiene of time
              tracking.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              Is the ChronoLeaf client application available on all operating
              systems?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Universal Access:</strong> Yes, ChronoLeaf is compatible
              with all operating systems. However, please note that fingerprint
              functionality is currently not supported on Mac. We are actively
              working to add this feature soon.
            </Typography>

            <Typography
              variant="h5"
              className="faqQuestion"
              sx={{ ml: 0, mr: "auto", mt: 2 }}
            >
              How does the daily code work?
            </Typography>
            <Typography
              className="faqAnswer"
              sx={{ ml: 0, mr: "auto", textAlign: "left", maxWidth: "75%" }}
            >
              <strong>Simple and Secure:</strong> The daily code for clocking in
              and out is conveniently displayed at the top of the webpage for
              business leadership. You can distribute this code to employees
              through any method you prefer, such as writing it on a sticky note
              or sending a text message.
            </Typography>

            <Box
              sx={{
                mt: 5,
                ml: "auto",
                mr: "auto",
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <Typography
                sx={{
                  margin: "15px",
                  "&:hover": {
                    color: "rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                  },
                }}
                onClick={togglePrivacy}
              >
                Privacy Policy
              </Typography>
              <Typography
                sx={{
                  margin: "15px",
                  "&:hover": {
                    color: "rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                  },
                }}
                onClick={toggleTos}
              >
                Terms of Service
              </Typography>
            </Box>
            <Box>@ {currentYear} ChronoLeaf. All rights reserved.</Box>
          </Box>
        </Box>
        <BootstrapDialog
          onClose={togglePrivacy}
          aria-labelledby="customized-dialog-title"
          open={renderPrivacy}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, minWidth: "300px" }}
            id="customized-dialog-title"
          >
            Privacy Policy
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={togglePrivacy}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom sx={{ mt: 2 }}>
              At ChronoLeaf ("we", "us", or "our"), we understand the importance
              of your privacy. Our SaaS platform provides timekeeping services
              for small businesses, and this Privacy Policy outlines how we
              collect, use, disclose, and safeguard your information when you
              use our service. By using ChronoLeaf, you agree to the terms of
              this Privacy Policy
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We collect both business-related and user-related information to
              provide and enhance our services. The business data we collect
              includes the business name, state, phone number, timezone,
              associated scanner email, latitude and longitude (if provided),
              Stripe customer ID, email address, and relevant subscription IDs.
              For user data, we collect first name, last name, email address,
              and encrypted fingerprint data.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              This information is used to provide and maintain our service,
              manage subscriptions, and process payments through Stripe. We also
              use Google OAuth 2.0 for user authentication, which helps us
              populate user profiles with names and email addresses.
              Additionally, the data allows us to communicate with you regarding
              your account, offer customer support, and improve our platform's
              security and functionality.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We utilize third-party services like Stripe for subscription
              management and payment processing, and Google for OAuth 2.0
              authentication. Stripe's privacy policy can be found{" "}
              <a href="https://stripe.com/privacy" target="_blank">
                here
              </a>
              , and Google's privacy policy can be found{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                here
              </a>
              .
            </Typography>
            <Typography sx={{ mt: 2 }}>
              At ChronoLeaf, we are committed to protecting your data with
              industry-standard security measures to prevent unauthorized
              access, alteration, disclosure, or destruction. Employee data can
              be deleted at any time by removing the employee from your business
              account. Business and user data will be deleted if a subscription
              remains inactive for over a month or upon request via our support
              chat.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Depending on your location, you may have certain rights regarding
              your personal data, including the right to access and update your
              information, request deletion of your data, object to or restrict
              certain types of processing, and the right to data portability. To
              exercise any of these rights, please contact us via the support
              chat.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Our service is not intended for use by children under the age of
              13, and we do not knowingly collect personal information from
              children under 13. If we become aware that a child under 13 has
              provided us with personal information, we will delete such
              information from our servers.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We may update our Privacy Policy from time to time and will notify
              you of any changes by posting the new Privacy Policy on our
              website and updating the effective date at the top. We advise you
              to review this Privacy Policy periodically for any changes.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us via the support chat on our
              platform.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              size="medium"
              onClick={togglePrivacy}
              sx={{
                ml: "auto",
                mr: "auto",
                backgroundColor: "#52796F",
                "&:hover": { backgroundColor: "#435e57" },
              }}
            >
              <Typography>Close</Typography>
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={toggleTos}
          aria-labelledby="customized-dialog-title"
          open={renderTos}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, minWidth: "300px" }}
            id="customized-dialog-title"
          >
            Terms of Service
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={toggleTos}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom sx={{ mt: 2 }}>
              Welcome to ChronoLeaf. These Terms of Service ("Terms") govern
              your use of our SaaS platform, which provides timekeeping services
              for small businesses. By accessing or using ChronoLeaf, you agree
              to be bound by these Terms. If you do not agree with any part of
              these Terms, you should not use our service.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf grants you a limited, non-exclusive, non-transferable,
              and revocable license to use our platform for managing your
              business's timekeeping needs. You agree to use our service in
              compliance with all applicable laws and regulations.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              To use ChronoLeaf, you must register for an account. You agree to
              provide accurate, current, and complete information during the
              registration process and to update such information to keep it
              accurate, current, and complete. You are responsible for
              safeguarding your account password and for any activities or
              actions under your account.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf uses Stripe for subscription management and payment
              processing. By subscribing to our service, you agree to pay the
              applicable fees and taxes. All payments are processed securely by
              Stripe, and you agree to Stripe's terms and conditions.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We collect and store business data (such as business name, state,
              phone number, timezone, scanner email, latitude and longitude,
              Stripe customer ID, email, and subscription IDs) and user data
              (such as first name, last name, email address, and encrypted
              fingerprint data) to provide and improve our services. You agree
              to our data collection and usage practices as described in our
              Privacy Policy.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              You can delete employee data at any time by removing the employee
              from your business account. Business and user data will be deleted
              if a subscription remains inactive for over a month or upon
              request via our support chat.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We strive to ensure that ChronoLeaf is available at all times, but
              we do not guarantee uninterrupted access to our service. We may
              experience temporary outages due to maintenance, upgrades, or
              other factors beyond our control.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf is provided "as is" and "as available," without
              warranties of any kind, either express or implied. We disclaim all
              warranties, including but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, title, and
              non-infringement.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              To the maximum extent permitted by law, ChronoLeaf and its
              affiliates, officers, employees, agents, and partners shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to, loss of profits,
              data, use, goodwill, or other intangible losses, resulting from
              (i) your use or inability to use the service; (ii) any
              unauthorized access to or use of our servers and/or any personal
              information stored therein; (iii) any interruption or cessation of
              transmission to or from our service; (iv) any bugs, viruses,
              trojan horses, or the like that may be transmitted to or through
              our service by any third party; (v) any errors or omissions in any
              content or for any loss or damage incurred as a result of your use
              of any content posted, emailed, transmitted, or otherwise made
              available through the service; and/or (vi) any miscalculated or
              displayed wages, hours, or overtime due to bugs or errors in our
              service.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              You agree to indemnify, defend, and hold harmless ChronoLeaf, its
              affiliates, officers, employees, agents, and partners from and
              against any and all claims, liabilities, damages, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              from your use of the service or your violation of these Terms.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We may modify these Terms at any time. We will notify you of any
              changes by posting the new Terms on our website and updating the
              effective date at the top. Your continued use of ChronoLeaf after
              any such changes constitutes your acceptance of the new Terms.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              These Terms shall be governed and construed in accordance with the
              laws of the State of North Carolina, without regard to its
              conflict of law principles.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              If you have any questions about these Terms, please contact us via
              the support chat on our platform.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              size="medium"
              onClick={toggleTos}
              sx={{
                ml: "auto",
                mr: "auto",
                backgroundColor: "#52796F",
                "&:hover": { backgroundColor: "#435e57" },
              }}
            >
              <Typography>Close</Typography>
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    </>
  );
};

const SlideComponent = ({ imageSrc, alt, direction }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [isIntersecting, hasIntersected]);

  return (
    <div ref={ref}>
      <Slide direction={direction} in={hasIntersected} mountOnEnter unmountOnExit timeout={1000}>
        <img src={imageSrc} alt={alt} style={{ maxWidth: "200px", paddingBottom: '30px' }} />
      </Slide>
    </div>
  );
};

const TooltipSlide = ({ title, imageSrc, alt, text }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [isIntersecting, hasIntersected]);

  return (
    <div ref={ref}>
      <Slide direction="right" in={hasIntersected} mountOnEnter unmountOnExit timeout={1000}>
        <Tooltip title={title}>
          <Paper
            sx={{
              width: "auto",
              maxWidth: "100px",
              padding: "10px",
              margin: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                cursor: "pointer",
              },
            }}
          >
            <img
              style={{ width: "100px" }}
              src={imageSrc}
              alt={alt}
            />
            <Typography>{text}</Typography>
          </Paper>
        </Tooltip>
      </Slide>
    </div>
  );
};

export default Product;
