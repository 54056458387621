import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is used or remove it if unnecessary
import "./ProfileSupport.css";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import IconButton from "@mui/material/IconButton";
import WalletIcon from "@mui/icons-material/Wallet";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import env from '../environmentVariables';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ProfileSupport = ({
  canDelete,
  googleUser,
  user,
  onUserCrudResponse,
}) => {
  const { baseURL } = env;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [editEmployee, setEditEmployee] = useState(false);
  const [editManager, setEditManager] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Success");
  const [errorMsg, setErrorMsg] = useState(
    "An error has occured, please try later."
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [noEmployees, setNoEmployees] = useState(false);
  const [noAdd, setNoAdd] = useState(false);
  const [supportType, setSupportType] = useState("");
  const [supportTypeError, setSupportTypeError] = useState(false);
  const [supportCommentsError, setSupportCommentsError] = useState(false);
  const [supportComments, setSupportComments] = useState("");
  const [userQrCode, setUserQrCode] = useState("");
  const [passLink, setPassLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passType, setPassType] = useState("Apple");
  const [displaySelect, setDisplaySelect] = useState(false);
  const types = ["Apple", "Android"];
  const [renderInfo, setRenderInfo] = useState(false);

  useEffect(() => {
    if (user.passLink != null && user.passLink != "") {
      setPassLink(user.passLink);
      setPassType(user.passType);
      setDisplaySelect(false);
    } else {
      const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(
        navigator.userAgent
      );
      setPassType(isAppleDevice ? "Apple" : "Android");
      setDisplaySelect(true);
    }
    setUserQrCode(user.qrCode);
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const toggleInfoSection = () => {
    if (user.passLink != null && user.passLink != "") {
      handleGetWallet();
    } else {
      setRenderInfo(!renderInfo);
    }
  };

  const dynamicSuccessOrErrorStyle =
    triggerSuccess || triggerError ? { borderBottom: "1px solid black" } : {};

  const handleSupportTypeChange = (event) => {
    setSupportType(event.target.value);
    setSupportTypeError(false);
  };

  const handleSupportCommentsChange = (event) => {
    setSupportComments(event.target.value);
    setSupportCommentsError(false);
  };

  const handleSubmitSupportRequest = () => {
    if (supportType === "" || supportComments === "") {
      if (supportType === "") {
        setSupportTypeError(true);
      } else if (supportComments === "") {
        setSupportCommentsError(true);
      }
      setErrorMsg("Please select the type and enter related support comments.");
      setTriggerError(true);
    } else {
      let SupportDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        userId: user.userId,
        businessId: user.business.businessId,
        type: supportType,
        comments: supportComments,
      };

      axios
        .post(`${baseURL}/submitSupport`, SupportDTO)
        .then((response) => {
          setSuccessMsg(
            "Thank you for your support request. We will reach out shortly."
          );
          setTriggerSuccess(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg(
              "It appears you are making too many requests. Please wait a moment then try again.."
            );
          } else {
            setErrorMsg(
              "We are having trouble getting your support request. Please try back later."
            );              
          }
          setTriggerError(true);
        });
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGetWallet = async (event) => {
    setIsLoading(true);
    if (passLink != null && passLink != "") {
      window.open(passLink, "_blank", "noreferrer");
    } else {
      let PassDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        operatorId: user.userId,
        businessId: user.business.businessId,
        businessName: user.business.businessName,
        firstName: user.firstName,
        lastName: user.lastName,
        qrCode: user.qrCode,
        passType: passType,
      };

      await axios
        .post(`${baseURL}/createPass`, PassDTO)
        .then((response) => {
          setRenderInfo(false);
          setSuccessMsg(`A link to add your Employee Pass to your digital wallet has been emailed to you.`);
          setTriggerSuccess(true);
          onUserCrudResponse({ response });
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg("It appears you are making too many requests. Please wait a moment then try again.");
          } else {
            setErrorMsg("An error has occured, please try later.");             
          }

          setRenderInfo(false);
          setTriggerError(true);
        });
    }

    setIsLoading(false);
  };

  const handlePassTypeChange = (event) => {
    setPassType(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            marginTop: "15%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress sx={{ color: "#52796F" }} />
        </Box>
      ) : (
        <div className="settings-container">
          <div
            className="settings-content-container"
            style={dynamicSuccessOrErrorStyle}
          >
            <Box sx={{ minWidth: 300, maxWidth: 300 }}>
              <AppBar position="static" sx={{ backgroundColor: "#52796F" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "#343F4B",
                    },
                  }}
                >
                  <Tab label="Pass" {...a11yProps(0)} disabled={noAdd} />
                  <Tab
                    label="Support"
                    {...a11yProps(1)}
                    disabled={noEmployees}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Typography>
                    ChronoLeaf uses <strong>Wallet Passes</strong> for QR and
                    NFC clocking in and out. These are conveniently stored on
                    your smart phone in either your Apple Wallet or Google
                    Wallet / Pass Wallet if you are on Android.
                  </Typography>

                  {displaySelect &&
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel
                        id="state-select-label"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "black",
                          },
                          "&.MuiInputLabel-root.Mui-focused": {
                            color: "#52796F",
                          },
                        }}
                      >
                        Device
                      </InputLabel>
                      <Select
                        labelId="state-select-label"
                        id="state-select"
                        value={passType}
                        label="Device"
                        onChange={handlePassTypeChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "black",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "black",
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#52796F",
                            },
                        }}
                      >
                        {types.map((passType) => (
                          <MenuItem key={passType} value={passType}>
                            {passType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }  

                  <Button
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<WalletIcon />}
                    onClick={toggleInfoSection}
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 2,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                  >
                    GET MY EMPLOYEE PASS
                  </Button>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                  <div className="select-employee-container">
                    <Typography>
                      Please select the type of support and enter any relevant
                      comments. We will get back to you by email as soon as
                      possible - but if you would prefer a phone call please
                      enter your phone number as well.
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "black",
                          },
                          "&.MuiInputLabel-root.Mui-focused": {
                            color: "#52796F",
                          },
                        }}
                      >
                        {" "}
                        Type
                      </InputLabel>
                      <Select
                        value={supportType}
                        label="Type"
                        onChange={handleSupportTypeChange}
                        error={supportTypeError}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "black",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "black",
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#52796F",
                            },
                        }}
                      >
                        <MenuItem value="General">General</MenuItem>
                        <MenuItem value="Feedback">Feedback</MenuItem>
                        <MenuItem value="Bug">Bug</MenuItem>
                      </Select>

                      <FormControl sx={{ mt: 2 }}>
                        <TextField
                          multiline
                          fullWidth
                          minRows={4}
                          label="Comments"
                          variant="outlined"
                          value={supportComments}
                          onChange={handleSupportCommentsChange}
                          error={supportCommentsError}
                          InputLabelProps={{
                            sx: {
                              color: "black !important",
                              "&.Mui-focused": { color: "#52796F !important" },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#52796F !important",
                            },
                          }}
                        />
                      </FormControl>
                    </FormControl>
                  </div>

                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 1,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleSubmitSupportRequest}
                  >
                    <Typography>Submit</Typography>
                  </Button>
                </TabPanel>
              </SwipeableViews>
            </Box>
          </div>
          <div className="settings-alert-container">
            <Stack sx={{ width: "100%", maxWidth: 300 }}>
              {triggerSuccess && (
                <Alert
                  severity="success"
                  onClose={() => setTriggerSuccess(false)} // Close the success alert
                >
                  {successMsg}
                </Alert>
              )}
              {triggerError && (
                <Alert
                  severity="error"
                  onClose={() => setTriggerError(false)} // Close the error alert
                >
                  {errorMsg}
                </Alert>
              )}
            </Stack>
          </div>
          <BootstrapDialog
        onClose={toggleInfoSection}
        aria-labelledby="customized-dialog-title"
        open={renderInfo}
        sx={{maxWidth: '400px', ml: 'auto', mr: 'auto'}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Warning
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleInfoSection}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom sx={{mt: 2}}>
            Please note, users are only allowed 1 type of device for their pass.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
          If you are sure that {passType} is the correct type of device you want your pass to be on, then click yes, otherwise click the exit icon.
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button
                autoFocus
                variant="contained"
                size="medium"
                sx={{
                  ml: 'auto',
                  mr: 'auto',
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleGetWallet}
              >
                <Typography>Yes</Typography>
              </Button>
        </DialogActions>
      </BootstrapDialog>
        </div>
      )}
    </>
  );
};

export default ProfileSupport;
