import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import WeeklyClockRecords from "../WeeklyClockRecords/WeeklyClockRecords";
import InfoIcon from "./InfoIcon.png";
import EnterIcon from "./EnterIcon.png";
import SaveIcon from "./SaveIcon.png";
import ExitIcon from "../WeeklyClockRecords/ExitIcon.png";
import RefreshIcon from "./RefreshIcon.png";
import Add from "./add.png";
import Edit from "./edit.png";
import In from "./in.png";
import Out from "./out.png";
import "./Home.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";

import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import env from '../environmentVariables';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Home = ({ googleUser, user, onSaveResponse }) => {
  const { baseURL } = env;
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const [selectedWeek, setSelectedWeek] = useState("");
  const [mondayRecords, setMondayRecords] = useState([]);
  const [tuesdayRecords, setTuesdayRecords] = useState([]);
  const [wednesdayRecords, setWednesdayRecords] = useState([]);
  const [thursdayRecords, setThursdayRecords] = useState([]);
  const [fridayRecords, setFridayRecords] = useState([]);
  const [saturdayRecords, setSaturdayRecords] = useState([]);
  const [sundayRecords, setSundayRecords] = useState([]);
  const [renderRecords, setRenderRecords] = useState(false);
  const [employeeList, setEmployeeList] = useState(false);
  const [leader, setLeader] = useState(false);
  const [selectedUser, setSelectedUser] = useState("Self");
  const [renderInfo, setRenderInfo] = useState(false);
  const [weeklyHours, setWeeklyHours] = useState("0.00 HOURS");
  const [changes, setChanges] = useState([]);
  const [userListWithData, setUserListWithData] = useState([]);
  const clearRef = useRef();
  const [triggerAlert, setTriggerAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Alert Message");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [changesMade, setChangesMade] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState();

  useEffect(() => {
    setYears(generateYearsList());
    updateMonthsAndWeeks(selectedYear, new Date().getMonth());
    if (user.userType !== "Employee") {
      // setEmployeeList(generateEmployeeList());
      setLeader(true);
    }

    var employeeLocalList = [];

    employeeLocalList.push("Self");

    if (user.business.users !== null) {
      for (let i = 0; i < user.business.users.length; i++) {
        if (
          user.business.users[i].userType !== "Owner" &&
          user.business.users[i] !== user.userId &&
          user.business.users[i].userType !== "Scanner"
        ) {
          let fullName =
            user.business.users[i].firstName +
            " " +
            user.business.users[i].lastName;
          employeeLocalList.push(
            user.business.users[i].firstName +
              " " +
              user.business.users[i].lastName
          );
        }
      }
    }

    setEmployeeList(employeeLocalList);
  }, [selectedYear]);

  const toggleInfoSection = () => {
    setRenderInfo(!renderInfo);
  };

  const isCurrentWeek = (weekString) => {
    const [start, end] = weekString
      .split(" - ")
      .map((dateStr) => new Date(`${dateStr}, ${selectedYear}`));
    const today = new Date();
    return start <= today && today <= end;
  };

  const generateEmployeeList = () => {
    let userList = [];
    userList.push("Self");
    for (let i = 1; i < user.business.users.length; i++) {
      if (
        user.business.users[i].userType !== "Owner" &&
        user.business.users[i].userType !== "Scanner"
      ) {
        userList.push(
          user.business.users[i].firstName +
            " " +
            user.business.users[i].lastName
        );

        let newUser = {
          userId: user.business.users[i].userId,
          name:
            user.business.users[i].firstName +
            " " +
            user.business.users[i].lastName,
        };

        setUserListWithData((userListWithData) => [
          ...userListWithData,
          newUser,
        ]);
      }
    }

    return userList;
  };

  const generateYearsList = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => currentYear - i);
  };

  const generateMonthsList = (year) => {
    let monthsList = [];
    let currentMonth = new Date().getMonth();
    let monthsToAdd = year === new Date().getFullYear() ? currentMonth : 11;

    for (let i = 0; i <= monthsToAdd; i++) {
      monthsList.push(
        new Date(year, i, 1).toLocaleString("default", { month: "long" })
      );
    }

    return monthsList;
  };

  const findFirstMondayOfYear = (year) => {
    const date = new Date(year, 0, 1);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  const generateWeeksList = (year, month) => {
    let weeksList = [];
    let currentDate = findFirstMondayOfYear(year);
    let weekNumber = 1;
    const currentYear = new Date().getFullYear();
    const today = new Date();

    while (
      currentDate.getFullYear() === year &&
      (year < currentYear || currentDate <= today)
    ) {
      const startOfWeek = new Date(currentDate);
      const endOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() + 6)
      );
      weeksList.push(
        `Week ${weekNumber}: ${formatDate(startOfWeek)} - ${formatDate(
          endOfWeek
        )}`
      );
      currentDate.setDate(currentDate.getDate() + 1);
      weekNumber++;
    }

    // Filter weeks to include only those within the selected month
    return weeksList
      .filter((week) => {
        const [start, end] = week
          .match(/Week \d+: (\w+ \d+) - (\w+ \d+)/)
          .slice(1, 3);
        const startMonth = new Date(`${start}, ${year}`).getMonth();
        const endMonth = new Date(`${end}, ${year}`).getMonth();
        return startMonth === month || endMonth === month;
      })
      .reverse();
  };

  const updateMonthsAndWeeks = (year, month, resetMonth = true) => {
    const newMonths = generateMonthsList(year);
    setMonths(newMonths);
    const newWeeks = generateWeeksList(year, month);
    setWeeks(newWeeks);

    if (resetMonth) {
      if (year === new Date().getFullYear()) {
        setSelectedMonth(newMonths[new Date().getMonth()]);
      } else {
        setSelectedMonth(newMonths[0]);
      }
    }

    if (newWeeks.length > 0) {
      setSelectedWeek(newWeeks[0]);
    } else {
      setSelectedWeek("");
    }
  };

  const getISOWeekNumber = (date) => {
    const firstMonday = findFirstMondayOfYear(date.getFullYear());
    const daysSinceFirstMonday = Math.floor(
      (date - firstMonday) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil((daysSinceFirstMonday + 1) / 7);
  };

  const formatDate = (date) => {
    return date.toLocaleString("default", { month: "short", day: "numeric" });
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setSelectedYear(newYear);
    updateMonthsAndWeeks(newYear, 0); // Reset to January for a new year
  };

  const handleMonthChange = (event) => {
    const monthName = event.target.value;
    const monthIndex = new Date(`${monthName} 1, 2000`).getMonth();
    setSelectedMonth(monthName);
    updateMonthsAndWeeks(selectedYear, monthIndex, false);
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);


    if (event.target.value === 'Self') {
      setSelectedUserId(user.userId);
    } else {
      for (let i = 0; i < user.business.users.length; i++) {
        let name = user.business.users[i].firstName + " " + user.business.users[i].lastName; 
        if (event.target.value === name) {
          setSelectedUserId(user.business.users[i].userId);
        }
      }
    }
  };

  const renderInfoClick = () => {
    setRenderInfo(true);
  };

  const handleSaveClick = () => {
    setChangesMade(true);

    let finalChanges = processAndConsolidateChanges();

    for (let i = finalChanges.length - 1; i >= 0; i--) {
      if (
        typeof finalChanges[i].clockRecordId === "string" &&
        finalChanges[i].clockRecordId &&
        finalChanges[i].clockRecordId.includes("EDIT") &&
        finalChanges[i].change === "edit"
      ) {
        for (let j = changes.length - 1; j >= 0; j--) {
          if (finalChanges[i].clockRecordId === changes[j].clockRecordId) {
            finalChanges[i].inOrOut = changes[j].inOrOut;
          }
        }
      }
    }

    let recordChangeDTO = {
      userId: user.userId,
      businessId: user.business.businessId,
      email: user.email,
      userType: user.userType,
      accessToken: googleUser.access_token,
      recordChanges: finalChanges,
    };

    axios
      .post(`${baseURL}/editRecords`, recordChangeDTO)
      .then((response) => {
        onSaveResponse({ response });
        user.clockRecords = response.data.clockRecords;
        renderWeekRecords();

        if (response.data.message === 'Future Dates') {
          setAlertSeverity("error");
          setAlertMsg(
            "ChronoLeaf does not currently support setting clock records that occur on future dates."
          );
          setTriggerAlert(true);
        } else if (response.data.message === 'Invalid Delete' || response.data.message === 'Invalid Add') {
          setAlertSeverity("error");
          setAlertMsg(
            "Please note that for a given day you must ensure either there no records for that day or the records remain alternating between IN and OUT and that the first record is IN and the last record is OUT."
          );
          setTriggerAlert(true);
        } else {
          setAlertSeverity("success");
          setAlertMsg("Successfully saved the changes.");
          setTriggerAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setAlertMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setAlertMsg(
            "An error occured while saving the changes. Please contact support if this continues."
          );          
        }

        setAlertSeverity("error");
        setTriggerAlert(true);
      });

    setChanges([]);
    if (clearRef.current && clearRef.current.clearFunction) {
      clearRef.current.clearFunction();
    }
  };

  const processAndConsolidateChanges = () => {
    const consolidatedChanges = {};

    // Iterate through the changes in reverse to get the last occurrence of each clockRecordId
    for (let i = changes.length - 1; i >= 0; i--) {
      const change = changes[i];
      const id = change.clockRecordId;

      // If the id is not already in consolidatedChanges, add it
      if (!consolidatedChanges[id]) {
        consolidatedChanges[id] = change;
      }
    }

    return Object.values(consolidatedChanges);
  };

  const renderWeekRecords = () => {
    const weekMatch = selectedWeek.match(/Week \d+: (\w+ \d+) - (\w+ \d+)/);
    if (!weekMatch) return;

    const convertDateString = (year, dateString) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const [month, day] = dateString.split(" ");
      const monthIndex = monthNames.indexOf(month);
      return `${year}-${monthIndex + 1}-${day.padStart(2, "0")}`;
    };

    const startDateString = convertDateString(selectedYear, weekMatch[1]);
    const endDateString = convertDateString(selectedYear, weekMatch[2]);
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    endDate.setDate(endDate.getDate() + 1);

    let relatedUserId = "";

    if (selectedUser !== "Self") {
      const nameParts = selectedUser.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts[1];

      for (let i = 1; i < user.business.users.length; i++) {
        if (
          user.business.users[i].firstName === firstName &&
          user.business.users[i].lastName === lastName
        ) {
          relatedUserId = user.business.users[i].userId;
        }
      }
    } else {
      relatedUserId = user.userId;
    }

    let WeekRecordsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      userId: user.userId,
      relatedUserId: selectedUserId,
      businessId: user.business.businessId,
      startDate: startDateString,
      endDate: endDateString,
    };

    let searchRecords = [];

    const isWithinWeek = (dateString) => {
      const date = new Date(dateString);
      return date >= startDate && date <= endDate;
    };

    axios
      .post(`${baseURL}/weekClockRecords`, WeekRecordsDTO)
      .then((response) => {
        searchRecords = response.data.weekRecords;

        let mondayRecords = [];
        let tuesdayRecords = [];
        let wednesdayRecords = [];
        let thursdayRecords = [];
        let fridayRecords = [];
        let saturdayRecords = [];
        let sundayRecords = [];

        if (searchRecords !== null && searchRecords.length > 0) {
          searchRecords.forEach((record) => {
            // if (!isWithinWeek(record.timeRecorded)) {
            //   return;
            // }

            const dayOfWeek = new Date(record.timeRecorded).getDay();
            switch (dayOfWeek) {
              case 1:
                mondayRecords.push(record);
                break;
              case 2:
                tuesdayRecords.push(record);
                break;
              case 3:
                wednesdayRecords.push(record);
                break;
              case 4:
                thursdayRecords.push(record);
                break;
              case 5:
                fridayRecords.push(record);
                break;
              case 6:
                saturdayRecords.push(record);
                break;
              case 0:
                sundayRecords.push(record);
                break;
              default:
                break;
            }
          });
        }

        setMondayRecords([...mondayRecords]);
        setTuesdayRecords([...tuesdayRecords]);
        setWednesdayRecords([...wednesdayRecords]);
        setThursdayRecords([...thursdayRecords]);
        setFridayRecords([...fridayRecords]);
        setSaturdayRecords([...saturdayRecords]);
        setSundayRecords([...sundayRecords]);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setAlertMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setAlertMsg(
            "An error occured while retrieving this weeks clock records. Please contact support if this continues."
          );            
        }
        
        setAlertSeverity("error");
        setTriggerAlert(true);
      })
      .finally(() => {
        // ensure this runs after everything is settled
        setRenderRecords(true);
      });
  };

  const handleDailyTotal = (dailyTotal) => {
    setWeeklyHours(dailyTotal);
  };

  const handleWeekEdit = (newChange) => {
    setChangesMade(false);
  
    if (newChange.message !== null && (newChange.message === 'GFD' || newChange.message === 'BFD')) {
      setChanges(prevChanges => {
        const updatedChanges = prevChanges.map(change => {
          if (change.day !== null && change.day === newChange.day) {
            return { ...change, goodForDay: newChange.message === 'GFD' };
          }
          return change;
        });
        return updatedChanges;
      });
    } else {
      setChanges(prevChanges => [...prevChanges, newChange]);
    }
  };

  const handleRefreshClick = () => {
    setChangesMade(true);
    setChanges([]);
    renderWeekRecords();
    if (clearRef.current && clearRef.current.clearFunction) {
      clearRef.current.clearFunction();
    }
  };

  return (
    <div className="home-container">
      <div className="weekly-header-container">
        <div className="home-header-input-and-hours-container">
          <FormControl>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                "&.MuiInputLabel-root": {
                  color: "black",
                },
                "&.MuiInputLabel-root.Mui-focused": {
                  color: "black",
                  fontWeight: "bolder",
                },
                mt: 1,
                "@media (max-width: 830px)": {
                  ml: 1,
                },
              }}
            >
              Year
            </InputLabel>
            <Select
              value={selectedYear}
              label="Year"
              onChange={handleYearChange}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#2F3E46",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                height: "40px",
                mt: 1,
                "@media (max-width: 830px)": {
                  width: "230px",
                  ml: 1,
                },
              }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ ml: 1 }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                "&.MuiInputLabel-root": {
                  color: "black",
                },
                "&.MuiInputLabel-root.Mui-focused": {
                  color: "black",
                  fontWeight: "bolder",
                },
                mt: 1,
              }}
            >
              Month
            </InputLabel>
            <Select
              value={selectedMonth}
              label="Month"
              onChange={handleMonthChange}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#2F3E46",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                height: "40px",
                mt: 1,
              }}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ ml: 1 }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                "&.MuiInputLabel-root": {
                  color: "black",
                },
                "&.MuiInputLabel-root.Mui-focused": {
                  color: "black",
                  fontWeight: "bolder",
                },
                mt: 1,
              }}
            >
              Week
            </InputLabel>
            <Select
              value={selectedWeek}
              label="Week"
              onChange={handleWeekChange}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#2F3E46",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
                height: "40px",
                mt: 1,
              }}
            >
              {weeks.map((week) => (
                <MenuItem key={week} value={week}>
                  {week}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {leader && (
            <FormControl sx={{ ml: 1 }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  "&.MuiInputLabel-root": {
                    color: "black",
                  },
                  "&.MuiInputLabel-root.Mui-focused": {
                    color: "black",
                    fontWeight: "bolder",
                  },
                  mt: 1,
                }}
              >
                User
              </InputLabel>
              <Select
                value={selectedUser}
                label="User"
                onChange={handleUserChange}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#2F3E46",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                  height: "40px",
                  mt: 1,
                }}
              >
                {employeeList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <Button
              variant="outlined"
              disabled
              sx={{
                border: "1px solid black",
                ml: 1,
                mt: "auto",
                mb: "auto",
                height: "40px",
                color: "black",
                "&.Mui-disabled": {
                  borderColor: "black",
                  color: "white",
                  backgroundColor: "#3E606D",
                },
                "@media (max-width: 830px)": {
                  mt: 1,
                },
              }}
            >
              {weeklyHours}
            </Button>
          </FormControl>
          <div className="home-header-hours"></div>
        </div>
        <div className="home-header-buttons-container">
          {leader && (
            <>
              <IconButton
                aria-label="refresh-icon"
                onClick={handleRefreshClick}
                disabled={changesMade}
              >
                <img
                  src={RefreshIcon}
                  alt="Refresh Icon Button"
                  style={{ height: "36px", opacity: changesMade ? "0.5" : "1" }}
                />
              </IconButton>
              <IconButton
                aria-label="save-icon"
                onClick={handleSaveClick}
                disabled={changesMade}
              >
                <img
                  src={SaveIcon}
                  alt="Save Icon Button"
                  style={{ height: "28px", opacity: changesMade ? "0.5" : "1" }}
                />
              </IconButton>
            </>
          )}
          <IconButton aria-label="info-icon" onClick={renderInfoClick}>
            <img
              src={InfoIcon}
              alt="Info Icon Button"
              style={{ height: "36px" }}
            />
          </IconButton>
          <IconButton aria-label="enter-icon" onClick={renderWeekRecords}>
            <img
              src={EnterIcon}
              alt="Enter Icon Button"
              style={{ height: "40px" }}
            />
          </IconButton>
        </div>
      </div>
      <Stack sx={{ width: "100%" }}>
        {triggerAlert && (
          <Alert
            severity={alertSeverity}
            onClose={() => setTriggerAlert(false)}
          >
            {alertMsg}
          </Alert>
        )}
      </Stack>
      <div className="weekly-clock-records-container">
        {renderRecords && (
          <WeeklyClockRecords
            mondayRecords={mondayRecords}
            tuesdayRecords={tuesdayRecords}
            wednesdayRecords={wednesdayRecords}
            thursdayRecords={thursdayRecords}
            fridayRecords={fridayRecords}
            saturdayRecords={saturdayRecords}
            sundayRecords={sundayRecords}
            onDailyTotal={handleDailyTotal}
            isLeader={leader}
            onWeekEdit={handleWeekEdit}
            dateRange={selectedWeek}
            year={selectedYear}
            user={selectedUser}
            userId={selectedUserId}
            operator={user}
            userListInfo={userListWithData}
            ref={clearRef}
            selectedUserId={selectedUserId}
          />
        )}
      </div>
      <BootstrapDialog
        onClose={toggleInfoSection}
        aria-labelledby="customized-dialog-title"
        open={renderInfo}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Weekly Clock Records - Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleInfoSection}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <Grid container spacing={2}>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
                    <img 
                        src={In}
                        className="info-icon"
                    />
                <Typography sx={{paddingLeft: '10px'}}>
                Clock in Records
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={Out}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Clock out Records
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={Add}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Added Records
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={Edit}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Edited Records
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={EnterIcon}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Get Week Records
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={SaveIcon}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Save Changes
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection:'row', width: '175px', ml: 'auto', mr: 'auto', padding: '5px', mt: 2}}>
            <img 
                                src={RefreshIcon}
                                className="info-icon"
                            />
                <Typography sx={{paddingLeft: '10px'}}>
                Refresh Edits
                </Typography>
            </Paper>
            </Grid>
          <Typography gutterBottom sx={{mt: 2}}>
            The filters load based on the current week and user. Future years,
            months, and weeks are not displayed. Make filter selections as
            desired and click the enter icon to load the indicated week for the
            selected employee.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            Click the add icon to add a new record. It will default to being
            IN/OUT depending on the last record - or it will be IN if there are
            no records for that day. Additionally, it will be 1 minute after the
            last record - or the current time if there are no records for that
            day.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            Clicking the Delete icon on the right side of a record will remove
            the record.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            You can also edit existing or new records by clicking them - please
            note that it won't allow you to enter a time that is before the last
            record (if there are records for that day).
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            Lastly, when you are satisfied with your changes - simply click the
            save icon, you will be notified shortly after of the successful
            changes. If you wish to reset your changes, simply click the refresh
            icon.
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button
                autoFocus
                variant="contained"
                size="medium"
                sx={{
                  ml: 'auto',
                  mr: 'auto',
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={toggleInfoSection}
              >
                <Typography>Close</Typography>
              </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default Home;
