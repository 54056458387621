import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is used or remove it if unnecessary
import "./Settings.css";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Paper, useMediaQuery } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import env from "../environmentVariables";
import fp from "./fp.png";
import nfc from "./nfc.png";
import qrImg from "./qr.png";
import Tooltip from "@mui/material/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Settings = ({ googleUser, user, onUserCrudResponse }) => {
  const { stripePortal } = env;
  const { stripeNfcPay } = env;
  const { baseURL } = env;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [allManagerList, setAllManagerList] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [role, setRole] = useState("");
  const [action, setAction] = useState("");
  const [editEmployee, setEditEmployee] = useState(false);
  const [editManager, setEditManager] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [dailyCode, setDailyCode] = useState(false);
  const [qr, setQr] = useState(false);
  const [rfid, setRfid] = useState(false);
  const [biometrics, setBiometrics] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Success");
  const [errorMsg, setErrorMsg] = useState(
    "An error has occured, please try later."
  );
  const [noEmployees, setNoEmployees] = useState();
  const [noManagers, setNoManagers] = useState();
  const [scannerEmail, setScannerEmail] = useState("");
  const [scannerEmailError, setScannerEmailError] = useState(false);
  const [currentScanner, setCurrentScanner] = useState("N/A");
  const [pinCode, setPinCode] = useState("N/A");
  const isSmallScreen = useMediaQuery("(max-width:915px)");
  const [timezone, setTimezone] = useState("");
  const [wage, setWage] = useState(0.0);

  const [phone, setPhone] = useState("");

  const [tardyAlert, setTardyAlert] = useState(true);
  const [overTimeAlert, setOverTimeAlert] = useState(true);
  const [startOfWeek, setStartOfWeek] = useState("Monday");

  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

  const [stateError, setStateError] = useState(false);

  const [selectedState, setSelectedState] = useState("");

  const [phoneError, setPhoneError] = useState(false);

  const [alertRate, setAlertRate] = useState(0);

  const [view, setView] = React.useState("add");

  const [scannerView, setScannerView] = React.useState("config-scanner");

  const handleToggleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleScannerToggleChange = (event, nextView) => {
    setScannerView(nextView);
  };

  const [allDepartmentList, setAllDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEditDepartment, setSelectedEditDepartment] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [departmentError, setDepartmentError] = useState(false);
  const [editDepartmentName, setEditDepartmentName] = useState("");
  const [editDepartmentError, setEditDepartmentError] = useState(false);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [receiveAlerts, setReceiveAlerts] = useState(false);
  const [ownerAlerts, setOwnerAlerts] = useState(false);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [distance, setDistance] = useState(0);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    var employeeList = [];
    var managerList = [];

    setWage(user.wage);

    if (user.business.users !== null) {
      for (let i = 0; i < user.business.users.length; i++) {
        if (
          user.business.users[i].userType !== "Owner" &&
          user.business.users[i] !== user.userId &&
          user.business.users[i].userType !== "Scanner"
        ) {
          let fullName =
            user.business.users[i].firstName +
            " " +
            user.business.users[i].lastName;
          const newEmployee = { ...user.business.users[i], fullName: fullName };
          employeeList.push(newEmployee);

          if (user.business.users[i].userType === "Manager") {
            managerList.push(newEmployee);
          }
        }
      }
    }

    setAllEmployeeList(employeeList);
    if (
      user.business != null &&
      user.business.departments != null &&
      user.business.departments.length > 0
    ) {
      setAllDepartmentList(user.business.departments);
      setSelectedDepartment(user.business.departments[0].departmentId);
      setSelectedEditDepartment(user.business.departments[0].departmentId);
      setEditDepartmentName(user.business.departments[0].departmentName);
    }

    if (employeeList.length === 0) {
      setNoEmployees(true);
      handleChangeIndex(2);
    } else {
      let noManagers = true;
      for (let i = 0; i < employeeList.length; i++) {
        if (
          employeeList[i].userType !== null &&
          employeeList[i].userType === "Manager"
        ) {
          noManagers = false;
        }
      }
      setNoManagers(noManagers);
    }

    setAllManagerList(managerList);
    setDailyCode(user.business.dailyCode);
    setQr(user.business.qr);
    setRfid(user.business.rfid);
    setBiometrics(user.business.biometrics);
    setTardyAlert(user.business.tardyAlert);
    setOverTimeAlert(user.business.overTimeAlert);
    setSelectedState(user.business.state);
    setPhone(user.business.phone);
    setAlertRate(user.business.alertRate);
    setOwnerAlerts(user.business.ownerAlerts);
    setLatitude(user.business.latitude);
    setLongitude(user.business.longitude);
    setDistance(user.business.distance);

    if (user.business.scanner != null) {
      setCurrentScanner(user.business.scanner);
      setPinCode(user.business.pinCode);
    }

    if (employeeList.length > 0) {
      setSelectedEmployee(employeeList[0].userId);
      setRole(employeeList[0].userType);

      if (employeeList[0].userType === "Manager") {
        setAction("Demote");
      } else {
        setAction("Promote");
      }
    } else {
      setSelectedEmployee("");
    }

    if (managerList.length > 0) {
      setSelectedManager(managerList[0].userId);

      setAddUser(managerList[0].addUser);
      setDeleteUser(managerList[0].deleteUser);
      setEditEmployee(managerList[0].editEmployee);
      setEditManager(managerList[0].editManager);
      setReceiveAlerts(managerList[0].receiveAlerts);
    } else {
      setSelectedManager("");
    }
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubChange = (event, newValue) => {
    setSubValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSubChangeIndex = (index) => {
    setSubValue(index);
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
    for (let i = 0; i < allEmployeeList.length; i++) {
      if (event.target.value === allEmployeeList[i].userId) {
        setRole(allEmployeeList[i].userType);

        if (allEmployeeList[i].userType === "Manager") {
          setAction("Demote");
        } else {
          setAction("Promote");
        }
      }
    }
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleStartOfWeekChange = (event) => {
    setStartOfWeek(event.target.value);
  };

  const handleEditDepartmentChange = (event) => {
    setSelectedEditDepartment(event.target.value);
    for (let i = 0; i < allDepartmentList.length; i += 1) {
      if (allDepartmentList[i].departmentId === event.target.value) {
        setEditDepartmentName(allDepartmentList[i].departmentName);
      }
    }
  };

  const handleDepartmentNameChange = (event) => {
    setDepartmentName(event.target.value);
    if (event.target.value != null && event.target.value != "") {
      setDepartmentError(false);
    }
  };

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);

    if (event.target.value == "") {
      setLatitude(0.0);
    }
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);

    if (event.target.value == "") {
      setLongitude(0.0);
    }
  };

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);

    if (event.target.value == "") {
      setDistance(0);
    }
  };

  const handleEditDepartmentNameChange = (event) => {
    setEditDepartmentName(event.target.value);
    if (event.target.value != null && event.target.value != "") {
      setEditDepartmentError(false);
    }
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setStateError(false);
    }
  };

  const handlePhoneChange = (event) => {
    let value = event.target.value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length > 3 && onlyNums.length <= 6) {
      value = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    } else if (onlyNums.length > 6) {
      value = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
        6,
        10
      )}`;
    } else {
      value = onlyNums;
    }

    setPhone(value);
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    if (phoneRegex.test(value)) {
      setPhoneError(false);
    }
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);

    for (let i = 0; i < allManagerList.length; i++) {
      if (event.target.value === allManagerList[i].userId) {
        setAddUser(allManagerList[i].addUser);
        setDeleteUser(allManagerList[i].deleteUser);
        setEditEmployee(allManagerList[i].editEmployee);
        setEditManager(allManagerList[i].editManager);
        setReceiveAlerts(allManagerList[i].receiveAlerts);
      }
    }
  };

  const handleAddUserChange = () => {
    setAddUser(!addUser);
  };

  const handleDeleteUserChange = () => {
    setDeleteUser(!deleteUser);
  };

  const handleEditEmployeeChange = () => {
    setEditEmployee(!editEmployee);
  };

  const handleReceiveAlertsChange = () => {
    setReceiveAlerts(!receiveAlerts);
  };

  const handleOwnerAlertsChange = () => {
    setOwnerAlerts(!ownerAlerts);
  };

  const handleTardyChange = () => {
    setTardyAlert(!tardyAlert);
  };

  const handleOverTimeChange = () => {
    setOverTimeAlert(!overTimeAlert);
  };

  const handleEditManagerChange = () => {
    setEditManager(!editManager);
  };

  const handleDailyCodeChange = () => {
    setDailyCode(!dailyCode);
  };

  const handleRfidChange = () => {
    setRfid(!rfid);
  };

  const handleBiometricsChange = () => {
    setBiometrics(!biometrics);
  };

  const handleQrChange = () => {
    setQr(!qr);
  };

  const handleActionClick = () => {
    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      category: "ROLES",
      action: action,
      relatedUserId: selectedEmployee,
      dailyCode: false,
      qr: false,
      rfid: false,
      biometrics: false,
      editEmployee: false,
      editManager: false,
      addUser: false,
      deleteUser: false,
    };

    axios
      .post(`${baseURL}/promoteOrDemoteEmployee`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        let resultAction = action.slice(0, -1);
        setSuccessMsg("Successful " + resultAction + "ion.");
        setTriggerSuccess(true);
        setAction(!action);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }
        setTriggerError(true);
      });
  };

  const switchStyles = {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: "#7e4338", // Color of the thumb when unchecked
    },
    "&.MuiSwitch-root .Mui-checked": {
      color: "#52796F", // Color of the thumb when checked
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#84A98C", // Color of the track when checked
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#9e5d51", // Color of the track when unchecked
    },
  };

  const handlePermissionsSave = () => {
    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      category: "ROLES",
      action: "",
      relatedUserId: selectedManager,
      dailyCode: false,
      qr: false,
      rfid: false,
      biometrics: false,
      editEmployee: editEmployee,
      editManager: editManager,
      addUser: addUser,
      deleteUser: deleteUser,
      receiveAlerts: receiveAlerts,
    };

    axios
      .post(`${baseURL}/editPermissions`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        setSuccessMsg("Successful permissions save.");
        setTriggerSuccess(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }

        setTriggerError(true);
      });
  };

  const handleMethodsSave = () => {
    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      category: "ROLES",
      action: "",
      relatedUserId: 0,
      dailyCode: dailyCode,
      qr: qr,
      rfid: rfid,
      biometrics: biometrics,
      editEmployee: false,
      editManager: false,
      addUser: false,
      deleteUser: false,
    };

    axios
      .post(`${baseURL}/editClockInMethods`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        setSuccessMsg("Successful clock in methods save.");
        setTriggerSuccess(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }

        setTriggerError(true);
      });
  };

  const validateLatitude = () => {
    let numLatitude = parseFloat(latitude);
    if (isNaN(numLatitude)) {
      setErrorMsg("Latitude must be a numeric value.");
      setTriggerError(true);
      return false;
    }

    if (numLatitude < -1000 || numLatitude > 1000) {
      setErrorMsg("Latitude must be between -1000 and 1000.");
      setTriggerError(true);
      return false;
    }

    return true;
  };

  const validateLongitude = () => {
    let numLongitude = parseFloat(longitude);
    if (isNaN(numLongitude)) {
      setErrorMsg("Longitude must be a numeric value.");
      setTriggerError(true);
      return false;
    }

    if (numLongitude < -1000 || numLongitude > 1000) {
      setErrorMsg("Longitude must be between -1000 and 1000.");
      setTriggerError(true);
      return false;
    }

    return true;
  };

  const validateDistance = () => {
    let numDistance = parseFloat(distance);
    if (isNaN(numDistance)) {
      setErrorMsg("Distance must be a numeric value.");
      setTriggerError(true);
      return false;
    }

    if (numDistance < 0 || numDistance > 1000) {
      setErrorMsg("Distance must be between 0 and 1000.");
      setTriggerError(true);
      return false;
    }

    return true;
  };

  const validateWage = () => {
    let numWage = parseFloat(wage);
    if (isNaN(numWage)) {
      setErrorMsg("The wage must be a numeric value.");
      setTriggerError(true);
      return false;
    }

    if (numWage < 0 || numWage > 2000) {
      setErrorMsg("Wage must be between 0 and 2000.");
      setTriggerError(true);
      return false;
    }

    return true;
  };

  const handleGeoSave = () => {
    if (!validateLatitude() || !validateLongitude || !validateDistance) {
      return;
    }

    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      category: "ROLES",
      action: "",
      relatedUserId: 0,
      dailyCode: dailyCode,
      qr: qr,
      rfid: rfid,
      biometrics: biometrics,
      editEmployee: false,
      editManager: false,
      addUser: false,
      deleteUser: false,
      latitude: latitude,
      longitude: longitude,
      distance: distance,
    };

    axios
      .post(`${baseURL}/editGeo`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        setSuccessMsg("Successful geo-location settings save.");
        setTriggerSuccess(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }

        setTriggerError(true);
      });
  };

  const dynamicSuccessOrErrorStyle =
    triggerSuccess || triggerError ? { borderBottom: "1px solid black" } : {};

  const handleScannerEmailChange = (event) => {
    setScannerEmail(event.target.value);
    setScannerEmailError(false);
  };

  const isValidGoogleEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return emailRegex.test(email);
  };

  const handleAlertRateChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "" || (Number(newValue) <= 500 && Number(newValue) >= 0)) {
      setAlertRate(newValue);
    }
  };

  const blockInvalidChar = (e) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };

  const blockInvalidChar2 = (e) => {
    if (["e", "E", "+"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const blockInvalidChar3 = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleOwnerWageClick = () => {

    if (!validateWage()) {
      return;
    }

    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      wage: wage
    };

    axios
      .post(`${baseURL}/editOwnersWage`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        setSuccessMsg("Successfully edited your wage.");
        setTriggerSuccess(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }

        setTriggerError(true);
      });
  };

  const handleWageChange = (event) => {
    setWage(event.target.value);
  };

  const handleAlertsSave = () => {
    let SettingsDTO = {
      operatorEmail: user.email,
      operatorToken: googleUser.access_token,
      operatorUserType: user.userType,
      businessId: user.business.businessId,
      category: "ROLES",
      action: "",
      relatedUserId: 0,
      dailyCode: dailyCode,
      qr: qr,
      rfid: rfid,
      biometrics: biometrics,
      editEmployee: false,
      editManager: false,
      addUser: false,
      deleteUser: false,
      tardyAlert: tardyAlert,
      overTimeAlert: overTimeAlert,
      alertRate: alertRate,
      startOfWeek: startOfWeek,
      ownerAlerts: ownerAlerts,
    };

    axios
      .post(`${baseURL}/editAlerts`, SettingsDTO)
      .then((response) => {
        onUserCrudResponse({ response });
        setSuccessMsg("Successful alert save.");
        setTriggerSuccess(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setErrorMsg(
            "It appears you are making too many requests. Please wait a moment then try again."
          );
        } else {
          setErrorMsg("An error has occured, please try later.");
        }

        setTriggerError(true);
      });
  };

  const handleContactSave = () => {
    setPhoneError(!phoneRegex.test(phone));

    if (phoneRegex.test(phone)) {
      let SettingsDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        businessId: user.business.businessId,
        category: "ROLES",
        action: "",
        relatedUserId: 0,
        dailyCode: dailyCode,
        qr: qr,
        rfid: rfid,
        biometrics: biometrics,
        editEmployee: false,
        editManager: false,
        addUser: false,
        deleteUser: false,
        phone: phone,
        state: selectedState,
      };

      axios
        .post(`${baseURL}/editContact`, SettingsDTO)
        .then((response) => {
          onUserCrudResponse({ response });
          setSuccessMsg("Successful clock in methods save.");
          setTriggerSuccess(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg(
              "It appears you are making too many requests. Please wait a moment then try again."
            );
          } else {
            setErrorMsg("An error has occured, please try later.");
          }

          setTriggerError(true);
        });
    } else {
      setErrorMsg("Please ensure you entered the required input.");
      setTriggerError(true);
    }
  };

  const handleScannerSubmit = () => {
    setScannerEmailError(
      scannerEmail === "" ||
        scannerEmail === null ||
        !isValidGoogleEmail(scannerEmail)
    );

    if (
      !(
        scannerEmail === "" ||
        scannerEmail === null ||
        !isValidGoogleEmail(scannerEmail)
      )
    ) {
      let UserDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        businessId: user.business.businessId,
        newEmail: scannerEmail,
      };

      axios
        .post(`${baseURL}/scanner`, UserDTO)
        .then((response) => {
          onUserCrudResponse({ response });
          setSuccessMsg("Successfuly added your Scanner account.");
          setTriggerSuccess(true);
        })
        .catch((error) => {
          if (
            error.response.data === "Error: Duplicate Email exists" ||
            error.response.status === 409
          ) {
            setErrorMsg("It appears that email is already in use.");
            setTriggerError(true);
          } else if (error.response && error.response.status === 429) {
            setErrorMsg(
              "It appears you are making too many requests. Please wait a moment then try again."
            );
            setTriggerError(true);
          } else {
            setErrorMsg("An error has occured, please try later.");
            setTriggerError(true);
          }
        });
    } else {
      setErrorMsg(
        "Please ensure names are populated and a valid google email."
      );
      setTriggerError(true);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAddDepartment = () => {
    setDepartmentError(departmentName == null || departmentName == "");

    if (!(departmentName == null || departmentName == "")) {
      let SettingsDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        operatorId: user.userId,
        businessId: user.business.businessId,
        category: "ROLES",
        action: "ADD DEPARTMENT",
        relatedUserId: 0,
        dailyCode: dailyCode,
        qr: qr,
        rfid: rfid,
        biometrics: biometrics,
        editEmployee: false,
        editManager: false,
        addUser: false,
        deleteUser: false,
        departmentName: departmentName,
      };

      axios
        .post(`${baseURL}/editDepartments`, SettingsDTO)
        .then((response) => {
          if (response.data.message === "ADDED") {
            onUserCrudResponse({ response });
            setSuccessMsg("Successfully added " + departmentName);
            setTriggerSuccess(true);
          } else if (response.data.message === "LIMIT") {
            setErrorMsg(
              "Not added - you have reached your limit of 5 departments."
            );
            setTriggerError(true);
          } else {
            setErrorMsg(
              "Something went wrong - please ensure this department does not already exist."
            );
            setTriggerError(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg(
              "It appears you are making too many requests. Please wait a moment then try again."
            );
          } else {
            setErrorMsg("An error has occured, please try later.");
          }

          setTriggerError(true);
        });
    } else {
      setErrorMsg("Please ensure you enter a valid department name.");
      setTriggerError(true);
    }
  };

  const handleDeleteDepartment = () => {
    let delDepName = "";
    let delId = -1;

    for (let i = 0; i < allDepartmentList.length; i += 1) {
      if (allDepartmentList[i].departmentId === selectedDepartment) {
        delDepName = allDepartmentList[i].departmentName;
        delId = allDepartmentList[i].departmentId;
      }
    }

    if (delDepName.toUpperCase() == "ALL") {
      setErrorMsg("You can not delete the All department.");
      setTriggerError(true);
    } else {
      let SettingsDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        businessId: user.business.businessId,
        category: "ROLES",
        action: "DELETE DEPARTMENT",
        relatedUserId: delId,
        dailyCode: dailyCode,
        qr: qr,
        rfid: rfid,
        biometrics: biometrics,
        editEmployee: false,
        editManager: false,
        addUser: false,
        deleteUser: false,
        departmentName: delDepName,
      };

      axios
        .post(`${baseURL}/editDepartments`, SettingsDTO)
        .then((response) => {
          if (response.data.message === "DELETED") {
            onUserCrudResponse({ response });
            setSuccessMsg("Successfully deleted the department: " + delDepName);
            setTriggerSuccess(true);
          } else {
            setErrorMsg(
              "Something went wrong - please ensure this department does not already exist."
            );
            setTriggerError(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg(
              "It appears you are making too many requests. Please wait a moment then try again."
            );
          } else {
            setErrorMsg("An error has occured, please try later.");
          }

          setTriggerError(true);
        });
    }
  };

  const handleEditDepartment = () => {
    setEditDepartmentError(
      editDepartmentName == null || editDepartmentName == ""
    );

    if (!(editDepartmentName == null || editDepartmentName == "")) {
      let editDepName = "";
      let editId = -1;

      for (let i = 0; i < allDepartmentList.length; i += 1) {
        if (allDepartmentList[i].departmentId === selectedEditDepartment) {
          editDepName = allDepartmentList[i].departmentName;
          editId = allDepartmentList[i].departmentId;
        }
      }

      if (editDepName.toUpperCase() == "ALL") {
        setErrorMsg("You can not edit the All department.");
        setTriggerError(true);
      } else {
        let SettingsDTO = {
          operatorEmail: user.email,
          operatorToken: googleUser.access_token,
          operatorUserType: user.userType,
          businessId: user.business.businessId,
          category: editDepartmentName,
          action: "EDIT DEPARTMENT",
          relatedUserId: editId,
          dailyCode: dailyCode,
          qr: qr,
          rfid: rfid,
          biometrics: biometrics,
          editEmployee: false,
          editManager: false,
          addUser: false,
          deleteUser: false,
          departmentName: editDepName,
        };

        axios
          .post(`${baseURL}/editDepartments`, SettingsDTO)
          .then((response) => {
            if (response.data.message === "EDITED") {
              onUserCrudResponse({ response });
              setSuccessMsg("Successfully edited the department.");
              setTriggerSuccess(true);
            } else {
              setErrorMsg("An error has occured, please try later..");
              setTriggerError(true);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 429) {
              setErrorMsg(
                "It appears you are making too many requests. Please wait a moment then try again."
              );
            } else {
              setErrorMsg("An error has occured, please try later.");
            }

            setTriggerError(true);
          });
      }
    } else {
      setErrorMsg("Please ensure you enter required inputs.");
      setTriggerError(true);
    }
  };

  const handleStripeClick = () => {
    window.location.href = `${stripePortal}${user.email}`;
  };

  const downloadChronoLeaf = async () => {
    window.open(
      "https://www.dropbox.com/scl/fo/bsk6mv9igj3byj020p8k0/APY3bnm99fkItRb0CloxAsc?rlkey=0k9ece6r6t3sym6p0xoh063s8&st=nc0wgz5q&dl=0",
      "_blank"
    );
  };

  const handleFpClick = () => {
    window.open(
      "https://www.bayometric.com/secugen-hamster-pro-20-fingerprint-reader-scanner/",
      "_blank"
    );
  };

  const handleQrClick = () => {
    window.open(
      "https://www.amazon.com/Tera-Wireless-Barcode-Scanner-Handheld/dp/B086VCBM25?ref_=ast_sto_dp&th=1",
      "_blank"
    );
  };

  const handleNfcClick = () => {
    // Need to sell our own or forward to passninja
    window.open(`${stripeNfcPay}${user.email}`, "_blank");
  };

  return (
    <div className="settings-container">
      <div
        className="settings-content-container"
        style={dynamicSuccessOrErrorStyle}
      >
        <Box sx={{ minWidth: 320, maxWidth: 915, ml: "auto", mr: "auto" }}>
          <AppBar position="static" sx={{ backgroundColor: "#52796F" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="stacked" // Adjust based on screen size
              scrollButtons={isSmallScreen ? "auto" : false}
              allowScrollButtonsMobile
              aria-label="full width tabs example"
              orientation={isSmallScreen ? "vertical" : "horizontal"}
              sx={{
                ".MuiTabs-indicator": {
                  backgroundColor: "#343F4B",
                },
              }}
            >
              <Tab
                label="Roles"
                sx={{ ml: "auto", mr: "auto" }}
                {...a11yProps(0)}
                disabled={noEmployees}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Permissions"
                {...a11yProps(1)}
                disabled={noManagers || noEmployees}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Methods"
                {...a11yProps(2)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Scanner"
                {...a11yProps(3)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Alerts"
                {...a11yProps(4)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Contact"
                {...a11yProps(5)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Departments"
                {...a11yProps(6)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="Billing"
                {...a11yProps(6)}
              />
              <Tab
                sx={{ ml: "auto", mr: "auto" }}
                label="My Pay"
                {...a11yProps(7)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className="select-employee-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "black",
                      },
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#52796F",
                      },
                    }}
                  >
                    Employee
                  </InputLabel>
                  <Select
                    value={selectedEmployee}
                    label="Employee"
                    onChange={handleEmployeeChange}
                    displayEmpty
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "black",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#52796F",
                        },
                    }}
                  >
                    {allEmployeeList.map((emp) => (
                      <MenuItem key={emp.userId} value={emp.userId}>
                        {emp.fullName || "Unnamed User"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="role-action-container">
                <div>
                  {selectedEmployee && (
                    <Button
                      variant="outlined"
                      disabled
                      sx={{
                        border: "1px solid black",
                        color: "black",
                        "&.Mui-disabled": {
                          borderColor: "black",
                          color: "white",
                          backgroundColor: "#3E606D",
                        },
                      }}
                    >
                      {role}
                    </Button>
                  )}
                </div>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    ml: "auto",
                    mr: 0,
                    backgroundColor: "#52796F",
                    "&:hover": { backgroundColor: "#435e57" },
                  }}
                  onClick={handleActionClick}
                >
                  <Typography>{action}</Typography>
                </Button>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className="select-employee-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "black",
                      },
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#52796F",
                      },
                    }}
                  >
                    Manager
                  </InputLabel>
                  <Select
                    value={selectedManager}
                    label="Manager"
                    onChange={handleManagerChange}
                    displayEmpty
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "black",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#52796F",
                        },
                    }}
                  >
                    {allManagerList.map((mngr) => (
                      <MenuItem key={mngr.userId} value={mngr.userId}>
                        {mngr.fullName || "Unnamed User"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="add-and-edit-employee-switch-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0",
                    marginRight: "auto",
                    width: "40%",
                  }}
                >
                  <Typography>Add User</Typography>
                  <div>
                    <Switch
                      checked={addUser}
                      onChange={handleAddUserChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "0",
                    width: "40%",
                  }}
                >
                  <Typography>Delete User</Typography>
                  <div>
                    <Switch
                      checked={deleteUser}
                      onChange={handleDeleteUserChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
              </div>

              <div className="add-and-edit-employee-switch-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0",
                    marginRight: "auto",
                    width: "40%",
                  }}
                >
                  <Typography>Edit Employee</Typography>
                  <div>
                    <Switch
                      checked={editEmployee}
                      onChange={handleEditEmployeeChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "0",
                    width: "40%",
                  }}
                >
                  <Typography>Edit Manager</Typography>
                  <div>
                    <Switch
                      checked={editManager}
                      onChange={handleEditManagerChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "40%",
                }}
              >
                <Typography>Receive Alerts</Typography>
                <div>
                  <Switch
                    checked={receiveAlerts}
                    onChange={handleReceiveAlertsChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ ...switchStyles }}
                  />
                </div>
              </div>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handlePermissionsSave}
              >
                <Typography>Save</Typography>
              </Button>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <div
                className="add-and-edit-employee-switch-container"
                style={{ marginTop: "0" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0",
                    marginRight: "auto",
                    width: "40%",
                  }}
                >
                  <Typography>Daily Code</Typography>
                  <div>
                    <Switch
                      checked={dailyCode}
                      onChange={handleDailyCodeChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "0",
                    width: "40%",
                  }}
                >
                  <Typography>QR</Typography>
                  <div>
                    <Switch
                      checked={qr}
                      onChange={handleQrChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
              </div>

              <div className="add-and-edit-employee-switch-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0",
                    marginRight: "auto",
                    width: "40%",
                  }}
                >
                  <Typography>NFC</Typography>
                  <div>
                    <Switch
                      checked={rfid}
                      onChange={handleRfidChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "0",
                    width: "40%",
                  }}
                >
                  <Typography>Biometrics</Typography>
                  <div>
                    <Switch
                      checked={biometrics}
                      onChange={handleBiometricsChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
              </div>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleMethodsSave}
              >
                <Typography>Save</Typography>
              </Button>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <ToggleButtonGroup
                orientation="horizontal"
                value={scannerView}
                exclusive
                onChange={handleScannerToggleChange}
              >
                <ToggleButton value="config-scanner" aria-label="add">
                  Scanner Account
                </ToggleButton>
                <ToggleButton value="config-geo" aria-label="delete">
                  Modify GeoCoding
                </ToggleButton>
                <ToggleButton value="scanner-devices" aria-label="delete">
                  Scanner Devices
                </ToggleButton>
              </ToggleButtonGroup>
              {scannerView === "config-scanner" && (
                <div className="select-employee-container">
                  <Typography sx={{ mt: 2 }}>
                    The client application is a background app that can run on
                    either Windows or Mac. With this application, you can
                    actively listen for QR scans, fingerprint touches, phone
                    taps, and register fingerprints.
                    <br></br>
                    <br></br>
                    Setting a scanner is required for logging into the client
                    application, by using the below pin code (revealed by
                    clicking the eye icon) and the indicated email address. You
                    can change your pin by entering the same email address, and
                    clicking the Save button.
                  </Typography>
                  <Button
                    variant="outlined"
                    disabled
                    sx={{
                      border: "1px solid black",
                      mt: 1,
                      color: "black",
                      "&.Mui-disabled": {
                        borderColor: "black",
                        color: "white",
                        backgroundColor: "#3E606D",
                      },
                    }}
                  >
                    Current Scanner Account: {currentScanner}
                  </Button>
                  <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      shrink={true}
                      sx={{
                        color: "black", // Ensures the label text is black
                        "&.Mui-disabled": {
                          color: "black", // Keeps the label black even when disabled
                        },
                      }}
                    >
                      Pin Code
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={pinCode}
                      onChange={handleChange} // This needs to be defined to handle input changes
                      notched={true}
                      disabled={true}
                      sx={{
                        color: "black", // Text color
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black", // Border color
                        },
                        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black", // Keeps border black even when disabled
                        },
                        "&.Mui-disabled": {
                          color: "black", // Keeps text black even when disabled
                          "-webkit-text-fill-color": "black", // Overrides the default style for WebKit browsers
                          opacity: 1, // Ensures the text does not have transparency
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          color: "black", // Ensures the input text color is black in disabled state
                          "-webkit-text-fill-color": "black", // Additional fix for WebKit browsers
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Pin Code"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      multiline
                      fullWidth
                      label="Email"
                      variant="outlined"
                      value={scannerEmail}
                      onChange={handleScannerEmailChange}
                      error={scannerEmailError}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={handleScannerSubmit}
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 1,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                  >
                    <Typography>Save</Typography>
                  </Button>
                </div>
              )}
              {scannerView === "config-geo" && (
                <Box>
                  <Typography sx={{ mt: 2 }}>
                    Employees can add a pass to their smart phone (Apple or
                    Android) via the support panel - this will contain their QR
                    Code and NFC Identifier.
                    <br></br>
                    <br></br>
                    If you would like it so that their phones prompt opening
                    their pass when they are nearby your business, then enter
                    the latitude and longitude of your business location, as
                    well as the distance in meters that you would like this
                    prompt to activate.
                    <br></br>
                    <br></br>
                    This can easily be identified{" "}
                    <strong>
                      <a
                        href="https://www.latlong.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "underline", color: "black" }}
                      >
                        here
                      </a>
                    </strong>{" "}
                    by entering your business address as the place name, then
                    clicking Find. To be more precise, click your building on
                    the map for exact coordinates.
                  </Typography>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      label="Latitude"
                      value={latitude}
                      onChange={handleLatitudeChange}
                      type="number"
                      onKeyDown={blockInvalidChar2} // Prevent 'e', 'E', '+', '-'
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      label="Longitude"
                      value={longitude}
                      onChange={handleLongitudeChange}
                      type="number"
                      onKeyDown={blockInvalidChar2} // Prevent 'e', 'E', '+', '-'
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      label="Distance (Meters)"
                      value={distance}
                      onChange={handleDistanceChange}
                      type="number"
                      onKeyDown={blockInvalidChar2} // Prevent 'e', 'E', '+', '-'
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 2,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleGeoSave}
                  >
                    <Typography>Save</Typography>
                  </Button>
                </Box>
              )}
              {scannerView === "scanner-devices" && (
                <Box>
                  <Typography sx={{ mt: 2 }}>
                    <strong>PLEASE NOTE: </strong> The only Fingerprint and NFC
                    devices that are compatible with our Software are listed
                    here. The NFC Reader requires personalized configuration
                    before being sent out which is why you must purchase it
                    through us.{" "}
                    <u>
                      Fingerprint functionality is currently supported only on
                      Windows 10/11 64-bit operating systems.
                    </u>
                  </Typography>
                  <Box
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    <Tooltip title="Fingerprint Reader">
                      <Paper
                        onClick={handleFpClick}
                        sx={{
                          width: "auto",
                          maxWidth: "100px",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <img
                          style={{ width: "100px" }}
                          src={fp}
                          alt="Fingerprint Reader"
                        />
                        <Typography>$71.40</Typography>
                      </Paper>
                    </Tooltip>

                    <Tooltip title="NFC Reader">
                      <Paper
                        onClick={handleNfcClick}
                        sx={{
                          width: "auto",
                          maxWidth: "100px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <img
                          style={{ width: "100px" }}
                          src={nfc}
                          alt="Fingerprint Reader"
                        />
                        <Typography>$200</Typography>
                      </Paper>
                    </Tooltip>

                    <Tooltip title="QR Scanner">
                      <Paper
                        onClick={handleQrClick}
                        sx={{
                          width: "auto",
                          maxWidth: "100px",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <img
                          style={{ width: "100px" }}
                          src={qrImg}
                          alt="Fingerprint Reader"
                        />
                        <Typography>$49.99</Typography>
                      </Paper>
                    </Tooltip>
                  </Box>

                  <Typography sx={{ mt: 2 }}>
                    Additionally, you will need to download our software to
                    listen for employee clock records.
                  </Typography>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 2,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={downloadChronoLeaf}
                  >
                    <Typography>Download</Typography>
                  </Button>
                </Box>
              )}
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <Typography>
                Overtime alerts will let you know when an employee goes over
                their legal over time per state (given a 7-day work period based
                on the selected start of the week). Tardy Alerts will let you
                know if an employee goes past the alert rate of their set daily
                start time (managed on the users tab) - for example, if the
                Alert Rate is 30, you will be notified if they clock in 30
                minutes late.
              </Typography>
              <div
                className="add-and-edit-employee-switch-container"
                style={{ marginTop: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0",
                    marginRight: "auto",
                    width: "40%",
                  }}
                >
                  <Typography>Tardy Alerts</Typography>
                  <div>
                    <Switch
                      checked={tardyAlert}
                      onChange={handleTardyChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "0",
                    width: "40%",
                  }}
                >
                  <Typography>Overtime Alerts</Typography>
                  <div>
                    <Switch
                      checked={overTimeAlert}
                      onChange={handleOverTimeChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ...switchStyles }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "40%",
                  marginTop: "10px",
                }}
              >
                <Typography>Owner Receives Alerts</Typography>
                <div>
                  <Switch
                    checked={ownerAlerts}
                    onChange={handleOwnerAlertsChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ ...switchStyles }}
                  />
                </div>
              </div>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  id="register-first-name-tf"
                  label="Alert Rate"
                  type="number"
                  onKeyDown={blockInvalidChar} // Prevent 'e', 'E', '+', '-'
                  value={alertRate}
                  onChange={handleAlertRateChange}
                  InputLabelProps={{
                    sx: {
                      color: "black !important",
                      "&.Mui-focused": { color: "#52796F !important" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#52796F !important",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  id="week-start-day-label"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "black",
                    },
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#52796F",
                    },
                    mt: 2,
                  }}
                >
                  Week Start
                </InputLabel>
                <Select
                  value={startOfWeek}
                  label="Week Start"
                  onChange={handleStartOfWeekChange}
                  displayEmpty
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#52796F",
                      },
                    mt: 2,
                  }}
                >
                  {daysOfWeek.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleAlertsSave}
              >
                <Typography>Save</Typography>
              </Button>
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              <Typography>
                We won't use your phone number for any reason other than
                contacting you in the event you need support. As for the State,
                we use that for overtime alerts.
              </Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  id="register-phone-tf"
                  label="Contact Number"
                  value={phone}
                  onChange={handlePhoneChange}
                  error={phoneError}
                  type="tel" // Suggests numeric input
                  InputLabelProps={{
                    sx: {
                      color: "black !important",
                      "&.Mui-focused": { color: "#52796F !important" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#52796F !important",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="state-select-label"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "black",
                    },
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#52796F",
                    },
                  }}
                >
                  State
                </InputLabel>
                <Select
                  labelId="state-select-label"
                  id="state-select"
                  value={selectedState}
                  label="State"
                  onChange={handleStateChange}
                  error={stateError}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#52796F",
                      },
                  }}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleContactSave}
              >
                <Typography>Save</Typography>
              </Button>
            </TabPanel>
            <TabPanel value={value} index={6} dir={theme.direction}>
              <ToggleButtonGroup
                orientation="horizontal"
                value={view}
                exclusive
                onChange={handleToggleChange}
              >
                <ToggleButton value="add" aria-label="add">
                  Add
                </ToggleButton>
                <ToggleButton value="delete" aria-label="delete">
                  Delete
                </ToggleButton>
                <ToggleButton value="edit" aria-label="edit">
                  Edit
                </ToggleButton>
              </ToggleButtonGroup>
              {view === "add" && (
                <Box>
                  <Typography sx={{ mt: 1 }}>
                    Easily add a new Department. After adding, you can start
                    assigning employees to this department via the users tab.
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      id="register-phone-tf"
                      label="New Department Name"
                      value={departmentName}
                      onChange={handleDepartmentNameChange}
                      error={departmentError}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 1,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleAddDepartment}
                  >
                    <Typography>Add</Typography>
                  </Button>
                </Box>
              )}
              {view === "delete" && (
                <Box>
                  <Typography sx={{ mt: 1 }}>
                    If you no longer need a department - feel free to delete it
                    here. If you have employees in this department currently,
                    they will be moved to the default ALL department.
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "black",
                        },
                        "&.MuiInputLabel-root.Mui-focused": {
                          color: "#52796F",
                        },
                      }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      value={selectedDepartment}
                      label="Department"
                      onChange={handleDepartmentChange}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "black",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#52796F",
                          },
                      }}
                    >
                      {allDepartmentList.map((dep) => (
                        <MenuItem
                          key={dep.departmentId}
                          value={dep.departmentId}
                        >
                          {dep.departmentName || "Unnamed Department"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 1,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleDeleteDepartment}
                  >
                    <Typography>Delete</Typography>
                  </Button>
                </Box>
              )}
              {view === "edit" && (
                <Box>
                  <Typography sx={{ mt: 1 }}>
                    Accidentally entered the wrong department name? No worries -
                    you can easily edit department names here.
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "black",
                        },
                        "&.MuiInputLabel-root.Mui-focused": {
                          color: "#52796F",
                        },
                      }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      value={selectedEditDepartment}
                      label="Department"
                      onChange={handleEditDepartmentChange}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "black",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#52796F",
                          },
                      }}
                    >
                      {allDepartmentList.map((dep) => (
                        <MenuItem
                          key={dep.departmentId}
                          value={dep.departmentId}
                        >
                          {dep.departmentName || "Unnamed Department"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      id="register-phone-tf"
                      label="New Department Name"
                      value={editDepartmentName}
                      onChange={handleEditDepartmentNameChange}
                      error={editDepartmentError}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 1,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleEditDepartment}
                  >
                    <Typography>Edit</Typography>
                  </Button>
                </Box>
              )}
            </TabPanel>
            <TabPanel value={value} index={7} dir={theme.direction}>
              <Typography>
                Securely manage your subscriptions and payment methods through
                the <strong>Stripe Customer Portal</strong>. Please be aware
                that cancelling either the base subscription or the additional
                user subscription will result in the immediate termination of
                both services.
              </Typography>

              <Button
                variant="contained"
                tabIndex={-1}
                disabled={true}
                onClick={handleStripeClick}
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 2,
                  width: "50%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
              >
                CUSTOMER PORTAL
              </Button>
            </TabPanel>

            <TabPanel value={value} index={8} dir={theme.direction}>
              <Typography>
                We default the business owners wage to 0 for reporting purposes. If you wish to record your time as well and see how much your wage should be, feel free to enter
                your hourly wage here.
              </Typography>
              <FormControl sx={{mt: 2}}>
              <TextField
                id="add-email"
                label="Hourly Wage"
                type="number"
                value={wage}
                onChange={handleWageChange}
                onKeyDown={blockInvalidChar3} // Prevent 'e', 'E', '+', '-'
                InputLabelProps={{
                  sx: {
                    color: "black !important",
                    "&.Mui-focused": { color: "#52796F !important" },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#52796F !important",
                  },
                  width: '100%',
                  mt: 2,
                }}
              />
              <Button
                variant="contained"
                tabIndex={-1}
                onClick={handleOwnerWageClick}
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 2,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
              >
                ADJUST MY WAGE
              </Button>
              </FormControl>
              
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>

      <div className="settings-alert-container">
        <Stack sx={{ minWidth: 320, maxWidth: 915, ml: "auto", mr: "auto" }}>
          {triggerSuccess && (
            <Alert
              severity="success"
              onClose={() => setTriggerSuccess(false)} // Close the success alert
            >
              {successMsg}
            </Alert>
          )}
          {triggerError && (
            <Alert
              severity="error"
              onClose={() => setTriggerError(false)} // Close the error alert
            >
              {errorMsg}
            </Alert>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default Settings;
