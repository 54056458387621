import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StackedPhones from "./finalStacked.png";
import Reporting from "./reportingWithFilters.png";
import Timesheets from "./timeEdits.png";
import Users from "./addUser.png";
import Alerts from "./alerts2.png";
import QR from "./qr.png";
import NFC from "./rfid.png";
import DAILY from "./dailycode.png";
import FINGER from "./biometrics.png";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import "./Product.css";

const About = ({}) => {
  return (
    <Box className="product">
      <Box sx={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Box sx={{ marginBottom: '40px' }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          About ChronoLeaf
        </Typography>
      </Box>
      
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" component="h2" gutterBottom>
          What is ChronoLeaf?
        </Typography>
        <Typography variant="body1" paragraph>
          ChronoLeaf is a cutting-edge timekeeping service tailored for small businesses, designed to simplify and streamline the process of tracking employee hours. Founded by Austin, ChronoLeaf was born from a unique blend of professional innovation and a personal passion for gardening. The name itself reflects this fusion, combining the precision of timekeeping with the natural growth and nurturing associated with gardening.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Why Choose ChronoLeaf?
        </Typography>
        <Typography variant="body1" paragraph>
          In a market saturated with timekeeping solutions, ChronoLeaf distinguishes itself through several key features:
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>Flexible Clock-In Methods: Employees can clock in and out with unparalleled convenience using their smartphones. Whether it's via Apple or Google Wallet passes, NFC, QR codes, biometrics, or daily codes, ChronoLeaf adapts to your employees' preferences.</li>
          <li>Competitive Pricing: Post-beta, ChronoLeaf aims to offer some of the most competitive pricing options available, making top-tier timekeeping accessible for businesses of all sizes.</li>
          <li>User-Friendly Interface: Managing and viewing time entries has never been easier. Our intuitive platform eliminates the frustration often associated with timekeeping, ensuring a smooth experience for both employees and managers.</li>
          <li>Comprehensive Reporting: Stay informed with our robust reporting tools. Easily track employee hours and calculate what they're owed, providing transparency and accuracy in payroll management.</li>
          <li>Real-Time Alerts: Stay on top of your workforce with real-time notifications. Whether it's a late arrival or overtime work, you'll be promptly informed to make timely decisions.</li>
          <li>Manager Access: Empower your management team with the ability to monitor and edit employee time. Delegate responsibilities efficiently and maintain control over your operations.</li>
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" component="h2" gutterBottom>
          Our Story
        </Typography>
        <Typography variant="body1" paragraph>
          ChronoLeaf's journey began alongside founder Austin's newfound interest in gardening. As Austin cultivated his garden, the idea of nurturing a business solution that could grow and adapt like a living plant took root. This inspiration led to the creation of ChronoLeaf, a timekeeping service that is as flexible and reliable as nature itself.
        </Typography>
        <Typography variant="body1" paragraph>
          Just as a garden requires careful attention and management, so does a business. ChronoLeaf embodies this philosophy, offering a service that grows with your business needs while maintaining simplicity and ease of use.
        </Typography>
        <Typography variant="body1" paragraph>
          Join us on this journey and experience how ChronoLeaf can transform your timekeeping process, bringing a touch of natural efficiency to your business operations.
        </Typography>
      </Box>
    </Box>
    </Box>
  );
};


export default About;
