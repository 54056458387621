import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from '@mui/material';
import Slide from "@mui/material/Slide";
import StackedPhones from "./finalStacked.png";
import Reporting from "./reportingWithFilters.png";
import Timesheets from "./timeEdits.png";
import Users from "./addUser.png";
import Alerts from "./alerts2.png";
import QR from "./qr.png";
import NFC from "./rfid.png";
import DAILY from "./dailycode.png";
import FINGER from "./biometrics.png";
import Tooltip from "@mui/material/Tooltip";
import Login from './login.png';
import Menu from "./menu.png";
import Register from './register.png';
import Clocked from './clockOut.png';
import Leafs from './leafs.png'
import "./Product.css";

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
};

const SlideComponent = ({ imageSrc, alt, direction }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [isIntersecting, hasIntersected]);

  return (
    <div ref={ref}>
      <Slide direction={direction} in={hasIntersected} mountOnEnter unmountOnExit timeout={1000}>
        <Box
          component="img"
          src={imageSrc}
          sx={{
            width: '100%',
            height: 'auto',
            boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
            borderRadius: 1,
          }}
          alt={alt}
        />
      </Slide>
    </div>
  );
};

const Pricing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 775px)");

  return (
    <Box className="product" style={{ paddingBottom: '50px' }}>
      <Box className="top-section" sx={{ width: "75%", ml: "auto", mr: "auto", display: 'flex', flexDirection: 'column', mt: 10, paddingBottom: '50px' }}>
        <Typography align="center" variant="h4" sx={{ ml: 'auto', mr: 'auto' }}>
          <span>Experience ChronoLeaf</span>{" "}
          <span className="customHeaderFont" style={{ color: "#2CCB4D", fontWeight: "bolder" }}>
            Free of Charge
          </span>
        </Typography>
        <Typography align="center" variant="h5" sx={{ paddingBottom: "5px", mt: 4 }}>
          Accurate timekeeping is crucial for precise payroll management. At ChronoLeaf, we are dedicated to delivering a dependable and high-quality solution. We are currently offering our services for free as we refine our product with a select group of businesses.
        </Typography>
        <Typography align="center" variant="h6" sx={{ paddingBottom: "5px", mt: 4, ml: 'auto', mr: 'auto', maxWidth: '75%' }}>
          Upon registration, you will gain immediate access to all ChronoLeaf features. This includes our client-sided software, showcased below, which empowers you to efficiently manage Employee clock-ins and clock-outs using NFC, QR codes, or fingerprint scanning technology.
        </Typography>
      </Box>

      <Box
          component="img"
          src={Leafs}
          alt="Leafs"
          sx={{
            maxWidth: '100%',
            height: 'auto',
            marginBottom: '20px'
          }}
        />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2, // Adjusts the margin between rows
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5, // Adjusts the margin between images
          }}
        >
          <SlideComponent imageSrc={Login} alt="Login" direction="left" />
          <SlideComponent imageSrc={Menu} alt="Menu" direction="right" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5, // Adjusts the margin between images
            mt: 8
          }}
        >
          <SlideComponent imageSrc={Register} alt="Register" direction="left" />
          <SlideComponent imageSrc={Clocked} alt="Clocked" direction="right" />
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;
