import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is used or remove it if unnecessary
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import LeafConfetti from '../ClockRecord/LeafConfetti'
import env from '../environmentVariables';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const Scanner = ({ canDelete, googleUser, user, onUserCrudResponse}) => {
    const { baseURL } = env;
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [triggerAlert, setTriggerAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Alert Message");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [noEmployees, setNoEmployees] = useState(false);
    const [noAdd, setNoAdd] = useState(false);
    const [scannedCode, setScannedCode] = useState('')
    const [showLeafConfetti, setShowLeafConfetti] = useState(false);
    const [lastRecorded, setLastRecorded] = useState('');
    const [displayAlt, setDisplayAlt] = useState(false);

    useEffect(() => {


    }, [user]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const getTodayDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 because months start from 0
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleScan = (event) => {
        setScannedCode(event.target.value);
        if (scannedCode.length === 35) {
            let today = getTodayDate();

            let WeekRecordsDTO = {
                operatorEmail: user.email,
                operatorToken: googleUser.access_token,
                operatorUserType: user.userType,
                userId: user.userId,
                relatedUserId: 0,
                businessId: user.business.businessId,
                startDate: today,
                endDate: today,
                weekRecords: null,
                lastRecord: null,
                clockInMethod: "qr",
                action: "IN",
                dailyCode: event.target.value
            };

            axios.post(`${baseURL}/recordNewRecord`, WeekRecordsDTO)
                .then(response => {
                    let message = response.data.lastScannedName + " has Clocked " + response.data.action + ".";
                    setLastRecorded(message);
                    setDisplayAlt(true);
                    setShowLeafConfetti(true);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        setAlertMsg("Unauthorized - please check with the business owner to see if QR Clocking is enabled.")
                    } else if (error.response && error.response.status === 429) {
                        setAlertMsg("It appears you are making too many requests. Please wait a moment then try again.")
                    } else {
                        setAlertMsg("An error occured while recording the clocking.")
                    }
                    setAlertSeverity("error")
                    setTriggerAlert(true)
                });

            setShowLeafConfetti(false);
            setScannedCode('');
        }
    };

    const dynamicSuccessOrErrorStyle = (triggerAlert) ? { borderBottom: '1px solid black' } : {};

    
    return (
        <>
        {showLeafConfetti && <LeafConfetti />}
        <div className="settings-container">
            <div className='settings-content-container' style={dynamicSuccessOrErrorStyle}>
                <Box sx={{maxWidth: 400}}>
                    <AppBar position="static" sx={{ backgroundColor: '#52796F'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                                '.MuiTabs-indicator': {
                                    backgroundColor: '#343F4B',
                                }
                            }}
                        >
                            <Tab label="Scanner" {...a11yProps(0)} disabled={true} sx={{color: 'white', opacity: 1, '&.Mui-disabled':{opacity: 1, color: 'white'}}}/>
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    disabled
                                    sx={{
                                        border: '1px solid black',
                                        color: 'black',
                                        marginBottom: '10px',
                                        '&.Mui-disabled': {
                                            borderColor: 'black',
                                            color: 'black',
                                        }
                                    }}
                                >
                                    {!displayAlt ? (
                                        <Typography>
                                            Your scanner works like a keyboard, focus your cursor in the input area and then scan away!
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {lastRecorded}
                                        </Typography>
                                    )}
                                </Button>
                                <TextField
                                    label="Scan Code"
                                    autoFocus
                                    displayEmpty
                                    value={scannedCode}
                                    onChange={handleScan}
                                    InputLabelProps={{
                                        sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
                                    }}
                                    sx={{
                                        mt: 1,
                                        "& .MuiOutlinedInput-notchedOutline": {  
                                            borderColor: "black !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                                            borderColor: "#52796F !important",
                                        }
                                    }}
                                    >

                                </TextField>
                            </FormControl>
                        </TabPanel>

                    </SwipeableViews>
                </Box>
            </div>
            <div className='settings-alert-container'>
                <Stack sx={{ width: '100%', maxWidth: 400 }}>
                    {triggerAlert && (
                        <Alert 
                            severity={alertSeverity}
                            onClose={() => setTriggerAlert(false)}
                        >
                            {alertMsg}
                        </Alert>
                    )}
                </Stack>
            </div>
        </div>
        </>
    );
};

export default Scanner;
