import logo from './logo.svg';
import './App.css';
import Landing from './Components/Header/Landing.js';

function App() {
  return (
    <div className="App">
      <Landing message="gang"/>
    </div>
  );
}

export default App;