// // environmentVariables.js

// const environment = process.env.NODE_ENV || 'development';

// const config = {
//   development: {
//     baseURL: 'http://localhost:8080/chronoleaf',
//     stripePay: 'https://buy.stripe.com/test_dR601tawi8lj7xC002?prefilled_email=',
//   },
//   production: {
//     baseURL: 'https://chronoleaf.com/chronoleaf',
//     stripePay: 'https://buy.stripe.com/test_dR601tawi8lj7xC002?prefilled_email=',
//   }
// };

// // Export the base URL based on the environment
// export const baseURL = (config[environment] ? config[environment].baseURL : config.development.baseURL);
// environmentVariables.js

// Determine the environment based on the window location
// TODO: CHANGE RIGHT SIDE TO production
// production: {
//   baseURL: 'https://chronoleaf.com/chronoleaf',
//   stripePay: 'https://buy.stripe.com/test_dR601tawi8lj7xC002?prefilled_email=',
//   stripePayNoTrial: 'https://buy.stripe.com/test_4gwg0rbAm8lj6ty7sv?prefilled_email=',
//   stripePortal: 'https://billing.stripe.com/p/login/test_cN2aHDaz147zanSbII?prefilled_email=',
//   stripeNfcPay: 'https://buy.stripe.com/test_14kcOf1ZM30ZbNS4gk?prefilled_email='
// }
const environment = window.location.hostname.includes('localhost') ? 'development' : 'production';

const config = {
  development: {
    baseURL: 'http://localhost:8080/chronoleaf',
    stripePay: 'https://buy.stripe.com/test_dR601tawi8lj7xC002?prefilled_email=',
    stripePayNoTrial: 'https://buy.stripe.com/test_4gwg0rbAm8lj6ty7sv?prefilled_email=',
    stripePortal: 'https://billing.stripe.com/p/login/test_cN2aHDaz147zanSbII?prefilled_email=',
    stripeNfcPay: 'https://buy.stripe.com/4gwcPM82j7x1b04cMM'
  },
  production: {
    baseURL: 'https://chronoleaf.ue.r.appspot.com/chronoleaf',
    stripePay: 'https://buy.stripe.com/test_dR601tawi8lj7xC002?prefilled_email=',
    stripePayNoTrial: 'https://buy.stripe.com/test_4gwg0rbAm8lj6ty7sv?prefilled_email=',
    stripePortal: 'https://billing.stripe.com/p/login/test_cN2aHDaz147zanSbII?prefilled_email=',
    stripeNfcPay: 'https://buy.stripe.com/4gwcPM82j7x1b04cMM'
  }
};

// Export configuration based on the determined environment
export default config[environment];
