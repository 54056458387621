import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is used or remove it if unnecessary
import "./Reporting.css";
import InfoIcon from '@mui/icons-material/Info';
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import LeafConfetti from "../ClockRecord/LeafConfetti";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import PrintIcon from "@mui/icons-material/Print";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import env from '../environmentVariables';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const operators = [">", "<", ">=", "<=", "="];

const Reporting = ({ googleUser, user, onUserCrudResponse }) => {
  const { baseURL } = env;
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [triggerAlert, setTriggerAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Alert Message");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [showLeafConfetti, setShowLeafConfetti] = useState(false);
  const [startDate, setStartDate] = useState(
    formatDate(new Date(Date.now() - 12096e5))
  );
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [data, setData] = useState(null);
  const [dataCopy, setDataCopy] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [showDecimal, setShowDecimal] = useState(false);
  const [filters, setFilters] = useState({
    name: "",
    tardys: { operator: ">", value: "" },
    overtime: { operator: ">", value: "" },
    totalHours: { operator: ">", value: "" },
    wage: { operator: ">", value: "" },
  });
  const [renderInfo, setRenderInfo] = useState(false);

  useEffect(() => {
    handleSetDateRange(2); // Simulate button 2 click on page load
  }, []);

  const checkDateRange = () => {
    for (let weeksAgo = 1; weeksAgo <= 4; weeksAgo++) {
      const end = new Date(endDate);
      const start = new Date(end);
      start.setDate(end.getDate() - (weeksAgo * 7 - 1));
      
      const formattedStartDate = formatDate(start);
      const formattedEndDate = formatDate(end);

      if (startDate === formattedStartDate && endDate === formattedEndDate) {
        return true;
      }
    }
    return false;
  };

  const handleReportRequest = () => {
    setStartError(startDate === null || startDate === "");
    setEndError(endDate === null || endDate === "");
    let noRangeError = checkDateRange();
    setStartError(!noRangeError)

    if (
      !(startDate === null || startDate === "") &&
      !(endDate === null || endDate === "") && noRangeError
    ) {
      setIsLoading(true);

      let SettingsDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        businessId: user.business.businessId,
        category: startDate,
        action: endDate,
      };

      axios
        .post(`${baseURL}/process-records`, SettingsDTO)
        .then((response) => {
          setData(response.data);
          setDataCopy(response.data);
          setFilteredData(response.data);
          setIsLoading(false);
          setShowLeafConfetti(true);
          setTriggerAlert(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setAlertMsg(
              "It appears you are making too many requests. Please wait a moment then try again."
            );
          } else {
            setAlertMsg(
              "It seems an error has occured. Please try again later and contact support if this continues."
            );            
          }
          setIsLoading(false);
          setAlertSeverity("error");
          setTriggerAlert(true);
        });
    } else {
      setAlertSeverity("error");
      setAlertMsg("Please make sure you enter valid dates. They must be in periods of weeks up to 4 weeks.");
      setTriggerAlert(true);
    }

    setShowLeafConfetti(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const sumFormattedTime = (timeArray) => {
    let totalMinutes = 0;
    timeArray.forEach((time) => {
      const [hours, minutes] = time.split(" Hours and ").map(Number);
      totalMinutes += hours * 60 + (minutes || 0);
    });
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} Hours and ${minutes} Minutes`;
  };

  const sumWeekTotalOverTimeWorked = (overtimeArray) => {
    let totalMinutes = 0;
    overtimeArray.forEach((overtime) => {
      const [hours, minutes] = overtime.first.split(" Hours and ").map(Number);
      totalMinutes += hours * 60 + (minutes || 0);
    });
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} Hours and ${minutes} Minutes`;
  };

  const sumWeekTotalWage = (wageArray) => {
    return wageArray.reduce((total, wage) => total + wage, 0).toFixed(2);
  };

  const handleToggle = () => {
    setShowDecimal((prevShowDecimal) => !prevShowDecimal);
  };

  const handleFilterChange = (field, value) => {
    const updatedFilters = {
      ...filters,
      [field]: value,
    };
    setFilters(updatedFilters);
    applyFilters(updatedFilters);
  };

  const clearFilters = () => {
    const clearedFilters = {
      name: "",
      tardys: { operator: ">", value: "" },
      overtime: { operator: ">", value: "" },
      totalHours: { operator: ">", value: "" },
      wage: { operator: ">", value: "" },
    };
    setFilters(clearedFilters);
    applyFilters(clearedFilters);
  };

  const applyFilters = (filters) => {
    const { name, tardys, overtime, totalHours, wage } = filters;

    const compare = (a, operator, b) => {
      switch (operator) {
        case ">":
          return a > b;
        case "<":
          return a < b;
        case ">=":
          return a >= b;
        case "<=":
          return a <= b;
        case "=":
          return a === b;
        default:
          return true;
      }
    };

    const filtered = data.first.filter((employee) => {
      if (name && !employee.name.toLowerCase().includes(name.toLowerCase()))
        return false;
      if (
        tardys.value &&
        !compare(employee.tardys, tardys.operator, Number(tardys.value))
      )
        return false;
      if (
        overtime.value &&
        !compare(
          employee.totalDecimalRangeOvertime,
          overtime.operator,
          Number(overtime.value)
        )
      )
        return false;
      if (
        totalHours.value &&
        !compare(
          employee.totalDecimalRangeHours,
          totalHours.operator,
          Number(totalHours.value)
        )
      )
        return false;
      if (
        wage.value &&
        !compare(
          sumWeekTotalWage(employee.weekTotalWage),
          wage.operator,
          Number(wage.value)
        )
      )
        return false;
      return true;
    });

    setFilteredData({ ...data, first: filtered });
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.first.map((employee) => {
        const record = { Name: employee.name };
        filteredData.second.forEach((date, index) => {
          record[date] = showDecimal
            ? employee.dayMinutes[index] !== undefined
              ? employee.dayMinutes[index]
              : "N/A"
            : employee.formattedWeekDayTotals[Math.floor(index / 7)][
                index % 7
              ] || "N/A";
        });
        record["Total Hours Worked"] = showDecimal
          ? employee.totalDecimalRangeHours || "N/A"
          : employee.totalRangeHours || "N/A";
        record["Overtime"] = showDecimal
          ? employee.totalDecimalRangeOvertime || "N/A"
          : employee.totalRangeOvertime || "N/A";
        record["Wage"] = sumWeekTotalWage(employee.weekTotalWage);
        record["Tardys"] = employee.tardys !== null ? employee.tardys : "N/A";
        return record;
      })
    );

    const range = XLSX.utils.decode_range(ws["!ref"]);

    // Auto width for each cell
    const wscols = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let maxLength = 10;
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const address = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = ws[address];
        if (!cell || !cell.v) continue;
        maxLength = Math.max(maxLength, cell.v.toString().length);
      }
      wscols.push({ wch: maxLength });
    }
    ws["!cols"] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    const date = new Date().toISOString().split("T")[0];
    const filename = `ChronoLeaf_${
      showDecimal ? "decimal_" : ""
    }report-${date}.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const printDocument = printWindow.document;

    printDocument.write("<html><head><title>Print Report</title>");
    printDocument.write("<style>");
    printDocument.write(`
      table {
        border-collapse: collapse;
        width: 100%;
      }
      th, td {
        border: 1px solid black;
        padding: 8px;
        text-align: left;
      }
      th {
        background-color: #f2f2f2;
      }
    `);
    printDocument.write("</style>");
    printDocument.write("</head><body>");
    printDocument.write("<table>");

    // Table Header
    printDocument.write("<thead><tr>");
    printDocument.write("<th>Date</th>");
    filteredData.first.forEach((employee) => {
      printDocument.write(`<th>${employee.name}</th>`);
    });
    printDocument.write("</tr></thead>");

    // Table Body
    printDocument.write("<tbody>");
    filteredData.second.forEach((date, i) => {
      printDocument.write("<tr>");
      printDocument.write(`<td>${date}</td>`);
      filteredData.first.forEach((employee) => {
        const value = showDecimal
          ? employee.dayMinutes[i] !== undefined
            ? employee.dayMinutes[i]
            : "N/A"
          : employee.formattedWeekDayTotals[Math.floor(i / 7)][i % 7] || "N/A";
        printDocument.write(`<td>${value}</td>`);
      });
      printDocument.write("</tr>");
    });

    // Total Rows
    const addTotalRow = (label, getValue) => {
      printDocument.write("<tr>");
      printDocument.write(`<td>${label}</td>`);
      filteredData.first.forEach((employee) => {
        printDocument.write(`<td>${getValue(employee)}</td>`);
      });
      printDocument.write("</tr>");
    };

    addTotalRow("Tardys", (employee) =>
      employee.tardys !== null ? employee.tardys : "N/A"
    );
    addTotalRow("Overtime", (employee) =>
      showDecimal
        ? employee.totalDecimalRangeOvertime || "N/A"
        : employee.totalRangeOvertime || "N/A"
    );
    addTotalRow("Total Hours Worked", (employee) =>
      showDecimal
        ? employee.totalDecimalRangeHours || "N/A"
        : employee.totalRangeHours || "N/A"
    );
    addTotalRow("Wage", (employee) => sumWeekTotalWage(employee.weekTotalWage));

    printDocument.write("</tbody></table>");
    printDocument.write("</body></html>");

    printDocument.close();
    printWindow.print();
  };

  const handleSetDateRange = (weeksAgo) => {
    setStartError(false)
    const end = new Date(endDate);
    const start = new Date(end);
    start.setDate(end.getDate() - (weeksAgo * 7 - 1));

    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
  };

  const handleInfoClick = () => {
    setRenderInfo(!renderInfo);
  };

  return (
    <>
      {showLeafConfetti && <LeafConfetti />}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            marginTop: "15%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress sx={{ color: "#52796F" }} />
        </Box>
      ) : (
        <div className="settings-container" style={{ marginBottom: "50px" }}>
          <div className="settings-content-container">
            <Box sx={{ maxWidth: "99vw", minWidth: 320 }}>
              <AppBar
                position="static"
                sx={{ backgroundColor: "#52796F", width: "100%" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "#343F4B",
                    },
                  }}
                >
                  <Box
            sx={{ml:'auto', mr:'auto'}}
          >
            <IconButton onClick={handleInfoClick} sx={{ ml: 1 }}>
              <InfoIcon/>
            </IconButton>
            <Tab
              label="Reporting"
              {...a11yProps(0)}
              disabled={true}
              sx={{
                color: 'WHITE',
                opacity: 1,
                '&.Mui-disabled': {
                  opacity: 1,
                  color: 'white',
                },
              }}
            />
          </Box>
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: filteredData ? "space-between" : "center",
                      alignItems: filteredData ? "none" : "center",
                      flexWrap: "wrap",
                      ml: "auto",
                      mr: "auto",
                      "& > *:not(:last-child)": {
                        mb: { xs: 2, md: 0 },
                      },
                    }}
                  >
                    <Paper
                      sx={{
                        minWidth: "275px",
                        width: "45%",
                        margin: { xs: "0 auto", md: "0" },
                        maxWidth: "450px",
                        padding: 2,
                        mr: "auto",
                        mt: 2,
                      }}
                    >
                      <FormControl
                        sx={{
                          minWidth: "275px",
                          width: "95%",
                          ml: "auto",
                          mr: "auto",
                          minHeight: "400px",
                          height: "100%",
                        }}
                      >
                        <Typography sx={{ paddingBottom: "10px" }}>
                          ChronoLeaf reports conveniently display all users and
                          their respective daily totals, total hours worked,
                          total overtime worked, their total pay, and the number
                          of times they have been late between a given start and
                          end date.
                        </Typography>
                        <FormControl sx={{mt: 2}}>
                        <TextField
                          label="Start Date"
                          type="date"
                          value={startDate}
                          error={startError}
                          onChange={(e) => setStartDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              color: "black !important",
                              "&.Mui-focused": { color: "#52796F !important" },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#52796F !important",
                            }
                          }}
                        />
                        </FormControl>
                        
                        <FormControl sx={{mt: 2}}>
                          <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            error={endError}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                color: "black !important",
                                "&.Mui-focused": { color: "#52796F !important" },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black !important",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#52796F !important",
                              }
                            }}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            ml: "auto",
                            mr: "auto",
                          }}
                        >
                          {[1, 2, 3, 4].map((weeks) => (
                            <Button
                              key={weeks}
                              variant="contained"
                              size="medium"
                              sx={{
                                backgroundColor: "#52796F",
                                "&:hover": { backgroundColor: "#435e57" },
                                minWidth: "55px !important",
                                mt: 2,
                                ml: weeks === 1 ? 0 : 1,
                              }}
                              onClick={() => handleSetDateRange(weeks)}
                            >
                              <Typography>{weeks}</Typography>
                            </Button>
                          ))}
                        </FormControl>
                        <div style={{ paddingBottom: "10px" }}></div>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            mt: "auto",
                            mb: 0,
                            width: "100%",
                            backgroundColor: "#52796F",
                            "&:hover": { backgroundColor: "#435e57" },
                          }}
                          onClick={handleReportRequest}
                        >
                          <Typography>Generate Report</Typography>
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            mt: 3,
                            mb: 0,
                            width: "100%",
                            backgroundColor: "#52796F",
                            "&:hover": { backgroundColor: "#435e57" },
                          }}
                          onClick={handleToggle}
                        >
                          <Typography>TOGGLE NUMERIC</Typography>
                        </Button>
                      </FormControl>
                    </Paper>
                    {filteredData &&
                    <Paper
                      sx={{
                        minWidth: "275px",
                        maxWidth: "450px",
                        width: "45%",
                        margin: { xs: "0 auto", md: "0" },
                        mt: 2,
                        mb: "auto",
                        padding: 2,
                        ml: "auto",
                        mr: "auto",
                      }}
                    >
                      <FormControl
                        sx={{
                          minWidth: "275px",
                          width: "95%",
                          ml: "auto",
                          mr: "auto",
                          paddingBottom: 2,
                          minHeight: "400px",
                          height: "100%",
                          mb: 0,
                          mt: "auto",
                        }}
                      >
                        <FormControl>
                        <TextField
                          label="Name"
                          value={filters.name}
                          onChange={(e) =>
                            handleFilterChange("name", e.target.value)
                          }
                          InputLabelProps={{
                            sx: {
                              color: "black !important",
                              "&.Mui-focused": { color: "#52796F !important" },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#52796F !important",
                            }
                          }}
                        />
                        </FormControl>
                        
                        <FormControl
                          sx={{ mt: 2, display: "flex", flexDirection: "row" }}
                        >
                          <InputLabel
                            sx={{
                              "&.MuiInputLabel-root": {
                                color: "black",
                              },
                              "&.MuiInputLabel-root.Mui-focused": {
                                color: "#52796F",
                              },
                            }}
                          >
                            Tardys
                          </InputLabel>
                          <Select
                            value={filters.tardys.operator}
                            onChange={(e) =>
                              handleFilterChange("tardys", {
                                ...filters.tardys,
                                operator: e.target.value,
                              })
                            }
                            label="Tardys"
                            sx={{
                              width: "10%",
                              minWidth: "75px",
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "black",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F",
                                },
                            }}
                          >
                            {operators.map((operator) => (
                              <MenuItem key={operator} value={operator}>
                                {operator}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            type="number"
                            value={filters.tardys.value}
                            onChange={(e) =>
                              handleFilterChange("tardys", {
                                ...filters.tardys,
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black !important",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F !important",
                                },
                              width: "88%",
                              ml: 2,
                            }}
                          />
                        </FormControl>
                        <FormControl
                          sx={{ mt: 2, display: "flex", flexDirection: "row" }}
                        >
                          <InputLabel
                            sx={{
                              "&.MuiInputLabel-root": {
                                color: "black",
                              },
                              "&.MuiInputLabel-root.Mui-focused": {
                                color: "#52796F",
                              },
                            }}
                          >
                            Overtime
                          </InputLabel>
                          <Select
                            value={filters.overtime.operator}
                            onChange={(e) =>
                              handleFilterChange("overtime", {
                                ...filters.overtime,
                                operator: e.target.value,
                              })
                            }
                            sx={{
                              width: "10%",
                              minWidth: "75px",
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "black",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F",
                                },
                            }}
                            label="Overtime"
                          >
                            {operators.map((operator) => (
                              <MenuItem key={operator} value={operator}>
                                {operator}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            type="number"
                            value={filters.overtime.value}
                            onChange={(e) =>
                              handleFilterChange("overtime", {
                                ...filters.overtime,
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black !important",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F !important",
                                },
                              width: "88%",
                              ml: 2,
                            }}
                          />
                        </FormControl>
                        <FormControl
                          sx={{ mt: 2, display: "flex", flexDirection: "row" }}
                        >
                          <InputLabel
                            sx={{
                              "&.MuiInputLabel-root": {
                                color: "black",
                              },
                              "&.MuiInputLabel-root.Mui-focused": {
                                color: "#52796F",
                              },
                            }}
                          >
                            All Hours
                          </InputLabel>
                          <Select
                            value={filters.totalHours.operator}
                            onChange={(e) =>
                              handleFilterChange("totalHours", {
                                ...filters.totalHours,
                                operator: e.target.value,
                              })
                            }
                            label="All Hours"
                            sx={{
                              width: "10%",
                              minWidth: "75px",
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "black",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F",
                                },
                            }}
                          >
                            {operators.map((operator) => (
                              <MenuItem key={operator} value={operator}>
                                {operator}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            type="number"
                            value={filters.totalHours.value}
                            onChange={(e) =>
                              handleFilterChange("totalHours", {
                                ...filters.totalHours,
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black !important",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F !important",
                                },
                              width: "88%",
                              ml: 2,
                            }}
                          />
                        </FormControl>
                        <FormControl
                          sx={{ mt: 2, display: "flex", flexDirection: "row" }}
                        >
                          <InputLabel
                            sx={{
                              "&.MuiInputLabel-root": {
                                color: "black",
                              },
                              "&.MuiInputLabel-root.Mui-focused": {
                                color: "#52796F",
                              },
                            }}
                          >
                            All Wage
                          </InputLabel>
                          <Select
                            value={filters.wage.operator}
                            onChange={(e) =>
                              handleFilterChange("wage", {
                                ...filters.wage,
                                operator: e.target.value,
                              })
                            }
                            label="All Wage"
                            sx={{
                              width: "10%",
                              minWidth: "75px",
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "black",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F",
                                },
                            }}
                          >
                            {operators.map((operator) => (
                              <MenuItem key={operator} value={operator}>
                                {operator}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            type="number"
                            value={filters.wage.value}
                            onChange={(e) =>
                              handleFilterChange("wage", {
                                ...filters.wage,
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "black !important",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#52796F !important",
                                },
                              width: "88%",
                              ml: 2,
                            }}
                          />
                        </FormControl>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            mb: 0,
                            mt: "auto",
                            width: "100%",
                            backgroundColor: "#52796F",
                            "&:hover": { backgroundColor: "#435e57" },
                          }}
                          onClick={clearFilters}
                        >
                          <Typography>Clear Filters</Typography>
                        </Button>
                      </FormControl>
        
                    </Paper>
                        }
                  </Box>
                  {filteredData && (
                    <Box sx={{ width: "100%" }}>
                      {filteredData && (
                        <TableContainer
                          sx={{ marginTop: 4, maxHeight: "600px" }}
                          component={Paper}
                        >
                          <Table
                            sx={{
                              tableLayout: "auto",
                              whiteSpace: "nowrap",
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            <TableHead>
                              <TableRow
                                sx={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 2,
                                  backgroundColor: "background.paper",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <Box sx={{ width: "100%", display: "flex" }}>
                                    <IconButton onClick={handleDownload}>
                                      <FileDownloadIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={handlePrint}
                                      sx={{ mr: 0, ml: "auto" }}
                                    >
                                      <PrintIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                {filteredData.first.map((employee, i) => (
                                  <TableCell
                                    key={i}
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {employee.name}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData.second.map((date, i) => (
                                <TableRow key={i}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {date}
                                  </TableCell>
                                  {filteredData.first.map((employee) => (
                                    <TableCell
                                      key={employee.name}
                                      sx={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                      }}
                                    >
                                      {showDecimal
                                        ? employee.dayMinutes[i] !== undefined
                                          ? employee.dayMinutes[i]
                                          : "N/A"
                                        : employee.formattedWeekDayTotals[
                                            Math.floor(i / 7)
                                          ][i % 7] || "N/A"}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                              <TableRow
                                sx={{
                                  position: "sticky",
                                  bottom: 105,
                                  zIndex: 1,
                                  backgroundColor: "background.paper",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tardys
                                </TableCell>
                                {filteredData.first.map((employee) => (
                                  <TableCell
                                    key={employee.name}
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                    }}
                                  >
                                    {employee.tardys !== null
                                      ? employee.tardys
                                      : "N/A"}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow
                                sx={{
                                  position: "sticky",
                                  bottom: 70,
                                  zIndex: 1,
                                  backgroundColor: "background.paper",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Overtime
                                </TableCell>
                                {filteredData.first.map((employee) => (
                                  <TableCell
                                    key={employee.name}
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                    }}
                                  >
                                    {showDecimal
                                      ? employee.totalDecimalRangeOvertime ||
                                        "N/A"
                                      : employee.totalRangeOvertime || "N/A"}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow
                                sx={{
                                  position: "sticky",
                                  bottom: 35,
                                  zIndex: 1,
                                  backgroundColor: "background.paper",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total Hours Worked
                                </TableCell>
                                {filteredData.first.map((employee) => (
                                  <TableCell
                                    key={employee.name}
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                    }}
                                  >
                                    {showDecimal
                                      ? employee.totalDecimalRangeHours || "N/A"
                                      : employee.totalRangeHours || "N/A"}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow
                                sx={{
                                  position: "sticky",
                                  bottom: 0,
                                  zIndex: 1,
                                  backgroundColor: "background.paper",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Wage
                                </TableCell>
                                {filteredData.first.map((employee) => (
                                  <TableCell
                                    key={employee.name}
                                    sx={{
                                      border:
                                        "1px solid rgba(224, 224, 224, 1)",
                                    }}
                                  >
                                    {sumWeekTotalWage(employee.weekTotalWage)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Box>
                  )}
                </TabPanel>
              </SwipeableViews>
            </Box>

            <BootstrapDialog
        onClose={handleInfoClick}
        aria-labelledby="customized-dialog-title"
        open={renderInfo}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          ChronoLeaf Reporting
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleInfoClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom sx={{mt: 2}}>
            Standard ChronoLeaf reports are formatted in periods of 7 days - as such, you must set your start date and end date accordingly for up to 4 weeks.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            To make this easy for you, we've conveniently added the 1, 2, 3, 4 buttons to automatically set the start date to be (#) number of weeks from the set End Date.
            Additionally, on page load it is set to generate a report from 2 weeks from the current day.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            ChronoLeaf provides the ability to view time worked as written (7 hours and 25 minutes) or by numeric (7.42). Toggle between the two by clicking 'TOGGLE NUMERIC'
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            ChronoLeaf filters will appear once a report has been generated. Use this to conveniently display employees that match the filter criteria you set. For example, if you want
            to view all employees who have 10 or more tardys, you would simply select the tardys drop down and choose the greater than or equal symbol and enter 10 in the box to the right.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            ChronoLeaf also provides the ability to download and print out generated reports. To do this, simply click the download or print icon in the report table.
          </Typography>
          <Typography gutterBottom sx={{mt: 2}}>
            Overtime is calculated by each week as set in the start and end date range. For example, if you entered 5/6/2024 (Monday) for the start date and 5/19/2024 (Sunday) for the end date -
            then overtime would be calculated for each week by Monday-Sunday. We do take relevant state overtime laws into account when generating these numbers according to the state listed in your settings. Wage is the reccomended amount of total wage in the given
            date range based on the employees wage, amount of overtime, and total time worked.
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button
                autoFocus
                variant="contained"
                size="medium"
                sx={{
                  ml: 'auto',
                  mr: 'auto',
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleInfoClick}
              >
                <Typography>Close</Typography>
              </Button>
        </DialogActions>
      </BootstrapDialog>
          </div>

          <div className="settings-alert-container">
            <Stack sx={{ width: "100%" }}>
              {triggerAlert && (
                <Alert
                  severity={alertSeverity}
                  onClose={() => setTriggerAlert(false)}
                >
                  {alertMsg}
                </Alert>
              )}
            </Stack>
          </div>
        </div>

        
      )}
    </>
  );
};

export default Reporting;
