import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is used or remove it if unnecessary
import './ClockRecord.css';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import LeafConfetti from './LeafConfetti'
import env from '../environmentVariables';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }


const ClockRecord = ({ googleUser, user, onUserCrudResponse }) => {
    const { baseURL } = env;
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [triggerAlert, setTriggerAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Alert Message");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [noEmployees, setNoEmployees] = useState();
    const [enteredCode, setEnteredCode] = useState('');
    const [dailyCodeError, setDailyCodeError] = useState(false);
    const [todaysRecords, setTodaysRecords] = useState([]);
    const [lastRecord, setLastRecord] = useState({businessId: 0, clockRecordId: 0, inOrOut: "IN", timeRecorded: "1996-01-04T00:00:00.000-00:00", userId: 0});
    const [lastTime, setLastTime] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [lastInOrOut, setLastInOrOut] = useState("");
    const [timeWorked, setTimeWorked] = useState("");
    const [action, setAction] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [displayAlt, setDisplayAlt] = useState(false);
    const [showLeafConfetti, setShowLeafConfetti] = useState(false);

    useEffect(() => {
        setIsLoading(true)

        let today = getTodayDate();

        let WeekRecordsDTO = {
            operatorEmail: user.email,
            operatorToken: googleUser.access_token,
            operatorUserType: user.userType,
            userId: user.userId,
            relatedUserId: user.userId,
            businessId: user.business.businessId,
            startDate: today,
            endDate: today,
            lastClockRecord: null
        };

        axios.post(`${baseURL}/dayClockRecords`, WeekRecordsDTO)
            .then(response => {
                
                if (response.data.weekRecords !== null && response.data.lastRecord !== null) {
                    setDisplayAlt(false)
                    setTodaysRecords(response.data.weekRecords);
                    setLastRecord(response.data.lastRecord);
                    setLastTime(formatTime(response.data.lastRecord.timeRecorded))
                    setLastDate(formatMonthAndDate(response.data.lastRecord.timeRecorded))
                    setLastInOrOut(response.data.lastRecord.inOrOut)
                    if (response.data.lastRecord.inOrOut === "IN") {
                        setAction("OUT")
                    } else {
                        setAction("IN")
                    }
                    let totalTime = calculateTotalTime(response.data.weekRecords)
                    setTimeWorked(totalTime)
                    setIsLoading(false)
                } else {
                    setDisplayAlt(true)
                    setTodaysRecords(response.data.weekRecords);
                    setLastRecord(response.data.lastRecord);
                    setLastTime("")
                    setLastDate("")
                    setLastInOrOut("")
                    setTimeWorked("N/A")
                    setAction("IN")
                    setIsLoading(false)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 429) {
                    setIsLoading(false)
                    setAlertSeverity("error")
                    setAlertMsg("It appears you are making too many requests. Please wait a moment then try again.")
                    setTriggerAlert(true)
                } else {
                    setIsLoading(false)
                    setAlertSeverity("error")
                    setAlertMsg("An error occured while populating your daily clock records.")
                    setTriggerAlert(true)                   
                }
            }); 
    }, []);

    const getTodayDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 because months start from 0
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formatTime = (dateStr) => {
        const date = new Date(dateStr);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
        return timeString;
    }
    
    const formatMonthAndDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { month: 'long', day: 'numeric' };
        const dateString = new Intl.DateTimeFormat('en-US', options).format(date);

        const day = date.getDate();
        const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || ["th", "st", "nd", "rd"][day] || "th";
        return `${dateString.split(' ')[0]} ${day}${suffix}`;
    }

    function calculateTotalTime(clockRecords) {
        let totalTime = 0; // Total time in milliseconds

        clockRecords.sort((a, b) => new Date(b.timeRecorded) - new Date(a.timeRecorded));
    
        // Use a stack to find matching OUT-IN pairs, considering the list is sorted newest to oldest
        const stack = [];
    
        // We iterate from the start (newest) to the end (oldest)
        for (const record of clockRecords) {
            if (record.inOrOut === "OUT") {
                stack.push(record); // Push OUT record onto stack
            } else if (record.inOrOut === "IN" && stack.length > 0) {
                const outRecord = stack.pop(); // Pop the last OUT record
                const duration = new Date(outRecord.timeRecorded) - new Date(record.timeRecorded);
                totalTime += duration; // Add the duration to total time
            }
        }

        return formatDuration(totalTime);
    }
    
    function formatDuration(milliseconds) {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
    
        const minutesLeft = minutes % 60;
    
        if (hours > 0 && minutesLeft > 0) {
            return `${hours} HOURS AND ${minutesLeft} MINUTES`;
        } else if (hours > 0) {
            return `${hours} HOURS`;
        } else {
            return `${minutesLeft} MINUTES`;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const handleDailyCodeChange = (event) => {
        setEnteredCode(event.target.value);
        setDailyCodeError(false)
    };

    const handleRecordClockRecord = () => {
        setIsLoading(true)

        let today = getTodayDate();

        let WeekRecordsDTO = {
            operatorEmail: user.email,
            operatorToken: googleUser.access_token,
            operatorUserType: user.userType,
            userId: user.userId,
            relatedUserId: user.userId,
            businessId: user.business.businessId,
            startDate: today,
            endDate: today,
            weekRecords: todaysRecords,
            lastRecord: lastRecord,
            clockInMethod: "dailyCode",
            action: action,
            dailyCode: enteredCode
        };

        axios.post(`${baseURL}/recordNewRecord`, WeekRecordsDTO)
            .then(response => {
                setTodaysRecords(response.data.weekRecords);
                setLastRecord(response.data.lastRecord);
                setLastTime(formatTime(response.data.lastRecord.timeRecorded))
                setLastDate(formatMonthAndDate(response.data.lastRecord.timeRecorded))
                setLastInOrOut(response.data.lastRecord.inOrOut)
                if (response.data.lastRecord.inOrOut === "IN") {
                    setAction("OUT")
                } else {
                    setAction("IN")
                }

                let totalTime = calculateTotalTime(response.data.weekRecords)
                setTimeWorked(totalTime)
                setIsLoading(false)
                setDisplayAlt(false)
                setShowLeafConfetti(true);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    setAlertMsg("Unauthorized - please check your daily code.")
                    setDailyCodeError(true)
                } else if (error.response && error.response.status === 429) {
                    setAlertMsg("It appears you are making too many requests. Please wait a moment then try again.")
                } else {
                    setAlertMsg("An error occured while populating your daily clock records.")
                }
                setIsLoading(false)
                setAlertSeverity("error")
                setTriggerAlert(true)
            });

            setShowLeafConfetti(false);
    }
    
    return (
        <>
        {showLeafConfetti && <LeafConfetti />}
        {isLoading ? (
            <Box sx={{ display: 'flex', marginTop: '15%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CircularProgress sx={{ color: '#52796F' }}/>
            </Box>
        ) : (
            <div className="settings-container">
                <div className='settings-content-container'>
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#52796F'}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                                sx={{
                                    '.MuiTabs-indicator': {
                                        backgroundColor: '#343F4B'
                                    }
                                }}
                            >
                                <Tab label="Clock Records" {...a11yProps(0)} disabled={true}
                                    sx={{
                                        color: 'WHITE',
                                        opacity: 1,
                                        '&.Mui-disabled': {
                                        opacity: 1,
                                        color: 'white',
                                    }}} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                    <FormControl fullWidth>
                                        <Button
                                            variant="outlined"
                                            disabled
                                            sx={{
                                            border: '1px solid black',
                                            color: 'black',
                                            '&.Mui-disabled': {
                                                borderColor: 'black',
                                                color: 'black',
                                            }
                                            }}
                                        >
                                            {displayAlt ? (
                                                <Typography>
                                                    No Clock Records found for today.
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    You last clocked <strong>{lastInOrOut}</strong> on <strong>{lastDate}</strong> at <strong>{lastTime}</strong>.
                                                </Typography>
                                            )}
                                        </Button>
                                        <FormControl sx={{mt: 2}}>
                                        <TextField
                                            id="daily-code-input"
                                            label="Daily Code"
                                            value={enteredCode}
                                            onChange={handleDailyCodeChange}
                                            error={dailyCodeError}
                                            disabled={!user.business.dailyCode && (user.business.qr || user.business.biometrics || user.business.rfid)}
                                            InputLabelProps={{
                                                sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": {  
                                                    borderColor: "black !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                                                    borderColor: "#52796F !important",
                                                }
                                            }}
                                        />
                                        </FormControl>
                                    </FormControl>
                                <div className='role-action-container'>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            disabled
                                            sx={{
                                            border: '1px solid black',
                                            color: 'black',
                                            '&.Mui-disabled': {
                                                borderColor: 'black',
                                                color: 'white',
                                                backgroundColor: '#3E606D'
                                            }
                                            }}
                                        >
                                            {timeWorked}
                                        </Button>
                                    </div>     
                                    <Button variant="contained" size="medium" sx={{ml: 'auto', mr: 0, backgroundColor: '#52796F', '&:hover': {backgroundColor: '#435e57'} }} onClick={handleRecordClockRecord} 
                                        disabled={!user.business.dailyCode && (user.business.qr || user.business.biometrics || user.business.rfid)}>
                                        <Typography>
                                            CLOCK {action}
                                        </Typography>
                                    </Button>
                                </div>
                            </TabPanel>
                        </SwipeableViews>
                    </Box>
                </div>

                <div className='settings-alert-container'>
                    <Stack sx={{ width: '100%' }}>
                        {triggerAlert && (
                            <Alert 
                                severity={alertSeverity}
                                onClose={() => setTriggerAlert(false)}
                            >
                                {alertMsg}
                            </Alert>
                        )}
                    </Stack>
                </div>
            </div>
        )}
        </>
    );
};

export default ClockRecord;