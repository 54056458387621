import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Landing.css";
import ChronoLogo from "./mainLogo.png";
import LoginIcon from "./LoginIcon.png";
import PriceIcon2 from "./PriceIcon.png";
import ProductIcon2 from "./ProductIcon.png";
import SettingsIcon2 from "./SettingsIcon.png";
import UsersIcon from "./UsersIcon.png";
import ReportIcon from "./ReportIcon.png";
import QrIcon from "./QrIcon.png";
import HomeIcon2 from "./HomeIcon.png";
import ClockIcon from "./ClockIcon.png";
import Register from "../Register/Register";
import Home from "../Home/Home";
import Tree from "../Tree/Tree";
import Reporting from "../Reporting/Reporting";
import Product from "../Product/Product";
import ClockRecord from "../ClockRecord/ClockRecord";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import DailyClockRecord from "../ClockRecord/ClockRecord";
import Users from "../Users/Users";
import Billing from "../Billing/Billing";
import Scanner from "../Scanner/Scanner";
import ProfileSupport from "../ProfileSupport/ProfileSupport";
import Settings from "../Settings/Settings";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper, useMediaQuery } from "@mui/material";
import grassImage from "../Tree/grass.png";
import { styled } from "@mui/material/styles";
import { FcGoogle } from "react-icons/fc";
import CloseIcon from "@mui/icons-material/Close";
import env from "../environmentVariables";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WalletIcon from '@mui/icons-material/Wallet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';

const Landing = (props) => {
  const { baseURL } = env;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [displayRegister, setDisplayRegister] = useState(false);
  const [displayEmployeeButtons, setDisplayEmployeeButtons] = useState(false);
  const [displayManagerButtons, setDisplayManagerButtons] = useState(false);
  const [displayOwnerButtons, setDisplayOwnerButtons] = useState(false);
  const [displayInitialButtons, setDisplayInitialButtons] = useState(true);
  const [createdUser, setCreatedUser] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [dailyId, setDailyId] = useState("");
  const [displayDailyId, setDisplayDailyId] = useState(false);
  const [homeButtonText, setHomeButtonText] = useState("Home");
  const [logoutButtonText, setLogoutButtonText] = useState("Logout");
  const [reportingButtonText, setReportingButtonText] = useState("Reporting");
  const [clockRecordText, setClockRecordText] = useState("Record Time");
  const [usersButtonText, setUsersButtonText] = useState("Users");
  const [settingsButtonText, setSettingsButtonText] = useState("Settings");
  const [renderClockRecord, setRenderClockRecord] = useState(false);
  const [renderHome, setRenderHome] = useState(false);
  const [renderUsers, setRenderUsers] = useState(false);
  const [renderSettings, setRenderSettings] = useState(false);
  const [displayUsers, setDisplayUsers] = useState(false);
  const [displayProfile, setDisplayProfile] = useState(false);
  const [supportText, setSupportText] = useState("Support");
  const [renderScanner, setRenderScanner] = useState(false);
  const [displayReporting, setDisplayReporting] = useState(false);
  const [renderTree, setRenderTree] = useState(false);
  const [displayLoginForm, setDisplayLoginForm] = useState(false);
  const [renderBilling, setRenderBilling] = useState(false);
  const [displayProduct, setDisplayProduct] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isProspect, setIsProspect] = useState(false);
  const isNotLong = useMediaQuery("(max-width: 1425px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [displayPricing, setDisplayPricing] = useState(false);
  const [displayAbout, setDisplayAbout] = useState(false);
  const [displayInitialHome, setDisplayInitialHome] = useState(true);
  

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedProfile = JSON.parse(localStorage.getItem("profile"));
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedUser && storedProfile && storedUserData && storedTimestamp) {
      const currentTime = new Date().getTime();
      const timestamp = parseInt(storedTimestamp, 10);

      // Check if stored data is older than 30 minutes (1800000 milliseconds)
      if (currentTime - timestamp < 1800000) {
        setDisplayProduct(false);
        setUser(storedUser);
        setProfile(storedProfile);
        setUserData(storedUserData);
        setIsLoggedIn(true);
        initializeUserFlow(storedUserData);
      } else {
        // Optional: Clear expired data from local storage
        localStorage.removeItem("user");
        localStorage.removeItem("profile");
        localStorage.removeItem("userData");
        localStorage.removeItem("timestamp");
      }
    }
  }, []);

  const login = () => {
    setDrawerOpen(false);
    setDisplayProduct(false);
    setDisplayLoginForm(true);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setDisplayLoginForm(false);
      setUser(codeResponse);
      setIsLoggedIn(true);
      const timestamp = new Date().getTime();
      localStorage.setItem("user", JSON.stringify(codeResponse));
      localStorage.setItem("timestamp", timestamp.toString());
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    setDrawerOpen(false);
    setDisplayLoginForm(false);
    googleLogout();
    setUser([]);
    setProfile(null);
    setUserData(null);
    setIsLoggedIn(false);
    setDisplayRegister(false);
    setDisplayEmployeeButtons(false);
    setDisplayManagerButtons(false);
    setDisplayOwnerButtons(false);
    setDisplayInitialButtons(true);
    setDailyId("");
    setBusinessName("");
    setDisplayDailyId(false);
    setRenderClockRecord(false);
    setRenderHome(false);
    setRenderUsers(false);
    setRenderSettings(false);
    setDisplayProfile(false);
    setDisplayReporting(false);
    setRenderScanner(false);
    setRenderTree(true);
    setDisplayProduct(true);

    localStorage.removeItem("user");
    localStorage.removeItem("profile");
    localStorage.removeItem("userData");
    localStorage.removeItem("timestamp");
  };

  const handleRegisterResponse = (response) => {
    if (response.userType === "Owner") {
      setUserData(response);
      setDisplayRegister(false);

      if (response.business.baseSub === "123") {
        setRenderBilling(false);
        initializeUserFlow(response);
      } else {
        setRenderBilling(true);
      }
    } else {
      alert("error");
    }
  };

  const handleClockRecordResponse = (newRecord) => {
    userData.clockRecords.push(newRecord);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setHomeButtonText("");
        setLogoutButtonText("");
        setReportingButtonText("");
        setUsersButtonText("");
        setSettingsButtonText("");
        setClockRecordText("");
        setSupportText("");
      } else {
        setHomeButtonText("Home");
        setLogoutButtonText("Logout");
        setReportingButtonText("Reporting");
        setUsersButtonText("Users");
        setSettingsButtonText("Settings");
        setClockRecordText("Record Time");
        setSupportText("MY PASS");
      }
    };

    // Set the initial state
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user && user.access_token) {
      setIsLoading(true);
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
          localStorage.setItem("profile", JSON.stringify(res.data));

          axios
            .get(`${baseURL}/users?email=${res.data.email}`, {
              headers: {
                access_token: user.access_token,
                Accept: "application/json",
              },
            })
            .then((userDataResponse) => {
              if (userDataResponse.data) {
                if (
                  userDataResponse.data.business === null &&
                  userDataResponse.data.userType === "Prospect"
                ) {
                  setIsLoading(false);
                  setIsProspect(true);
                  setDisplayRegister(true);
                  setDisplayInitialButtons(false);
                } else {
                  if (
                    userDataResponse.data.business.baseSub !== null &&
                    userDataResponse.data.business.baseSub.length > 0 &&
                    userDataResponse.data.business.addSub !== null &&
                    userDataResponse.data.business.addSub.length > 0 &&
                    userDataResponse.data.business.basePaid &&
                    userDataResponse.data.business.addPaid
                  ) {
                    localStorage.setItem(
                      "userData",
                      JSON.stringify(userDataResponse.data)
                    );
                  }

                  setUserData(userDataResponse.data);
                  setIsLoggedIn(true);
                  setIsLoading(false);
                  initializeUserFlow(userDataResponse.data);
                }
              }
            })
            .catch((userDataError) => {
              setIsLoading(false);
              setDisplayRegister(true);
              setDisplayInitialButtons(false);
            });
        })
        .catch((err) => console.log(err));
    }
  }, [user, isLoggedIn, baseURL]);

  const initializeUserFlow = (userData) => {
    if (userData.userType === "Owner") {
      setDisplayInitialButtons(false);
      setRenderTree(false);

      if (
        !userData.business.baseSub ||
        !userData.business.addSub ||
        !userData.business.basePaid ||
        !userData.business.addPaid
      ) {
        setRenderBilling(true);
      } else {
        setDisplayOwnerButtons(true);
        setBusinessName(userData.business.businessName);
        setDailyId(userData.business.dailyId);
        setDisplayDailyId(true);
        setRenderHome(true);
      }
    } else if (userData.userType === "Manager") {
      setDisplayInitialButtons(false);
      setRenderTree(false);

      if (!userData.business.baseSub || !userData.business.addSub) {
        setRenderBilling(true);
      } else {
        setDisplayManagerButtons(true);
        setBusinessName(userData.business.businessName);
        setDailyId(userData.business.dailyId);
        setDisplayDailyId(true);
        setRenderHome(true);

        if (userData.addUser || userData.deleteUser) {
          setDisplayUsers(true);
        }
      }
    } else if (userData.userType === "Employee") {
      setRenderTree(false);
      setDisplayInitialButtons(false);

      if (!userData.business.baseSub || !userData.business.addSub) {
        setRenderBilling(true);
      } else {
        setDisplayEmployeeButtons(true);
        setBusinessName(userData.business.businessName);
        setRenderHome(true);
      }
    } else if (userData.userType === "Scanner") {
      setDisplayInitialButtons(false);
      setRenderTree(false);

      if (!userData.business.baseSub || !userData.business.addSub) {
        setRenderBilling(true);
      } else {
        setBusinessName(userData.business.businessName);
        setRenderScanner(true);
      }
    } else if (userData.userType === "Prospect") {
      setIsProspect(true);
      setDisplayRegister(true);
      setDisplayInitialButtons(false);
      setRenderTree(false);
    } else {
      setDisplayRegister(true);
      setDisplayInitialButtons(false);
      setRenderTree(false);
    }
  };

  const clockRecord = () => {
    setDrawerOpen(false);
    setRenderHome(false);
    setRenderUsers(false);
    setRenderSettings(false);
    setDisplayProfile(false);
    setDisplayReporting(false);
    setRenderClockRecord(true);
  };

  const home = () => {
    setDrawerOpen(false);
    setDisplayProfile(false);
    setRenderClockRecord(false);
    setRenderUsers(false);
    setRenderSettings(false);
    setDisplayReporting(false);
    setRenderHome(true);
  };

  const handleSaveResponse = (response) => {
    setUserData(response.response.data);
  };

  const handleUserCrudResponse = (response) => {
    setUserData(response.response.data);
  };

  const handleUserClick = () => {
    setDrawerOpen(false);
    setDisplayProfile(false);
    setRenderHome(false);
    setRenderClockRecord(false);
    setDisplayReporting(false);
    setRenderSettings(false);
    setRenderUsers(true);
  };

  const handleSettingsClick = () => {
    setDrawerOpen(false);
    setDisplayProfile(false);
    setRenderHome(false);
    setRenderClockRecord(false);
    setRenderUsers(false);
    setDisplayReporting(false);
    setRenderSettings(true);
  };

  const handleProfileClick = () => {
    setDrawerOpen(false);
    setRenderHome(false);
    setRenderClockRecord(false);
    setRenderUsers(false);
    setRenderSettings(false);
    setDisplayReporting(false);
    setDisplayProfile(true);
  };

  const handleReportingClick = () => {
    setDrawerOpen(false);
    setRenderHome(false);
    setRenderClockRecord(false);
    setRenderUsers(false);
    setRenderSettings(false);
    setDisplayProfile(false);
    setDisplayReporting(true);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleOptionClick = (option) => {
    setDrawerOpen(false);
    if (option === 'Pricing') {
      setDisplayInitialHome(false);
      setDisplayAbout(false);
      setDisplayPricing(true)
    } else if (option === 'Home') {
      setDisplayPricing(false);
      setDisplayAbout(false);
      setDisplayInitialHome(true);
    } else {
      setDisplayPricing(false);
      setDisplayInitialHome(false);
      setDisplayAbout(true);
    }
  };

  return (
    <div
      class="newBody"
      style={{ backgroundColor: renderTree ? "#f4f4fc" : "#f4f4fc" }}
    >
      <div
        className="header"
        style={{
          borderBottom: !displayProduct
            ? "none"
            : "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: !displayProduct
            ? "none"
            : "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <div className="logo-container">
          <img
            src={ChronoLogo}
            alt="CompanyLogo"
            className="logo-image"
            style={{ paddingTop: "5px", paddingBottom: "5px" }}
          />
          <div className="logo-text">
            hronoLeaf
            {/* <Typography sx={{color: '#212020', fontSize: '32px', fontWeight: 300}}>
                      hronoLeaf
                    </Typography> */}
          </div>
        </div>
        {(displayOwnerButtons ||
          displayManagerButtons ||
          displayEmployeeButtons ||
          renderScanner) && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: !isNotLong ? 3 : "auto",
                mr: "auto",
                mt: !isNotLong ? 3 : 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  maxWidth: isNotLong ? "200px" : "none",
                  ml: "auto",
                  mr: "auto",
                  mt: !displayDailyId ? 1.5 : 0,
                }}
              >
                {businessName}
              </Typography>
              {displayDailyId && (
                <Typography variant="h7">{dailyId}</Typography>
              )}
            </Box>
          </>
        )}
        <div className="header-right">
          {displayRegister ? (
            <>
              <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={logOut}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>
                    </List>
                  </Drawer>
            </>
          ) : (
            <>
              {renderScanner && (
                <>
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={logOut}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>


                    </List>
                  </Drawer>
                </>
              )}
              {displayEmployeeButtons && (
                <>
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={handleProfileClick}>
                        <ListItemIcon>
                          <WalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Employee Pass" />
                      </ListItem>

                      <ListItem button onClick={clockRecord}>
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Record Time" />
                      </ListItem>

                      <ListItem button onClick={home}>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>

                      <ListItem button onClick={logOut}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>


                    </List>
                  </Drawer>
                </>
              )}
              {displayManagerButtons && (
                <>
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={handleUserClick}>
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users"/>
                      </ListItem>

                      <ListItem button onClick={handleReportingClick}>
                        <ListItemIcon>
                          <SummarizeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reporting" />
                      </ListItem>

                      <ListItem button onClick={handleProfileClick}>
                        <ListItemIcon>
                          <WalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Employee Pass" />
                      </ListItem>

                      <ListItem button onClick={clockRecord}>
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Record Time" />
                      </ListItem>

                      <ListItem button onClick={home}>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>

                      <ListItem button onClick={logOut}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>


                    </List>
                  </Drawer>
                </>
              )}
              {displayOwnerButtons && (
                <>
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={handleSettingsClick}>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </ListItem>

                      <ListItem button onClick={handleUserClick}>
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users"/>
                      </ListItem>

                      <ListItem button onClick={handleReportingClick}>
                        <ListItemIcon>
                          <SummarizeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reporting" />
                      </ListItem>

                      <ListItem button onClick={handleProfileClick}>
                        <ListItemIcon>
                          <WalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Employee Pass" />
                      </ListItem>

                      <ListItem button onClick={clockRecord}>
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Record Time" />
                      </ListItem>

                      <ListItem button onClick={home}>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>

                      <ListItem button onClick={logOut}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>


                    </List>
                  </Drawer>
                </>
                // <div className="support">
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-butto-owner-6"
                //     startIcon={
                //       <img
                //         src={SettingsIcon}
                //         alt="Settings Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={handleSettingsClick}
                //   >
                //     <Typography>{settingsButtonText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-button-owner-5"
                //     startIcon={
                //       <img
                //         src={UsersIcon}
                //         alt="Users Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={handleUserClick}
                //   >
                //     <Typography>{usersButtonText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-button-owner-4"
                //     startIcon={
                //       <img
                //         src={ReportIcon}
                //         alt="Reporting Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={handleReportingClick}
                //   >
                //     <Typography>{reportingButtonText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-butto-owner-6"
                //     startIcon={
                //       <img
                //         src={QrIcon}
                //         alt="Support Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={handleProfileClick}
                //   >
                //     <Typography>{supportText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-button-owner-3"
                //     startIcon={
                //       <img
                //         src={ClockIcon}
                //         alt="Record Clock Record Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={clockRecord}
                //   >
                //     <Typography>{clockRecordText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-button-owner-2"
                //     startIcon={
                //       <img
                //         src={HomeIcon}
                //         alt="Home Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={home}
                //   >
                //     <Typography>{homeButtonText}</Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     size="medium"
                //     className="login-button-owner-1"
                //     startIcon={
                //       <img
                //         src={LoginIcon}
                //         alt="Logout Button"
                //         style={{ height: "20px" }}
                //       />
                //     }
                //     sx={{
                //       mr: 1,
                //       backgroundColor: "#52796F",
                //       color: "black",
                //       "&:hover": { backgroundColor: "#435e57" },
                //       "@media (max-width: 450px)": {
                //         justifyContent: "center",
                //         alignItems: "center",
                //         "& .MuiButton-startIcon": { margin: 0 },
                //         "& .MuiButton-label": { display: "none" },
                //       },
                //     }}
                //     onClick={logOut}
                //   >
                //     <Typography>{logoutButtonText}</Typography>
                //   </Button>
                // </div>
              )}
              {displayInitialButtons && (
                <>
                  {/* <a className="initial-nav-button"><img src={ProductIcon} alt="Icon1" className="nav-icon" />Product</a> */}
                  {/* <Button
                    variant="contained"
                    size="medium"
                    startIcon={
                      <img
                        src={ProductIcon}
                        alt="Product Button"
                        style={{ height: "20px" }}
                      />
                    }
                    sx={{
                      mr: 1,
                      ml: 1,
                      backgroundColor: "#52796F",
                      color: "black",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={login}
                  >
                    <Typography>Product</Typography>
                  </Button> */}
                  {/* <Button
                    variant="contained"
                    size="medium"
                    startIcon={
                      <img
                        src={PriceIcon}
                        alt="Pricing Button"
                        style={{ height: "20px" }}
                      />
                    }
                    sx={{
                      mr: 1,
                      backgroundColor: "#52796F",
                      color: "black",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={login}
                  >
                    <Typography>Pricing</Typography>
                  </Button> */}

                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon fontSize="large" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                  >
                    <List>
                      <ListItem button onClick={login}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Login" />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => handleOptionClick("Pricing")}
                      >
                        <ListItemIcon>
                          <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Pricing"
                          sx={{ paddingRight: "10px" }}
                        />
                      </ListItem>
                      {/* <ListItem
                        button
                        onClick={() => handleOptionClick("About")}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About" />
                      </ListItem> */}
                      <ListItem
                        button
                        onClick={() => handleOptionClick("Home")}
                      >
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>
                    </List>
                  </Drawer>
                  {/* <Button
                    variant="contained"
                    size="medium"
                    className="login-button"
                    startIcon={
                      <img
                        src={LoginIcon}
                        alt="Login Button"
                        style={{ height: "20px" }}
                      />
                    }
                    sx={{
                      mr: 1,
                      backgroundColor: "#52796F",
                      color: "black",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={login}
                  >
                    <Typography>Login123</Typography>
                  </Button> */}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {displayRegister && (
        <Register
          class="register"
          newUser={profile}
          googleUser={user}
          onRegisterResponse={handleRegisterResponse}
          isProspect={isProspect}
        />
      )}
      {renderClockRecord && (
        <ClockRecord
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {renderHome && (
        <Home
          googleUser={user}
          user={userData}
          onSaveResponse={handleSaveResponse}
        />
      )}
      {renderUsers && (
        <Users
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {renderBilling && (
        <Billing
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {displayProfile && (
        <ProfileSupport
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {renderSettings && (
        <Settings
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {renderScanner && (
        <Scanner
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}
      {displayReporting && (
        <Reporting
          canDelete={true}
          user={userData}
          googleUser={user}
          onUserCrudResponse={handleUserCrudResponse}
        />
      )}

      {displayLoginForm && (
        <>
          <div
            class="settings-container"
            style={{ border: "none", borderRadius: "10px" }}
          >
            <Box
              sx={{
                position: "relative",
                maxWidth: "500px",
                minWidth: "320px",
                height: "auto",
                borderRadius: "10px",
                padding: "20px",
                border: "1px solid #ddd",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={logOut}
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>

              <Box
                sx={{
                  maxWidth: "85%",
                  ml: "auto",
                  mr: "auto",
                  paddingBottom: "20px",
                  mt: 2,
                }}
              >
                <Typography sx={{ mt: 2 }}>
                  <strong>ChronoLeaf</strong> currently uses Google OAuth 2.0
                  for secure and convenient authentication. We do not access or
                  store your Google password, ensuring your credentials remain
                  private. Soon, we will add login options for Facebook, X
                  (formerly Twitter), and Apple, offering more flexibility in
                  accessing our services.
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    mt: 2,
                    color: "black",
                    borderColor: "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderColor: "black",
                    },
                    textTransform: "none",
                  }}
                  startIcon={<FcGoogle size={24} />}
                  onClick={handleGoogleLogin}
                >
                  CONTINUE WITH GOOGLE
                </Button>
              </Box>
            </Box>
          </div>
        </>
      )}
      {displayProduct && <Product onRegisterClick={login} displayPricing={displayPricing} displayAbout={displayAbout} displayInitialHome={displayInitialHome}/>}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            marginTop: "10%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress sx={{ color: "#52796F" }} />
        </Box>
      )}

      {/* {renderTree && <><Tree />
          <div style={{
            width: '100%',
            marginTop: '-100px',
            height: '150px', // Adjust height as needed
            backgroundImage: `url(${grassImage})`,
            backgroundSize: 'cover', // Ensures the image covers the entire div without repeating
            backgroundPosition: 'center' // Centers the background image in the div
          }}>
          </div></>} */}
    </div>
  );
};

export default Landing;
