import React from 'react';
import './LeafConfetti.css';

const LeafConfetti = () => {
  const numberOfLeaves = 120; // Adjust the number of leaves here
  const leaves = Array.from({ length: numberOfLeaves }, (_, index) => (
    <div key={index} className={`leaf leaf-${index % 5 + 1}`}>
      <svg height="512px" viewBox="0 0 512 512" width="50px" xmlns="http://www.w3.org/2000/svg">
        <path fill="#52796F" stroke="#343F4B" stroke-width="4" d="M423.4,358c-43.8-6.7-47.1-13.1-47.1-13.1c9-54.1-18.9-122.6-64.6-159.7c-60.7-49.3-153.1-13.5-225-88.5 c-16.8-17.5-7,196.3,83,277.3c65.8,59.2,143.3,41,164.6,31.3c19.3-8.8,32.8-24.9,32.8-24.9c35.1,10.8,52.5,10.2,52.5,10.2 C431.9,392.1,438.1,360.2,423.4,358z M343.2,361.6c-115.3-34.1-202-153.8-202-153.8s76.1,89.8,210.2,133.5 C351.6,348.1,347.6,357.8,343.2,361.6z"/>
      </svg>
    </div>
  ));

  return (
    <div className="leaf-confetti">
      {leaves}
    </div>
  );
};

export default LeafConfetti;
