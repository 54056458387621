import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import env from '../environmentVariables';


const Billing = ({ canDelete, googleUser, user, onUserCrudResponse }) => {
    const { stripePay } = env;
    const { stripePayNoTrial } = env;
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
      if (user.userType === 'Owner') {
        setIsOwner(true);
      }
    }, [user]);

    const handleClose = () => {
      localStorage.removeItem("user");
      localStorage.removeItem("profile");
      localStorage.removeItem("userData");
      localStorage.removeItem("timestamp");
      window.location.reload();
    }

    const handleStripeClick = () => {
      if (user.business.usedTrial === null || user.business.usedTrial === false) {
        window.location.href = `${stripePay}${user.email}`;
      } else {
        window.location.href = `${stripePayNoTrial}${user.email}`;
      }
    }


  return (
    <>
        {isOwner ? (
            <div
            class="settings-container"
            style={{ border: "none", borderRadius: "10px" }}
          >
            <Box
      sx={{
        position: 'relative',
        maxWidth: "500px",
        minWidth: '320px',
        height: "auto",
        borderRadius: "10px",
        padding: '20px',
        border: '1px solid #ddd',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'black',
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={{ maxWidth: "85%", ml: "auto", mr: "auto", paddingBottom: '20px', mt: 2 }}>
      {user.business.usedTrial ? (
        <Typography sx={{ mt: 4 }}>
          At <strong>ChronoLeaf</strong>, our pricing structure is both competitive and straightforward: $20.00 monthly, plus $2.00 for each additional user.
          We prioritize your privacy and security, utilizing Stripe to securely process payments and manage subscriptions.

          <br>
          </br>
          <br></br>
          It appears your subscription is inactive. Please resubscribe or contact support if you believe this is an error.
        </Typography>
      ) : (
        <Typography sx={{ mt: 4 }}>
          At <strong>ChronoLeaf</strong>, our pricing structure is both competitive and straightforward: $20.00 monthly, plus $2.00 for each additional user.
          We prioritize your privacy and security, utilizing Stripe to securely process payments and manage subscriptions.
          Trust is key, which is why we offer a <strong>two-week risk-free trial</strong>, easily cancelable at any time through the owner's portal.
        </Typography>
      )}

        <Button
                    variant="contained"
                    tabIndex={-1}
                    onClick={handleStripeClick}
                    sx={{
                      ml: "auto",
                      mr: 0,
                      mt: 2,
                      width: "50%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                  >
                    SUBSCRIBE
                  </Button>

      </Box>
    </Box>
          </div>
        ) : (
          <div
          class="settings-container"
          style={{ border: "none", borderRadius: "10px" }}
        >
          <Box
    sx={{
      position: 'relative',
      maxWidth: "500px",
      minWidth: '320px',
      height: "auto",
      borderRadius: "10px",
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    }}
  >
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'black',
      }}
    >
      <CloseIcon />
    </IconButton>

    <Box sx={{ maxWidth: "85%", ml: "auto", mr: "auto", paddingBottom: '20px', mt: 2 }}>
      <Typography sx={{ mt: 2 }}>
        <strong>{user.business.businessName}</strong> is currently unavailable, please notify your business owner or contact support if this continues.
      </Typography>


    </Box>
  </Box>
        </div>

        )}
    </>
  );
};

export default Billing;
