
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Register.css';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import env from '../environmentVariables';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Register = ({ newUser, googleUser , onRegisterResponse, isProspect}) => {
  const { baseURL } = env;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [dailyCode, setDailyCode] = useState(true);
  const [qr, setQr] = useState(true);
  const [rfid, setRfid] = useState(false);
  const [biometrics, setBiometrics] = useState(false);
  const [user, setUser] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("error")
  const [hasAgreed, setHasAgreed] = useState(false);
  const [renderPrivacy, setRenderPrivacy] = useState(false);
  const [renderTos, setRenderTos] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasResponse, setHasResponse] = useState(false);

  const [triggerError, setTriggerError] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("An error has occured, please try later.");

  const [timezone, setTimezone] = useState('');

  const [phone, setPhone] = useState('');

  const [tardyAlert, setTardyAlert] = useState(true);
  const [overTimeAlert, setOverTimeAlert] = useState(true);

  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

  const [stateError, setStateError] = useState(false);

  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
    'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    if (isProspect) {
      setHasResponse(true);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const url = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=6636b43e9907c572372470pema25025`;

        try {
          const response = await axios.get(url);
          for (let i = 0; i < states.length; i+=1) {
            if (response.data.display_name.includes(states[i])) {
              setSelectedState(states[i])
            }
          }
        } catch (error) {
          console.error('Failed to fetch state from coordinates:', error);
        }
      }, (error) => {
        console.error('Geolocation error:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (isProspect) {
      setHasResponse(true);
    } else {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneAbbreviations = {
      'America/New_York': 'Eastern',
      'America/Chicago': 'Central',
      'America/Denver': 'Mountain',
      'America/Los_Angeles': 'Pacific'
    };

    if (timezoneAbbreviations[userTimezone]) {
      setTimezone(timezoneAbbreviations[userTimezone]);
    }
    }
  }, []);

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    if (event.target.value != '' && event.target.value != null) {
      setStateError(false);
    }
  };

  const togglePrivacy = () => {
    setRenderPrivacy(!renderPrivacy);
  };

  const toggleTos = () => {
    setRenderTos(!renderTos);
  };


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    if (event.target.value != '' && event.target.value != null) {
      setFirstNameError(false);
    }
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    if (event.target.value != '' && event.target.value != null) {
      setLastNameError(false);
    }
  };

  const handleChange = (event) => {
    setHasAgreed(event.target.checked);
};

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
    if (event.target.value != '' && event.target.value != null) {
      setBusinessNameError(false);
    }
  };

  const handlePhoneChange = (event) => {
    let value = event.target.value;
  
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length > 3 && onlyNums.length <= 6) {
      value = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    } else if (onlyNums.length > 6) {
      value = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    } else {
      value = onlyNums;
    }
  
    setPhone(value);
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    if (phoneRegex.test(value)) {
      setPhoneError(false);
    }
  };


  const handleRegisterBusiness = () => {
    setFirstNameError(firstName === "" || firstName === null);
    setLastNameError(lastName === "" || lastName === null);
    setBusinessNameError(businessName === "" || businessName === null);
    setPhoneError(!phoneRegex.test(phone));
    setStateError(selectedState === "" || selectedState === null);
  
    var requestBody = {
      businessId: 0,
      businessName: businessName,
      qrCode: qr,
      rfid: rfid,
      dailyCode: dailyCode,
      biometrics: biometrics,
      userId: 0,
      firstName: firstName,
      lastName: lastName,
      email: newUser.email,
      userType: 'Owner',
      accessToken: googleUser.access_token,
      timeZone: timezone,
      phone: phone,
      state: selectedState,
      tardyAlert: tardyAlert,
      overTimeAlert: overTimeAlert
    };
  
    if (!(firstName === "" || firstName === null) && !(lastName === "" || lastName === null) && !(businessName === "" || businessName === null) && !(selectedState === "" || selectedState === null) && phoneRegex.test(phone) && hasAgreed) {
      setIsLoading(true);
  
      axios.post(`${baseURL}/register`, JSON.stringify(requestBody), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setIsLoading(false);
        onRegisterResponse(response.data);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response && error.response.status === 422) {
          setHasResponse(true);
          setAlertSeverity("success");
          setErrorMsg("Successfully received your registration request.");
          setTriggerError(true);
        } else if (error.response && error.response.status === 429) {
          setAlertSeverity("error");
          setErrorMsg("It appears you are making too many requests. Please wait a moment then try again.");
          setTriggerError(true);
        } else {
          setAlertSeverity("error");
          setErrorMsg("Please try again later.");
          setTriggerError(true);
        }
      });
  
    } else {
      if (!hasAgreed) {
        setErrorMsg("Please ensure you check to agree to our terms of service and privacy policy.");
      } else {
        setErrorMsg("Please ensure you entered the required input.");
      }
      setTriggerError(true);
    }
  };
  

const switchStyles = {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
        color: "#7e4338",
    },
    "&.MuiSwitch-root .Mui-checked": {
        color: "#52796F",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: '#84A98C',
    },
    "& .MuiSwitch-track": {
        backgroundColor: '#9e5d51',
    }
};

const handleDailyCodeChange = () => {
  setDailyCode(!dailyCode)
}

const handleRfidChange = () => {
  setRfid(!rfid)
}

const handleBiometricsChange = () => {
  setBiometrics(!biometrics)
}

const handleTardyChange = () => {
  setTardyAlert(!tardyAlert);
}

const handleOverTimeChange = () => {
  setOverTimeAlert(!overTimeAlert);
}

const handleQrChange = () => {
  setQr(!qr)
}

  return (
    <>
    {isLoading ? (
      <Box sx={{ display: 'flex', marginTop: '15%', marginLeft: 'auto', marginRight: 'auto' }}>
          <CircularProgress sx={{ color: '#52796F' }}/>
      </Box>
  ) : ( 
    <Paper elevation={3} sx={{ backgroundColor: 'background.paper', minWidth: 300, maxWidth: 500, ml: 'auto', mr: 'auto', mt: '7%'}}>
      {hasResponse ? (<>
        <div style={{backgroundColor: '#52796F', color: '#eeeeee', paddingTop: '5px', paddingLeft: '5px', paddingRight: '5px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
          <Typography variant="h4" >Thank you for registering, {newUser.given_name}</Typography>
        </div>
        <Box sx={{maxWidth: '80%', display: 'flex', justifyContent: 'center', ml: 'auto',mr:'auto', paddingBottom: '10px'}}>
        <Typography sx={{mt: 2, ml: 'auto', mr:'auto'}}>
          ChronoLeaf is a new service and to ensure a quality product we are currently testing with a limited number of small businesses. We have been notified of your interest and will reach out shortly.
        </Typography>
        </Box>
      
      
      
      </>) : (
        <>
        <div style={{backgroundColor: '#52796F', color: '#eeeeee', paddingTop: '5px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
          <Typography variant="h4" >Welcome to ChronoLeaf, {newUser.given_name}</Typography>
          <Typography variant="h6" sx={{mt: 1, paddingBottom: 1}}>Time Keeping made Simple</Typography>
        </div>
        <div style={{maxHeight: '500px', overflowY:'auto'}}>
          <div style={{width: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
          <FormControl fullWidth>
            <FormControl sx={{mt: 2}}>
            <TextField
              id="register-first-name"
              label="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              error={firstNameError}
              InputLabelProps={{
                sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
              }}
              sx={{"& .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "black !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "#52796F !important"
              }}}
            />
            </FormControl>
            
            <FormControl sx={{mt: 2}}>
            <TextField
              id="register-last-name"
              label="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
              error={lastNameError}
              InputLabelProps={{
                sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
              }}
              sx={{"& .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "black !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "#52796F !important",
              }}}
            />
            </FormControl>
            
            <FormControl sx={{mt: 2}}>
            <TextField
              id="register-first-name-tf"
              label="Business Name"
              value={businessName}
              onChange={handleBusinessNameChange}
              error={businessNameError}
              InputLabelProps={{
                sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
              }}
              sx={{"& .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "black !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "#52796F !important",
              }}}
            />
            </FormControl>
            
            <FormControl sx={{mt: 2}}>
            <TextField
              id="register-phone-tf"
              label="Contact Number"
              value={phone}
              onChange={handlePhoneChange}
              error={phoneError}
              type="tel"  // Suggests numeric input
              InputLabelProps={{
                sx: { color: "black !important", "&.Mui-focused": { color: "#52796F !important" } },
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "black !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {  
                  borderColor: "#52796F !important",
                }
              }}
            />
            </FormControl>
            
          </FormControl>
          <FormControl fullWidth sx={{mt: 2}}>
      <InputLabel id="state-select-label" sx={{
                                              '&.MuiInputLabel-root': {
                                                  color: 'black',
                                              },
                                              '&.MuiInputLabel-root.Mui-focused': {
                                                  color: '#52796F',
                                              },
                                          }}>State</InputLabel>
      <Select
        labelId="state-select-label"
        id="state-select"
        value={selectedState}
        label="State"
        onChange={handleStateChange}
        error={stateError}
        sx={{
          '& .MuiSvgIcon-root': {
          color: 'black',
          },
          '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black'
          },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black'
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#52796F'
          }
      }}
      >
        {states.map((state) => (
          <MenuItem key={state} value={state}>{state}</MenuItem>
        ))}
      </Select>
    </FormControl>
                                    <FormControl fullWidth sx={{mt: 2}}>
                                          <InputLabel id="demo-simple-select-label" sx={{
                                              '&.MuiInputLabel-root': {
                                                  color: 'black',
                                              },
                                              '&.MuiInputLabel-root.Mui-focused': {
                                                  color: '#52796F',
                                              },
                                          }}> TimeZone
                                          </InputLabel>
                                          <Select
                                              value={timezone}
                                              label="Timezone"
                                              onChange={handleTimezoneChange}
                                              sx={{
                                                  '& .MuiSvgIcon-root': {
                                                  color: 'black',
                                                  },
                                                  '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor: 'black'
                                                  },
                                                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                  borderColor: 'black'
                                                  },
                                                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                  borderColor: '#52796F'
                                                  }
                                              }}
                                          >
                                              <MenuItem value="Eastern">Eastern</MenuItem>
                                              <MenuItem value="Central">Central</MenuItem>
                                              <MenuItem value="Mountain">Mountain</MenuItem>
                                              <MenuItem value="Pacific">Pacific</MenuItem>
                                          </Select>
                                  </FormControl>
                                  <Typography sx={{mt: 2, paddingBottom: 2}}>Select if you would like to enable alerts for when an employee is tardy or goes overtime. You can configure the tardiness thresholds in your owners portal.</Typography>
                                  <div className='add-and-edit-employee-switch-container' style={{ marginTop: '0' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0', marginRight: 'auto', width: '40%' }}>
                <Typography>Tardy Alerts</Typography>
                <div>
                    <Switch
                        checked={tardyAlert}
                        onChange={handleTardyChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: '0', width: '40%' }}>
                <Typography>Overtime Alerts</Typography>
                <div>
                    <Switch
                        checked={overTimeAlert}
                        onChange={handleOverTimeChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
          </div>
          <Typography sx={{mt: 2, paddingBottom: 2}}>Select all abilities that you would like employees to be able to clock in with. This can be changed later through the owners portal.</Typography>
          <div className='add-and-edit-employee-switch-container' style={{ marginTop: '0' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0', marginRight: 'auto', width: '40%' }}>
                <Typography>Daily Code</Typography>
                <div>
                    <Switch
                        checked={dailyCode}
                        onChange={handleDailyCodeChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: '0', width: '40%' }}>
                <Typography>QR</Typography>
                <div>
                    <Switch
                        checked={qr}
                        onChange={handleQrChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
          </div>
          <div className='add-and-edit-employee-switch-container'>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0', marginRight: 'auto', width: '40%' }}>
                <Typography>RFID</Typography>
                <div>
                    <Switch
                        checked={rfid}
                        onChange={handleRfidChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: '0', width: '40%' }}>
                <Typography>Biometrics</Typography>
                <div>
                    <Switch
                        checked={biometrics}
                        onChange={handleBiometricsChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ ...switchStyles }}
                    />
                </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection:'column'}}>
          <FormControlLabel
                sx={{width: '100%', mt: 3, ml:'auto', mr:'auto'}}
                control={<Checkbox checked={hasAgreed} onChange={handleChange} color="default" />}
                label={
                  <>
                      By continuing, you hereby agree to ChronoLeaf's
                      <a className='policyLinks' onClick={togglePrivacy} style={{fontWeight:'bolder'}}> privacy policy</a> and
                      <a className='policyLinks' onClick={toggleTos} style={{fontWeight:'bolder'}}> terms of service</a>.
                  </>
              }
            />
          <Button variant="contained" size="medium" sx={{mt: 2, mb: 2, backgroundColor: '#52796F', '&:hover': {backgroundColor: '#435e57'} }} onClick={handleRegisterBusiness}>
            <Typography>
              REGISTER
            </Typography>
          </Button>
          </div>
          </div>
        </div>
        <div className='settings-alert-container'>
                <Stack sx={{ width: '100%' }}>
                    {triggerError && (
                        <Alert 
                            severity={alertSeverity} 
                            onClose={() => setTriggerError(false)}  // Close the error alert
                        >
                            {errorMsg}
                        </Alert>
                    )}
                </Stack>
            </div>
            <BootstrapDialog
          onClose={togglePrivacy}
          aria-labelledby="customized-dialog-title"
          open={renderPrivacy}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, minWidth: "300px" }}
            id="customized-dialog-title"
          >
            Privacy Policy
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={togglePrivacy}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom sx={{ mt: 2 }}>
              At ChronoLeaf ("we", "us", or "our"), we understand the importance
              of your privacy. Our SaaS platform provides timekeeping services
              for small businesses, and this Privacy Policy outlines how we
              collect, use, disclose, and safeguard your information when you
              use our service. By using ChronoLeaf, you agree to the terms of
              this Privacy Policy
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We collect both business-related and user-related information to
              provide and enhance our services. The business data we collect
              includes the business name, state, phone number, timezone,
              associated scanner email, latitude and longitude (if provided),
              Stripe customer ID, email address, and relevant subscription IDs.
              For user data, we collect first name, last name, email address,
              and encrypted fingerprint data.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              This information is used to provide and maintain our service,
              manage subscriptions, and process payments through Stripe. We also
              use Google OAuth 2.0 for user authentication, which helps us
              populate user profiles with names and email addresses.
              Additionally, the data allows us to communicate with you regarding
              your account, offer customer support, and improve our platform's
              security and functionality.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We utilize third-party services like Stripe for subscription
              management and payment processing, and Google for OAuth 2.0
              authentication. Stripe's privacy policy can be found{" "}
              <a href="https://stripe.com/privacy" target="_blank">
                here
              </a>
              , and Google's privacy policy can be found{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                here
              </a>
              .
            </Typography>
            <Typography sx={{ mt: 2 }}>
              At ChronoLeaf, we are committed to protecting your data with
              industry-standard security measures to prevent unauthorized
              access, alteration, disclosure, or destruction. Employee data can
              be deleted at any time by removing the employee from your business
              account. Business and user data will be deleted if a subscription
              remains inactive for over a month or upon request via our support
              chat.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Depending on your location, you may have certain rights regarding
              your personal data, including the right to access and update your
              information, request deletion of your data, object to or restrict
              certain types of processing, and the right to data portability. To
              exercise any of these rights, please contact us via the support
              chat.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Our service is not intended for use by children under the age of
              13, and we do not knowingly collect personal information from
              children under 13. If we become aware that a child under 13 has
              provided us with personal information, we will delete such
              information from our servers.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We may update our Privacy Policy from time to time and will notify
              you of any changes by posting the new Privacy Policy on our
              website and updating the effective date at the top. We advise you
              to review this Privacy Policy periodically for any changes.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us via the support chat on our
              platform.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              size="medium"
              onClick={togglePrivacy}
              sx={{
                ml: "auto",
                mr: "auto",
                backgroundColor: "#52796F",
                "&:hover": { backgroundColor: "#435e57" },
              }}
            >
              <Typography>Close</Typography>
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={toggleTos}
          aria-labelledby="customized-dialog-title"
          open={renderTos}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, minWidth: "300px" }}
            id="customized-dialog-title"
          >
            Terms of Service
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={toggleTos}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom sx={{ mt: 2 }}>
              Welcome to ChronoLeaf. These Terms of Service ("Terms") govern
              your use of our SaaS platform, which provides timekeeping services
              for small businesses. By accessing or using ChronoLeaf, you agree
              to be bound by these Terms. If you do not agree with any part of
              these Terms, you should not use our service.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf grants you a limited, non-exclusive, non-transferable,
              and revocable license to use our platform for managing your
              business's timekeeping needs. You agree to use our service in
              compliance with all applicable laws and regulations.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              To use ChronoLeaf, you must register for an account. You agree to
              provide accurate, current, and complete information during the
              registration process and to update such information to keep it
              accurate, current, and complete. You are responsible for
              safeguarding your account password and for any activities or
              actions under your account.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf uses Stripe for subscription management and payment
              processing. By subscribing to our service, you agree to pay the
              applicable fees and taxes. All payments are processed securely by
              Stripe, and you agree to Stripe's terms and conditions.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We collect and store business data (such as business name, state,
              phone number, timezone, scanner email, latitude and longitude,
              Stripe customer ID, email, and subscription IDs) and user data
              (such as first name, last name, email address, and encrypted
              fingerprint data) to provide and improve our services. You agree
              to our data collection and usage practices as described in our
              Privacy Policy.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              You can delete employee data at any time by removing the employee
              from your business account. Business and user data will be deleted
              if a subscription remains inactive for over a month or upon
              request via our support chat.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We strive to ensure that ChronoLeaf is available at all times, but
              we do not guarantee uninterrupted access to our service. We may
              experience temporary outages due to maintenance, upgrades, or
              other factors beyond our control.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              ChronoLeaf is provided "as is" and "as available," without
              warranties of any kind, either express or implied. We disclaim all
              warranties, including but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, title, and
              non-infringement.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              To the maximum extent permitted by law, ChronoLeaf and its
              affiliates, officers, employees, agents, and partners shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to, loss of profits,
              data, use, goodwill, or other intangible losses, resulting from
              (i) your use or inability to use the service; (ii) any
              unauthorized access to or use of our servers and/or any personal
              information stored therein; (iii) any interruption or cessation of
              transmission to or from our service; (iv) any bugs, viruses,
              trojan horses, or the like that may be transmitted to or through
              our service by any third party; (v) any errors or omissions in any
              content or for any loss or damage incurred as a result of your use
              of any content posted, emailed, transmitted, or otherwise made
              available through the service; and/or (vi) any miscalculated or
              displayed wages, hours, or overtime due to bugs or errors in our
              service.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              You agree to indemnify, defend, and hold harmless ChronoLeaf, its
              affiliates, officers, employees, agents, and partners from and
              against any and all claims, liabilities, damages, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              from your use of the service or your violation of these Terms.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              We may modify these Terms at any time. We will notify you of any
              changes by posting the new Terms on our website and updating the
              effective date at the top. Your continued use of ChronoLeaf after
              any such changes constitutes your acceptance of the new Terms.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              These Terms shall be governed and construed in accordance with the
              laws of the State of North Carolina, without regard to its
              conflict of law principles.
            </Typography>
            <Typography gutterBottom sx={{ mt: 2 }}>
              If you have any questions about these Terms, please contact us via
              the support chat on our platform.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              size="medium"
              onClick={toggleTos}
              sx={{
                ml: "auto",
                mr: "auto",
                backgroundColor: "#52796F",
                "&:hover": { backgroundColor: "#435e57" },
              }}
            >
              <Typography>Close</Typography>
            </Button>
          </DialogActions>
        </BootstrapDialog>
        </>
      )}
    </Paper>
            )}
            </>
  );
}

export default Register;