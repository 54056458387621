import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import './WeeklyClockRecords.css';
import ExitIcon from './ExitIcon.png';
import PlusIcon from './PlusIcon.png';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

// Helper function to format total hours worked
const formatHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    return `${hours}.${(remainingMinutes < 10 ? '0' : '') + remainingMinutes}`;
};

const WeeklyClockRecords = forwardRef(({
  mondayRecords, tuesdayRecords, wednesdayRecords, thursdayRecords, 
  fridayRecords, saturdayRecords, sundayRecords, onDailyTotal, 
  isLeader, onWeekEdit, dateRange, year, user, operator, userListInfo, selectedUserId
}, ref) => {


  const [editingRecord, setEditingRecord] = useState({ day: null, clockRecordId: null });
  const [numRecordsAdded, setNumRecordsAdded] = useState(0);
  const [editingId, setEditingId] = useState(0);
  const [changes, setChanges] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  const [daysRecords, setDaysRecords] = useState({
    monday: mondayRecords,
    tuesday: tuesdayRecords,
    wednesday: wednesdayRecords,
    thursday: thursdayRecords,
    friday: fridayRecords,
    saturday: saturdayRecords,
    sunday: sundayRecords
  });

  useImperativeHandle(ref, () => ({
    clearFunction() {
      setChanges([]);
      setEditingRecord({ day: null, clockRecordId: null });
    }
  }));

  useEffect(() => {
    setCanEdit(false)
    let userList = operator.business.users;
    if (operator.userType === "Owner") {
      setCanEdit(true)
      setEditingId(operator.userId)
    } else if (operator.userType === "Manager") {

      if (user === "Self" && operator.editManager) {
        setCanEdit(true)
        setEditingId(operator.userId)
      } else {
        for (let i = 0; i < userList.length; i++) {
          let fullName = userList[i].firstName + " " + userList[i].lastName;
          if (fullName === user) {
            setEditingId(userList[i].userId)
            if (operator.editManager && userList[i].userType === "Manager") {
              setCanEdit(true);
            } else if (operator.editEmployee && userList[i].userType === "Employee") {
              setCanEdit(true);
            }
          }
        }
      }
    }

    setDaysRecords({
        monday: mondayRecords,
        tuesday: tuesdayRecords,
        wednesday: wednesdayRecords,
        thursday: thursdayRecords,
        friday: fridayRecords,
        saturday: saturdayRecords,
        sunday: sundayRecords
    });

}, [mondayRecords, tuesdayRecords, wednesdayRecords, thursdayRecords, fridayRecords, saturdayRecords, sundayRecords, isLeader, dateRange, operator, userListInfo]);

  const prevTimeRef = useRef();

  const handleTimeChangeStart = (day, clockRecordId, time) => {
    setEditingRecord({ day, clockRecordId });
  };
  
  const handleTimeChangeEnd = (timeInput, day, clockRecordId) => {

    if (daysRecords[day].length >= 2) {
      for (let i = 0; i < daysRecords[day].length; i++) {
        if (daysRecords[day][i].clockRecordId === clockRecordId) {
          const currentIndex = i;
          if (currentIndex - 1 >= 0) {
            const prevRecordTime = new Date(daysRecords[day][currentIndex - 1].timeRecorded);
            const prevRecordHours = prevRecordTime.getHours();
            const prevRecordMinutes = prevRecordTime.getMinutes();
            const formattedPrevRecordTime = `${prevRecordHours.toString().padStart(2, '0')}:${prevRecordMinutes.toString().padStart(2, '0')}`;
      
            if (timeInput !== "" && timeInput > formattedPrevRecordTime) {
              // good to add - unless timeInput is after next record
              if (currentIndex + 1 < daysRecords[day].length) {
                const nextRecordTime = new Date(daysRecords[day][currentIndex + 1].timeRecorded);
                const nextRecordHours = nextRecordTime.getHours();
                const nextRecordMinutes = nextRecordTime.getMinutes();
                const formattedNextRecordTime = `${nextRecordHours.toString().padStart(2, '0')}:${nextRecordMinutes.toString().padStart(2, '0')}`;

                if (timeInput < formattedNextRecordTime) {
                  executeTimeChangeEnd(timeInput, day, clockRecordId);
                } else {
                  setEditingRecord({ day: null, clockRecordId: null });
                }
              } else {
                executeTimeChangeEnd(timeInput, day, clockRecordId);
              }
            } else {
              setEditingRecord({ day: null, clockRecordId: null });
            }
          } else if (currentIndex === 0) {
            if (currentIndex + 1 < daysRecords[day].length) {
              const nextRecordTime = new Date(daysRecords[day][currentIndex + 1].timeRecorded);
              const nextRecordHours = nextRecordTime.getHours();
              const nextRecordMinutes = nextRecordTime.getMinutes();
              const formattedNextRecordTime = `${nextRecordHours.toString().padStart(2, '0')}:${nextRecordMinutes.toString().padStart(2, '0')}`;

              if (timeInput < formattedNextRecordTime) {
                executeTimeChangeEnd(timeInput, day, clockRecordId);
              }
            } else {
              executeTimeChangeEnd(timeInput, day, clockRecordId);
            }
          }
        }
      }
    } else {
      executeTimeChangeEnd(timeInput, day, clockRecordId);
    }
    
  };

  const executeTimeChangeEnd = (timeInput, day, clockRecordId) => {
    const updatedRecords = daysRecords[day].map(record => {
      if (record.clockRecordId === clockRecordId) {
        const originalDateTime = new Date(record.timeRecorded);

        const [hours, minutes] = timeInput.split(':').map(Number);

        originalDateTime.setHours(hours, minutes);

        const updatedDateTimeStr = originalDateTime.toISOString();
  
        return { ...record, timeRecorded: updatedDateTimeStr };
      }
      return record;
    });
  
    setDaysRecords(prevRecords => ({
      ...prevRecords,
      [day]: updatedRecords
    }));

    const lastUpdatedTime = updatedRecords[updatedRecords.length - 1].timeRecorded;

    setChanges(prevChanges => [...prevChanges, { clockRecordId, change: 'time', newTime: lastUpdatedTime }]);

    const datePart = "2024-01-15"; // The desired date
    const dateTime = new Date(`${datePart}T${timeInput}`);
    const isoDateTime = dateTime.toISOString();


    onWeekEdit({clockRecordId, change: 'edit', newTime: adjustDateForDay(isoDateTime, day), userId: selectedUserId})
    setEditingRecord({ day: null, clockRecordId: null }); // Reset editing state
    updateTotalInHome();
  }

  const handleRemoveRecord = (day, clockRecordId, inOrOut) => {

    var goodForDay = false;

    setDaysRecords(prevRecords => {
      const newDayRecords = {
        ...prevRecords,
        [day]: prevRecords[day].filter(record => record.clockRecordId !== clockRecordId)
      };
  

      // if (newDayRecords[day].length === 0) {
      //   goodForDay = true;
      // } else {
      //   // if newDayRecords[day].inOrOut alternate IN and OUT set goodForDay true -> also the day must start with IN and end with OUT
      // }

      if (newDayRecords[day].length === 0) {
        goodForDay = true;
      } else if (newDayRecords[day].length >= 2) {
        const records = newDayRecords[day];
        const startsWithIn = records[0].inOrOut === 'IN';
        const endsWithOut = records[records.length - 1].inOrOut === 'OUT';
        const alternatesCorrectly = records.every((record, index) => {
          if (index === 0) return true; // Skip the first record, already checked
  
          const previousRecord = records[index - 1];
          return (previousRecord.inOrOut === 'IN' && record.inOrOut === 'OUT') ||
                 (previousRecord.inOrOut === 'OUT' && record.inOrOut === 'IN');
        });
  
        goodForDay = startsWithIn && endsWithOut && alternatesCorrectly;
      }
  

      if (goodForDay) {
        onWeekEdit({message: 'GFD', day: day})
      } else {
        onWeekEdit({message: 'BFD', day: day})
      }


      // END
  
      return newDayRecords;
    });
  
    setChanges(prevChanges => [...prevChanges, { clockRecordId, change: 'remove' }]);
  
    onWeekEdit({ clockRecordId, change: 'remove', newTime: null, userId: selectedUserId, day: day, type: inOrOut, goodForDay: goodForDay });
  };

  const handleAddRecord = (day, lastRecordClass) => {
    // prev
    const newClass = lastRecordClass === 'IN' ? 'OUT' : 'IN';

    const formatDateNoSeconds = (date) => {
      return date.toISOString().replace(/:\d{2}\.\d{3}Z$/, ':00.000Z'); // Remove seconds and milliseconds, replace with ':00.000Z'
  };

    var newRecord = { clockRecordId: "EDIT" + numRecordsAdded, inOrOut: newClass, timeRecorded: formatDateNoSeconds(new Date()), userId: selectedUserId};
    if (daysRecords[day].length > 0) {
      // Parse the last record's timeRecorded into a Date object
      const lastRecordTime = new Date(daysRecords[day][daysRecords[day].length - 1].timeRecorded);
    
      // Add one minute
      lastRecordTime.setMinutes(lastRecordTime.getMinutes() + 1);
    
      // Format back to ISO string
      const newTimeRecorded = lastRecordTime.toISOString();
    
      // Create new record with updated time
      newRecord = { 
        clockRecordId: "EDIT" + numRecordsAdded, 
        inOrOut: newClass, 
        timeRecorded: newTimeRecorded,
        userId: selectedUserId
      };
    }
    
    setNumRecordsAdded(numRecordsAdded => numRecordsAdded + 1);

    // setDaysRecords(prevRecords => ({
    //   ...prevRecords,
    //   [day]: [...prevRecords[day], newRecord]
    // }));

    // NEW
    var goodForDay = false;

    setDaysRecords(prevRecords => {
      const newDayRecords = {
        ...prevRecords,
        [day]: [...prevRecords[day], newRecord]
      };
  
      if (newDayRecords[day].length >= 2) {
        const records = newDayRecords[day];
        const startsWithIn = records[0].inOrOut === 'IN';
        const endsWithOut = records[records.length - 1].inOrOut === 'OUT';
        const alternatesCorrectly = records.every((record, index) => {
          if (index === 0) return true; // Skip the first record, already checked
  
          const previousRecord = records[index - 1];
          return (previousRecord.inOrOut === 'IN' && record.inOrOut === 'OUT') ||
                 (previousRecord.inOrOut === 'OUT' && record.inOrOut === 'IN');
        });
  
        goodForDay = startsWithIn && endsWithOut && alternatesCorrectly;
      }

      if (goodForDay) {
        onWeekEdit({message: 'GFD', day: day})
      } else {
        onWeekEdit({message: 'BFD', day: day})
      }

      return newDayRecords;
    });


    // END NEW

    setChanges(prevChanges => [...prevChanges, { clockRecordId: newRecord.clockRecordId, change: 'add', class: newClass }]);
    let dateAndTime = newRecord.timeRecorded;

    onWeekEdit({clockRecordId: newRecord.clockRecordId, change: 'add', newTime: adjustDateForDay(newRecord.timeRecorded, day), inOrOut: newClass, userId: selectedUserId, day: day, goodForDay: goodForDay});
  };

  const adjustDateForDay = (dateAndTime, day) => {
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    
    // Extract the start date (Monday) from the dateRange
    const startDateStr = dateRange.split(': ')[1].split(' - ')[0]; // "Sep 18"
    const startDate = new Date(`${startDateStr}, ${year}`); // e.g., "Sep 18, 2023"
  
    const dayIndex = daysOfWeek.indexOf(day.toLowerCase());
  
    // Adjust the date to the specified day of the week
    startDate.setDate(startDate.getDate() + dayIndex);
  
    // Keeping the time from the original dateAndTime
    const time = new Date(dateAndTime);
    startDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  
    return startDate.toISOString();
  };
  
  // Function to sort records by time
  const sortRecordsByTime = (records) => {
    return records.sort((a, b) => new Date(a.timeRecorded) - new Date(b.timeRecorded));
  };

  // Function to calculate total hours worked in a day
  const calculateTotalHours = (records) => {
    let totalMinutes = 0;
    for (let i = 0; i < records.length; i += 2) {
      const startTime = records[i] && new Date(records[i].timeRecorded);
      const endTime = records[i + 1] && new Date(records[i + 1].timeRecorded);
      if (startTime && endTime) {
        totalMinutes += (endTime - startTime) / (1000 * 60); // Convert milliseconds to minutes
      }
    }
    return formatHours(totalMinutes); // Convert minutes to hours and minutes
  };

  const handleTimeOnChange = (newTimeInput, day, clockRecordId) => {
    if (daysRecords[day].length >= 2) {
      for (let i = 0; i < daysRecords[day].length; i++) {
        if (daysRecords[day][i].clockRecordId === clockRecordId) {
          const currentIndex = i;
          if (currentIndex + 1 < daysRecords[day].length) {
            const nextRecordTime = new Date(daysRecords[day][currentIndex + 1].timeRecorded);
            const nextRecordHours = nextRecordTime.getHours();
            const nextRecordMinutes = nextRecordTime.getMinutes();
            const formattedNextRecordTime = `${nextRecordHours.toString().padStart(2, '0')}:${nextRecordMinutes.toString().padStart(2, '0')}`;

            if (newTimeInput !== "" && newTimeInput < formattedNextRecordTime) {
              setEditingRecord({ day: day, clockRecordId: clockRecordId });
            } else {
              setEditingRecord({ day: null, clockRecordId: null });
            }
          }
        }
      }
    }
  };

  // Function to render records for a day
  const renderDayRecords = (day) => {
    const records = daysRecords[day];
    const sortedRecords = sortRecordsByTime(records);
    const totalHours = calculateTotalHours(sortedRecords);
    const lastRecordClass = records.length > 0 ? records[records.length - 1].inOrOut : 'OUT';
    const editedRecordIds = changes.map(change => change.clockRecordId);
  
    return (
      <div className="day-container">
       
       <div>
          <FormControl sx={{ width: '160px' }}>
            <Button
              variant="outlined"
              disabled
              sx={{
                border: '1px solid black',
                height: '40px',
                color: 'black',
                '&.Mui-disabled': {
                  borderColor: 'black',
                  color: 'white',
                  backgroundColor: '#3E606D'
                },
                '@media (max-width: 830px)': {
                  mt: 1
                }
              }}
            >
              {day}
            </Button>
          </FormControl>
        </div>
      
        {sortedRecords.map((record, index) => (
          <div 
          key={index} 
          className={`record-item ${record.inOrOut} ${typeof record.clockRecordId === 'string' && record.clockRecordId.includes('EDIT') ? 'new-record' : ''} ${changes.some(change => change.clockRecordId === record.clockRecordId) ? 'edited-record' : ''}`}
          style={{border: '1px solid black', marginTop: '5px', paddingRight: '3px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            {canEdit ? (
              <>
                {editingRecord.clockRecordId === record.clockRecordId ? (
                  <input 
                    type="time" id="myTimeInput" step="60"
                    defaultValue={new Date(record.timeRecorded).toLocaleTimeString('en-US', { hour12: false })}
                    onBlur={(e) => handleTimeChangeEnd(e.target.value, day, record.clockRecordId)}
                    onChange={(e) => handleTimeOnChange(e.target.value, day, record.clockRecordId)}
                    autoFocus
                  />
                ) : (
                  <div onClick={() => handleTimeChangeStart(day, record.clockRecordId, record.timeRecorded)}>
                    {new Date(record.timeRecorded).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                  </div>
                )}
                <img src={ExitIcon} className="exit-icon" onClick={() => handleRemoveRecord(day, record.clockRecordId, record.inOrOut)} alt="Exit" />
              </>
            ) : (
                <>
                  {new Date(record.timeRecorded).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                </>
            )}
          </div>
        ))}
        {canEdit && <FormControl sx={{width: '40px', ml: 'auto', mr: 'auto'}}><IconButton onClick={() => handleAddRecord(day, lastRecordClass)}><img src={PlusIcon} alt="Add Record" style={{height: '32px'}}/></IconButton></FormControl>}
        <div>
          <FormControl sx={{ width: '160px' }}>
            <Button
              variant="outlined"
              disabled
              sx={{
                border: '1px solid black',
                height: '40px',
                color: 'black',
                mt: 1,
                '&.Mui-disabled': {
                  borderColor: 'black',
                  color: 'white',
                  backgroundColor: '#3E606D'
                },
                '@media (max-width: 830px)': {
                  mt: 1
                }
              }}
            >
              {totalHours}
            </Button>
          </FormControl>
        </div>
      </div>
    );
  };
  

  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split('.').map(Number);
    return { hours, minutes };
  };
  
  const formatTime = (hours, minutes) => {
    // Convert excess minutes into hours
    const totalHours = hours + Math.floor(minutes / 60);
    const totalMinutes = minutes % 60;
  
    // Format to "HH.MM"
    return `${totalHours}.${totalMinutes < 10 ? '0' : ''}${totalMinutes}`;
  };

  const updateTotalInHome = () => {
    const times = Object.keys(daysRecords).map(day => calculateTotalHours(daysRecords[day]));
  
    let totalHours = 0;
    let totalMinutes = 0;
  
    times.forEach(timeString => {
      const { hours, minutes } = parseTime(timeString);
      totalHours += hours;
      totalMinutes += minutes;
    });
  
    onDailyTotal(formatTime(totalHours, totalMinutes) + " Hours");
  };
  
  
  

  useEffect(() => {
    updateTotalInHome();
  }, [daysRecords]); // Depend on daysRecords state
  

  return (
    <div className="weekly-records-component">
      {renderDayRecords('monday')}
      {renderDayRecords('tuesday')}
      {renderDayRecords('wednesday')}
      {renderDayRecords('thursday')}
      {renderDayRecords('friday')}
      {renderDayRecords('saturday')}
      {renderDayRecords('sunday')}
    </div>
  );
})

export default WeeklyClockRecords;