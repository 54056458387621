import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is used or remove it if unnecessary
import "./Users.css";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import env from '../environmentVariables';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Users = ({ canDelete, googleUser, user, onUserCrudResponse }) => {
  const { baseURL } = env;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [editEmployee, setEditEmployee] = useState(false);
  const [editManager, setEditManager] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Success");
  const [errorMsg, setErrorMsg] = useState(
    "An error has occured, please try later."
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [noEmployees, setNoEmployees] = useState(false);
  const [noAdd, setNoAdd] = useState(false);
  const [department, setDepartment] = useState("");
  const [title, setTitle] = useState("");
  const [wage, setWage] = useState(0);
  const [shiftStart, setShiftStart] = useState(null);
  const [departmentError, setDepartmentError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [wageError, setWageError] = useState(false);
  const [startTime, setStartTime] = useState("09:00");
  const [selectedEditEmployee, setSelectedEditEmployee] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editWage, setEditWage] = useState(0.0);
  const [editShiftStart, setEditShiftStart] = useState("09:00");
  const [editDepartmentError, setEditDepartmentError] = useState(false);
  const [editTitleError, setEditTitleError] = useState(false);
  const [editWageError, setEditWageError] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editEmailError, setEditEmailError] = useState(false);
  const [prevEmail, setPrevEmail] = useState("");
  const [allDepartmentList, setAllDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEditDepartment, setSelectedEditDepartment] = useState("");

  const [startError, setStartError] = useState(false);
  const [editStartError, setEditStartError] = useState(false);

  useEffect(() => {
    var employeeList = [];

    if (user.business.users !== null) {
      for (let i = 0; i < user.business.users.length; i++) {
        if (
          user.business.users[i].userType !== "Owner" &&
          user.business.users[i] !== user.userId &&
          user.business.users[i].userType !== "Scanner"
        ) {
          let fullName =
            user.business.users[i].firstName +
            " " +
            user.business.users[i].lastName;
          const newEmployee = { ...user.business.users[i], fullName: fullName };
          employeeList.push(newEmployee);
        }
      }
    }

    setAllEmployeeList(employeeList);

    if (
      user.business != null &&
      user.business.departments != null &&
      user.business.departments.length > 0
    ) {
      setAllDepartmentList(user.business.departments);
      setSelectedDepartment(user.business.departments[0].departmentId);
      setSelectedEditDepartment(user.business.departments[0].departmentId);
    }

    if (employeeList.length === 0 || !user.deleteUser) {
      setNoEmployees(true);
      handleChangeIndex(0);
    } else {
      if (!user.addUser) {
        setNoAdd(true);
        handleChangeIndex(1);
      }

      setNoEmployees(false);
    }

    if (employeeList.length > 0) {
      setSelectedEmployee(employeeList[0].userId);
      setSelectedEditEmployee(employeeList[0].userId);

      let depName = employeeList[0].department;
      for (let j = 0; j < user.business.departments.length; j++) {
        if (user.business.departments[j].departmentName === depName) {
          setSelectedEditDepartment(user.business.departments[j].departmentId);
        }
      }
      setEditDepartment(employeeList[0].department);
      setEditTitle(employeeList[0].title);
      setEditWage(employeeList[0].wage);
      setEditShiftStart(formatTime(employeeList[0].shiftStart));
      setEditEmail(employeeList[0].email);
      setPrevEmail(employeeList[0].email);
    } else {
      setSelectedEmployee("");
      setSelectedEditEmployee("");
    }
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23", // Use 24-hour time without AM/PM
    });
  };

  const handleEditEmployeeChange = (event) => {
    setSelectedEditEmployee(event.target.value);

    for (let i = 0; i < allEmployeeList.length; i++) {
      if (event.target.value === allEmployeeList[i].userId) {
        setEditDepartment(allEmployeeList[i].department);
        setEditTitle(allEmployeeList[i].title);
        setEditWage(allEmployeeList[i].wage);
        setEditEmail(allEmployeeList[i].email);
        let time = formatTime(allEmployeeList[i].shiftStart);
        setEditShiftStart(time);
        setPrevEmail(allEmployeeList[i].email);

        let depName = allEmployeeList[i].department;
        for (let j = 0; j < allDepartmentList.length; j++) {
          if (allDepartmentList[j].departmentName === depName) {
            setSelectedEditDepartment(allDepartmentList[j].departmentId);
          }
        }
      }
    }
  };

  const handleTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const dynamicSuccessOrErrorStyle =
    triggerSuccess || triggerError ? { borderBottom: "1px solid black" } : {};

  const isValidGoogleEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return emailRegex.test(email);
  };

  const handleAddUser = () => {
    let numWage = parseFloat(wage);
    setFirstNameError(firstName === "" || firstName === null);
    setLastNameError(lastName === "" || lastName === null);
    setEmailError(email === "" || email === null || !isValidGoogleEmail(email));
    setTitleError(title == "" || title == null);
    setWageError(wage < 0 || wage == null || wage == 0 || isNaN(numWage) || wage > 2000);
    setStartError(startTime === "" || startTime === null);

    if (
      !(firstName === "" || firstName === null) &&
      !(lastName === "" || lastName === null) &&
      !(email === "" || email === null || !isValidGoogleEmail(email)) &&
      !(title === "" || title === null) &&
      !(wage < 0 || wage == null || wage == 0 || isNaN(numWage) || wage > 2000) &&
      !(startTime === "" || startTime === null)
    ) {
      const now = new Date();
      const [hours, minutes] = startTime.split(":");
      const dateToSend = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        parseInt(hours),
        parseInt(minutes)
      );

      let depName = "";
      for (let i = 0; i < allDepartmentList.length; i++) {
        if (selectedDepartment === allDepartmentList[i].departmentId) {
          depName = allDepartmentList[i].departmentName;
        }
      }

      let UserDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        operation: "Add",
        businessId: user.business.businessId,
        newFirstName: firstName,
        newLastName: lastName,
        newEmail: email,
        newUserType: "Employee",
        deleteId: null,
        department: depName,
        title: title,
        wage: wage,
        shiftStart: dateToSend,
      };

      axios
        .post(
          `${baseURL}/addOrDeleteOrEditEmployee`,
          UserDTO
        )
        .then((response) => {
          onUserCrudResponse({ response });
          setSuccessMsg(
            "Successfuly added " + firstName + " " + lastName + "."
          );
          setTriggerSuccess(true);
          setFirstName("");
          setLastName("");
          setEmail("");
          setTitle("");
        })
        .catch((error) => {
          if (error.response) {
            const { data, status } = error.response;
            
            // Check if data exists and matches specific error messages
            if (data && data === "Error: Duplicate Email exists") {
                setErrorMsg("It appears that email is already in use.");
                setTriggerError(true);
            } else if (data && data === "Error: Duplicate Name exists") {
                setErrorMsg("It appears you already have an employee with that given name. Please use a nickname.");
                setTriggerError(true);
            } else if (status === 409) {
                setErrorMsg("It appears that email is already in use.");
                setTriggerError(true);
            } else if (status === 429) {
                setErrorMsg("It appears you are making too many requests. Please wait a moment then try again.");
                setTriggerError(true);
            } else {
                // Default error message if none of the specific statuses match
                setErrorMsg("An error has occurred, please try later.");
                setTriggerError(true);
            }
        } else {
            // Handle cases where there is no error response from the server
            setErrorMsg("An unexpected error occurred. Please check your network connection and try again.");
            setTriggerError(true);
        }
        });
    } else {
      setErrorMsg(
        "Please ensure inputs are populated and a valid google email."
      );
      setTriggerError(true);
    }
  };

  const handleDeleteUser = () => {
    let deleteName = "";

    if (allEmployeeList.length === 0) {
      setErrorMsg("It appears that are no employees to delete.");
      setTriggerError(true);
    } else {
      for (let i = 0; i < allEmployeeList.length; i += 1) {
        if (allEmployeeList[i].userId === selectedEmployee) {
          deleteName =
            allEmployeeList[i].firstName + " " + allEmployeeList[i].lastName;
        }
      }

      let UserDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        operation: "Delete",
        businessId: user.business.businessId,
        newFirstName: null,
        newLastName: null,
        newEmail: null,
        newUserType: null,
        deleteId: selectedEmployee,
      };

      axios
        .post(
          `${baseURL}/addOrDeleteOrEditEmployee`,
          UserDTO
        )
        .then((response) => {
          onUserCrudResponse({ response });
          setSuccessMsg("Successfuly deleted " + deleteName);
          setTriggerSuccess(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            setErrorMsg("It appears you are making too many requests. Please wait a moment then try again.");
          } else {
            setErrorMsg("An error has occured, please try later.");            
          }
          setTriggerError(true);
        });
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setFirstNameError(false);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setLastNameError(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (isValidGoogleEmail(email)) {
      setEmailError(false);
    }
  };

  const handleEditEmailChange = (event) => {
    setEditEmail(event.target.value);
    if (isValidGoogleEmail(email)) {
      setEditEmailError(false);
    }
  };

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setDepartmentError(false);
    }
  };

  const handleEditDepartmentChange = (event) => {
    setEditDepartment(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setEditDepartmentError(false);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setTitleError(false);
    }
  };

  const handleWageChange = (event) => {
    setWage(event.target.value);
    if (event.target.value > 0 && event.target.value != null) {
      setWageError(false);
    }
  };

  const handleEditWageChange = (event) => {
    setEditWage(event.target.value);
    if (event.target.value > 0 && event.target.value != null) {
      setEditWageError(false);
    }
  };

  const handleEditTitleChange = (event) => {
    setEditTitle(event.target.value);
    if (event.target.value > 0 && event.target.value != null) {
      setEditTitleError(false);
    }
  };

  const handleShiftStartChange = (event) => {
    setShiftStart(event.target.value);
    setStartTime(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setStartError(false);
    }
  };

  const blockInvalidChar = (e) => {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleEditShiftStartChange = (event) => {
    setEditShiftStart(event.target.value);
    if (event.target.value != "" && event.target.value != null) {
      setEditStartError(false);
    }
  };

  const handleEditUser = () => {
    let numWage = parseFloat(editWage);
    setEditEmailError(
      editEmail === "" || editEmail === null || !isValidGoogleEmail(editEmail)
    );
    setEditTitleError(editTitle == "" || editTitle == null);
    setEditWageError(editWage < 0 || editWage == null || editWage == 0 || isNaN(numWage) || editWage > 2000);
    setEditStartError(editShiftStart == "" || editShiftStart == null);

    if (
      !(editTitle === "" || editTitle === null) &&
      !(editWage < 0 || editWage == null || editWage == 0 || isNaN(numWage) || editWage > 2000) &&
      !(
        editEmail === "" ||
        editEmail === null ||
        !isValidGoogleEmail(editEmail)
      ) &&
      !(editShiftStart == "" || editShiftStart == null)
    ) {
      const now = new Date();
      const [hours, minutes] = editShiftStart.split(":");
      const dateToSend = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        parseInt(hours),
        parseInt(minutes)
      );

      let depName = "";
      for (let i = 0; i < allDepartmentList.length; i++) {
        if (selectedEditDepartment === allDepartmentList[i].departmentId) {
          depName = allDepartmentList[i].departmentName;
        }
      }

      let UserDTO = {
        operatorEmail: user.email,
        operatorToken: googleUser.access_token,
        operatorUserType: user.userType,
        operation: "Edit",
        businessId: user.business.businessId,
        newFirstName: firstName,
        newLastName: lastName,
        prevEmail: prevEmail,
        newEmail: editEmail,
        newUserType: "Employee",
        deleteId: null,
        department: depName,
        title: editTitle,
        wage: editWage,
        shiftStart: dateToSend,
        prevEmail: prevEmail,
      };

      axios
        .post(
          `${baseURL}/addOrDeleteOrEditEmployee`,
          UserDTO
        )
        .then((response) => {
          onUserCrudResponse({ response });
          setSuccessMsg("Successfuly edited.");
          setTriggerSuccess(true);
        })
        .catch((error) => {
      
          // Check if error.response exists and then further check the nested properties
          if (error.response) {
              const { data, status } = error.response;
              
              // Check if data exists and matches the specific error message
              if (data && data === "Error: Duplicate Email exists") {
                  setErrorMsg("It appears that email is already in use.");
                  setTriggerError(true);
              } else if (data && data === "Error: Duplicate Name exists") {
                  setErrorMsg("It appears you already have an employee with that given name. Please use a nickname.");
                  setTriggerError(true);
              } else if (status === 409) {
                  setErrorMsg("It appears that email is already in use.");
                  setTriggerError(true);
              } else if (status === 429) {
                  setErrorMsg("It appears you are making too many requests. Please wait a moment then try again.");
                  setTriggerError(true);
              } else {
                  // Default error message if none of the specific statuses match
                  setErrorMsg("An error has occurred, please try later.");
                  setTriggerError(true);
              }
          } else {
              // Handle cases where there is no error response from the server
              setErrorMsg("An unexpected error occurred. Please try again later.");
              setTriggerError(true);
          }
      });
    } else {
      setErrorMsg(
        "Please ensure inputs are populated and a valid google email."
      );
      setTriggerError(true);
    }
  };

  const handleSelectedDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleSelectEditDepartmentChange = (event) => {
    setSelectedEditDepartment(event.target.value);
  };

  return (
    <div className="settings-container">
      <div
        className="settings-content-container"
        style={dynamicSuccessOrErrorStyle}
      >
        <Box>
          <AppBar position="static" sx={{ backgroundColor: "#52796F" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                ".MuiTabs-indicator": {
                  backgroundColor: "#343F4B",
                },
              }}
            >
              <Tab label="Add" {...a11yProps(0)} disabled={noAdd} />
              <Tab label="Delete" {...a11yProps(1)} disabled={noEmployees} />
              <Tab
                label="Edit"
                {...a11yProps(2)}
                disabled={!(user.addUser && user.deleteUser) || noEmployees}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className="user-add-container">
                <FormControl>
                  <TextField
                    id="add-first-name"
                    label="First Name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    error={firstNameError}
                    key="1"
                    InputLabelProps={{
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      },
                    }}
                  />
                  <FormControl sx={{ mt: 2 }}>
                    <TextField
                      id="add-last-name"
                      label="Last Name"
                      value={lastName}
                      onChange={handleLastNameChange}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                      error={lastNameError}
                    />
                  </FormControl>

                  <FormControl sx={{ mt: 2 }}>
                    <TextField
                      id="add-email"
                      label="Email"
                      value={email}
                      onChange={handleEmailChange}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                      error={emailError}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "black",
                        },
                        "&.MuiInputLabel-root.Mui-focused": {
                          color: "#52796F",
                        },
                      }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      value={selectedDepartment}
                      label="Department"
                      onChange={handleSelectedDepartmentChange}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "black",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#52796F",
                          },
                      }}
                    >
                      {allDepartmentList.map((dep) => (
                        <MenuItem
                          key={dep.departmentId}
                          value={dep.departmentId}
                        >
                          {dep.departmentName || "Unnamed Department"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ mt: 2 }}>
                    <TextField
                      id="add-email"
                      label="Job Title"
                      value={title}
                      onChange={handleTitleChange}
                      InputLabelProps={{
                        sx: {
                          color: "black !important",
                          "&.Mui-focused": { color: "#52796F !important" },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#52796F !important",
                        },
                      }}
                      error={titleError}
                    />
                  </FormControl>

                  <TextField
                    id="add-email"
                    label="Hourly Wage"
                    type="number"
                    value={wage}
                    onChange={handleWageChange}
                    onKeyDown={blockInvalidChar}  // Prevent 'e', 'E', '+', '-'
                    InputLabelProps={{
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      },
                      mt: 2,
                    }}
                    error={wageError}
                  />
                  <TextField
                    label="Shift Start Time"
                    type="time"
                    value={startTime}
                    onChange={handleShiftStartChange}
                    error={startError}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      },
                      mt: 2,
                    }}
                    inputProps={{
                      step: 300, // 5 minutes steps
                    }}
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      mt: 2,
                      width: "100%",
                      backgroundColor: "#52796F",
                      "&:hover": { backgroundColor: "#435e57" },
                    }}
                    onClick={handleAddUser}
                  >
                    <Typography>Add User</Typography>
                  </Button>
                </FormControl>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className="select-employee-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "black",
                      },
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#52796F",
                      },
                    }}
                  >
                    Employee
                  </InputLabel>
                  <Select
                    value={selectedEmployee}
                    label="Manager"
                    onChange={handleEmployeeChange}
                    displayEmpty
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "black",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#52796F",
                        },
                    }}
                  >
                    {allEmployeeList.map((emp) => (
                      <MenuItem key={emp.userId} value={emp.userId}>
                        {emp.fullName || "Unnamed User"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleDeleteUser}
              >
                <Typography>Delete</Typography>
              </Button>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <div className="select-employee-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "black",
                      },
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#52796F",
                      },
                    }}
                  >
                    Employee
                  </InputLabel>
                  <Select
                    value={selectedEditEmployee}
                    label="Manager"
                    onChange={handleEditEmployeeChange}
                    displayEmpty
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "black",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#52796F",
                        },
                    }}
                  >
                    {allEmployeeList.map((emp) => (
                      <MenuItem key={emp.userId} value={emp.userId}>
                        {emp.fullName || "Unnamed User"}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormControl sx={{mt: 2}}>
                  <TextField
                    id="add-email"
                    label="Email"
                    value={editEmail}
                    onChange={handleEditEmailChange}
                    InputLabelProps={{
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      }
                    }}
                    error={editEmailError}
                  />
                  </FormControl>
                  
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "black",
                        },
                        "&.MuiInputLabel-root.Mui-focused": {
                          color: "#52796F",
                        },
                      }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      value={selectedEditDepartment}
                      label="Department"
                      onChange={handleSelectEditDepartmentChange}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "black",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#52796F",
                          },
                      }}
                    >
                      {allDepartmentList.map((dep) => (
                        <MenuItem
                          key={dep.departmentId}
                          value={dep.departmentId}
                        >
                          {dep.departmentName || "Unnamed Department"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{mt: 2}}>
                  <TextField
                    id="add-email"
                    label="Job Title"
                    value={editTitle}
                    onChange={handleEditTitleChange}
                    InputLabelProps={{
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      }
                    }}
                    error={editTitleError}
                  />
                  </FormControl>
                  
                  <TextField
                    id="add-email"
                    label="Hourly Wage"
                    type="number"
                    onKeyDown={blockInvalidChar}  // Prevent 'e', 'E', '+', '-'
                    value={editWage}
                    onChange={handleEditWageChange}
                    InputLabelProps={{
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      },
                      mt: 2,
                    }}
                    error={editWageError}
                  />
                  <TextField
                    label="Shift Start Time"
                    type="time"
                    value={editShiftStart}
                    onChange={handleEditShiftStartChange}
                    error={editStartError}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        color: "black !important",
                        "&.Mui-focused": { color: "#52796F !important" },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#52796F !important",
                      },
                      mt: 2,
                    }}
                    inputProps={{
                      step: 300, // 5 minutes steps
                    }}
                  />
                </FormControl>
              </div>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: 0,
                  mt: 1,
                  width: "100%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleEditUser}
              >
                <Typography>Save</Typography>
              </Button>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
      <div className="settings-alert-container">
        <Stack sx={{ width: "100%" }}>
          {triggerSuccess && (
            <Alert
              severity="success"
              onClose={() => setTriggerSuccess(false)} // Close the success alert
            >
              {successMsg}
            </Alert>
          )}
          {triggerError && (
            <Alert
              severity="error"
              onClose={() => setTriggerError(false)} // Close the error alert
            >
              {errorMsg}
            </Alert>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default Users;
