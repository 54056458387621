import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Paper, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StackedPhones from "./finalStacked.png";
import Reporting from "./reportingWithFilters.png";
import Timesheets from "./timeEdits.png";
import Users from "./addUser.png";
import Alerts from "./alerts2.png";
import QR from "./qr.png";
import NFC from "./rfid.png";
import DAILY from "./dailycode.png";
import FINGER from "./biometrics.png";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import "./Product.css";



const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
};

const SubProduct= ({ onRegisterClick }) => {
  const isSmallScreen = useMediaQuery("(max-width: 775px)");
  const isMedScreen = useMediaQuery("(max-width: 1050px)");
  const currentYear = new Date().getFullYear();

  useEffect(() => {}, []);

  const handleRegisterClick = () => {
    onRegisterClick();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box className="product">
          <Box
            className="top-section"
            sx={{
              width: "75%",
              ml: "auto",
              mr: "auto",
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              mt: 10,
            }}
          >
            <Box
              className="top-left"
              sx={{
                width: isSmallScreen ? "100%" : "60%",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "20px",
              }}
            >
              <Typography align="left" variant="h4">
                <span>ChronoLeaf</span>{" "}
                <span
                  className="customHeaderFont"
                  style={{ color: "#2CCB4D", fontWeight: "bolder" }}
                >
                  modernizes
                </span>{" "}
                business timekeeping.
              </Typography>
              <Typography
                align="left"
                variant="h6"
                sx={{ paddingBottom: "5px", mt: 2 }}
              >
                Empower your employees to clock in and out with a simple tap or
                scan. ChronoLeaf uses cutting-edge QR and NFC technology,
                biometric authentication, and daily codes for rapid and reliable
                timekeeping.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMedScreen ? "column" : "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <TooltipSlide
                    title="Fingerprint Reading"
                    imageSrc={FINGER}
                    alt="Fingerprint Reading"
                    text="Biometrics"
                  />
                  <TooltipSlide
                    title="Mobile NFC"
                    imageSrc={NFC}
                    alt="Mobile NFC"
                    text="NFC"
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <TooltipSlide
                    title="QR Codes"
                    imageSrc={QR}
                    alt="QR Codes"
                    text="QR Codes"
                  />
                  <TooltipSlide
                    title="Daily Codes"
                    imageSrc={DAILY}
                    alt="Daily Codes"
                    text="Daily Codes"
                  />
                </Box>
              </Box>

              <Button
                variant="contained"
                size="medium"
                sx={{
                  ml: "auto",
                  mr: "auto",
                  mt: 2,
                  width: isSmallScreen ? "100%" : "40%",
                  backgroundColor: "#52796F",
                  "&:hover": { backgroundColor: "#435e57" },
                }}
                onClick={handleRegisterClick}
              >
                <Typography>TRY RISK FREE</Typography>
              </Button>
            </Box>
            <Box
              className="top-right"
              sx={{
                zIndex: 50,
                width: isSmallScreen ? "100%" : "40%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <SlideComponent imageSrc={StackedPhones} alt="Image 1" direction="left" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "75%",
              ml: "auto",
              mr: "auto",
              mt: 5,
              position: "relative",
              paddingBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "-35px", // Adjust this value to position the header right on the top border
                left: "50%",
                transform: "translateX(-50%)",
                fontStyle: "italic",
                padding: "0 10px",
                zIndex: 50,
                fontFamily: "'Playwrite NL', sans-serif",
                padding: "10px",
                justifyContent: "center",
                borderRadius: '10px',
                maxHeight: '50px',
              }}
            >
              <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={1000}
              >
                <Typography align="left" variant="h4" sx={{ mt: 1 }}>
                  <span
                    className="customHeaderFont"
                    style={{ color: "#2CCB4D", fontWeight: "bolder" }}
                  >
                    Features
                  </span>
                </Typography>
              </Slide>
            </Box>

            <Slider {...settings}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  borderRadius: "10px",
                  maxWidth: "99%",
                  backgroundColor: "transparent",
                }}
              >
                <Box
                  className="feature-header-container"
                  sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    height: "25%",
                    mt: "50px",
                  }}
                >
                  <Box
                    className="feature-header-image-container"
                    sx={{
                      width: "55%",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Timesheets}
                    
                      alt="Image 1"
                      style={{
                        border: '1px solid black',
                        borderRadius: '5px',
                        maxWidth: "100%",
                        minWidth: "250px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Box>
                  <Box
                    className="feature-header-title"
                    sx={{ mt: "auto", mb: "auto", ml: 0, mr: "auto" }}
                  >
                    <Typography variant="h4" sx={{ mt: isSmallScreen ? 2 : 0 }}>
                      Simplified Timesheet Management
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "75%",
                    maxWidth: "90%",
                    mt: 2,
                    ml: "auto",
                    mr: "auto",
                    paddingBottom: 2,
                  }}
                >
                  <Typography>
                    <strong>Timesheet Troubles Be Gone:</strong> In{" "}
                    {currentYear}, managing timesheets should be nothing short
                    of simple. ChronoLeaf delivers this by making it effortless
                    to correct timesheets, add missed entries, and remove
                    inaccuracies. Streamlining these processes saves time and
                    reduces human error, making workforce management a breeze.
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  borderRadius: "10px",
                  maxWidth: "99%",
                }}
              >
                <Box
                  className="feature-header-container"
                  sx={{
                    mt: "50px",
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    height: "25%",
                  }}
                >
                  <Box
                    className="feature-header-image-container"
                    sx={{
                      width: "55%",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Reporting}
                      alt="Image 1"
                      style={{
                        border: '1px solid black',
                        borderRadius: '5px',
                        maxWidth: "100%",
                        minWidth: "250px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Box>
                  <Box
                    className="feature-header-title"
                    sx={{ mt: "auto", mb: "auto", ml: 0, mr: "auto" }}
                  >
                    <Typography variant="h4" sx={{ mt: isSmallScreen ? 2 : 0 }}>
                      Effortless Reporting
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "75%",
                    maxWidth: "90%",
                    mt: 2,
                    ml: "auto",
                    mr: "auto",
                    paddingBottom: 2,
                  }}
                >
                  <div className="feature-font">
                    <Typography>
                      <strong>Streamline Your Insights:</strong> Access a
                      comprehensive overview of employee performance with
                      ChronoLeaf's efficient reporting tools. Instantly generate
                      reports showing daily hours, total earnings (including
                      overtime), and tardiness. Our intuitive filters allow you
                      to quickly sort data by employee, hours, wages, and more,
                      enhancing your decision-making with precision and ease.
                    </Typography>
                  </div>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  borderRadius: "10px",
                  maxWidth: "99%",
                }}
              >
                <Box
                  className="feature-header-container"
                  sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    height: "25%",
                    mt: "50px",
                  }}
                >
                  <Box
                    className="feature-header-image-container"
                    sx={{
                      width: "55%",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Alerts}
                      alt="Image 1"
                      style={{
                        border: '1px solid black',
                        borderRadius: '5px',
                        maxWidth: "100%",
                        minWidth: "250px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Box>
                  <Box
                    className="feature-header-title"
                    sx={{ mt: "auto", mb: "auto", ml: 0, mr: "auto" }}
                  >
                    <Typography variant="h4" sx={{ mt: isSmallScreen ? 2 : 0 }}>
                      Proactive Alerts & Notifications
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "75%",
                    maxWidth: "90%",
                    mt: 2,
                    ml: "auto",
                    mr: "auto",
                    paddingBottom: 2,
                  }}
                >
                  <Typography>
                    <strong>Stay Informed, Stay Ahead:</strong> ChronoLeaf keeps
                    you connected with real-time notifications. Customize alerts
                    to know immediately if an employee is late or begins to
                    accumulate overtime. Our system ensures you're always
                    informed, allowing for timely interventions and better
                    workforce management.
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  borderRadius: "10px",
                  maxWidth: "99%",
                }}
              >
                <Box
                  className="feature-header-container"
                  sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    height: "25%",
                    mt: "50px",
                  }}
                >
                  <Box
                    className="feature-header-image-container"
                    sx={{
                      width: "55%",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Users}
                      alt="Image 1"
                      style={{
                        border: '1px solid black',
                        borderRadius: '5px',
                        maxWidth: "100%",
                        minWidth: "250px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Box>
                  <Box
                    className="feature-header-title"
                    sx={{ mt: "auto", mb: "auto", ml: 0, mr: "auto" }}
                  >
                    <Typography variant="h4" sx={{ mt: isSmallScreen ? 2 : 0 }}>
                      Dynamic User Management
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "75%",
                    maxWidth: "90%",
                    mt: 2,
                    ml: "auto",
                    mr: "auto",
                    paddingBottom: 2,
                  }}
                >
                  <Typography>
                    <strong>Control with Ease:</strong> ChronoLeaf's user
                    management system empowers you to efficiently handle team
                    dynamics. Add new users, set roles, and define wages and
                    schedules with just a few clicks. Elevate trusted employees
                    to managerial roles with specific permissions to edit time
                    records, manage user roles, and oversee report generation.
                    This level of customization enhances operational flexibility
                    and control.
                  </Typography>
                </Box>
              </Box>
            </Slider>
          </Box>
        </Box>
  );
};

const SlideComponent = ({ imageSrc, alt, direction }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [isIntersecting, hasIntersected]);

  return (
    <div ref={ref}>
      <Slide direction={direction} in={hasIntersected} mountOnEnter unmountOnExit timeout={1000}>
        <img src={imageSrc} alt={alt} style={{ maxWidth: "200px", paddingBottom: '30px' }} />
      </Slide>
    </div>
  );
};

const TooltipSlide = ({ title, imageSrc, alt, text }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [isIntersecting, hasIntersected]);

  return (
    <div ref={ref}>
      <Slide direction="right" in={hasIntersected} mountOnEnter unmountOnExit timeout={1000}>
        <Tooltip title={title}>
          <Paper
            sx={{
              width: "auto",
              maxWidth: "100px",
              padding: "10px",
              margin: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                cursor: "pointer",
              },
            }}
          >
            <img
              style={{ width: "100px" }}
              src={imageSrc}
              alt={alt}
            />
            <Typography>{text}</Typography>
          </Paper>
        </Tooltip>
      </Slide>
    </div>
  );
};

export default SubProduct;
